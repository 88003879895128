import {
    HStack,
    Link,
    VStack,
    Text,
} from '@chakra-ui/react';
import { Folder } from 'iconsax-react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import delimitNumber from '../../../../functions/delimitNumber';
import LoadingSpinner from '../../../LoadingSpinner';
import PropertyOverview from '../../../PropertyOverview';
import {
    useEntityPanelValuers,
    usePublicUser,
} from '../../../../../utils/api/hooks';
import AccordionContainer from '../../../AccordionContainer';
import { EnquiryType, EntityType, OfferType } from '../../../../../types';
import toUrl from '../../../../functions/toUrl';
import { useRole } from '../../../../hooks';
import EnquiryOverviewSectionContainer from '../display/EnquiryOverviewSectionContainer';
import { WvwText } from '../../../typography';

type PropTypes = {
    variant?: 'default' | 'white';
    overviewData: EnquiryType;
    acceptedOffer?: OfferType;
    obscureAccessDetails?: boolean;
};

const GeneralEnquiryOverview = (props: PropTypes) => {
    const {
        variant,
        overviewData,
        acceptedOffer,
        obscureAccessDetails,
    } = props;

    const toEntityIds = overviewData.toEntityIds || [];
    const fromUserId = overviewData.fromUserId || '';
    const valuationType = overviewData.valuationType || '';
    const valuationSpecial = overviewData.valuationSpecial || [];
    const valuationOther = overviewData.valuationOther || '';
    const retypeDocuments = overviewData.retypeDocuments || [];
    const valuationStandards = overviewData.valuationStandards || '';
    const valuationStandardsOther = overviewData.valuationStandardsOther || '';
    const valuationPurposes = overviewData.valuationPurposes || [];
    const valuationPurposesOther = overviewData.valuationPurposesOther || '';
    const completionDate = overviewData.completionDate || '';
    const valuerQualifications = overviewData.valuerQualifications || [];
    const valuerQualificationsOther = overviewData.valuerQualificationsOther || '';
    const valuerExperience = overviewData.valuerExperience || '';
    const valuerMinimumPICoverPercentage = overviewData.valuerMinimumPICoverPercentage || 0;
    const valuerMinimumPICover = overviewData.valuerMinimumPICover || 0;
    const valuerMinimumPartners = overviewData.valuerMinimumPartners || '';
    const valuerMaxOfficeDistance = overviewData.valuerMaxOfficeDistance || '';
    const additionalRequirements = overviewData.additionalRequirements || '';
    const properties = overviewData.properties || [];

    const { t } = useTranslation(['enquiryform', 'common', 'enquiries', 'formik']);

    const {
        userIsCompany,
        userIsClient,
        userIsValuer,
    } = useRole();

    const { pathname } = useLocation();

    const isInDashboard = pathname.includes('dashboard');

    const {
        valuerList = [] as unknown as EntityType[],
        isLoading: valuerLoading,
    } = useEntityPanelValuers();

    const selectedValuers = valuerList?.filter(
        valuer => toEntityIds.includes(valuer.id),
    );

    const {
        data: user,
        isLoading,
    } = usePublicUser(fromUserId);

    if (valuerLoading || isLoading) return <LoadingSpinner />;

    return (
        <EnquiryOverviewSectionContainer
            variant={variant}
            content={[
                {
                    header: t('newEnquiry.overview.valuation.requestedTitle'),
                    tableData: [
                        {
                            title: t('newEnquiry.overview.valuation.type'),
                            data: (
                                <div>
                                    {valuationType === 'Other Valuation' && (
                                        <VStack align="left">
                                            <WvwText>
                                                {t(valuationType, { ns: 'formik', defaultValue: `${t('noRequirement')}` })}
                                            </WvwText>

                                            {valuationSpecial.map(special => (
                                                <Text
                                                    key={special}
                                                    pl="1rem"
                                                >
                                                    {t(special, { ns: 'formik', defaultValue: special })}
                                                </Text>
                                            ))}

                                            <Text pl="1rem">
                                                {valuationOther}
                                            </Text>
                                        </VStack>
                                    )}

                                    {valuationType === 'Retype Valuation' && (
                                        <VStack align="left">
                                            <WvwText>
                                                {t(valuationType, { ns: 'formik', defaultValue: `${t('noRequirement')}` })}
                                            </WvwText>

                                            {retypeDocuments.map(document => (
                                                <Link
                                                    href={document.url}
                                                    isExternal
                                                    key={document.id}
                                                    color="wvwGreen"
                                                    pl="1rem"
                                                >
                                                    <HStack>
                                                        <Folder size="1rem" />

                                                        <WvwText>
                                                            {document.name}
                                                        </WvwText>
                                                    </HStack>
                                                </Link>
                                            ))}
                                        </VStack>
                                    )}

                                    {valuationType !== ('Retype Valuation' && 'Special Valuation' && 'Other Valuation') && (
                                        <WvwText>
                                            {t(valuationType, { ns: 'formik', defaultValue: `${t('noRequirement')}` })}
                                        </WvwText>
                                    )}
                                </div>
                            ),
                        },
                        {
                            title: (userIsCompany || userIsValuer) ? t('newEnquiry.overview.valuation.standards') : undefined,
                            data: (
                                <VStack align="left">
                                    {valuationStandards === 'Other' ? (
                                        <WvwText>
                                            {valuationStandardsOther}
                                        </WvwText>
                                    ) : (
                                        <WvwText>
                                            {t(valuationStandards, { ns: 'formik', defaultValue: `${t('noRequirement')}` })}
                                        </WvwText>
                                    )}
                                </VStack>
                            ),
                        },
                        {
                            title: t('newEnquiry.overview.valuation.purpose'),
                            data: (
                                <VStack align="left">
                                    {valuationPurposes.map(purpose => (
                                        <HStack key={purpose}>
                                            <WvwText>
                                                {t(purpose, { ns: 'formik', defaultValue: purpose })}
                                            </WvwText>
                                        </HStack>
                                    ))}

                                    {valuationPurposes.includes('Other') && (
                                        <Text pl="1rem">
                                            {valuationPurposesOther}
                                        </Text>
                                    )}
                                </VStack>
                            ),
                        },
                        {
                            title: t('newEnquiry.overview.completionExpectation'),
                            data: (
                                <WvwText>
                                    {completionDate}
                                </WvwText>
                            ),
                        },
                    ],
                },
                {
                    header: acceptedOffer ? t('acceptedOffer.valuer.sectionTitle', { ns: 'enquiryform' }) : undefined,
                    tableData: [
                        {
                            title: t('acceptedOffer.valuer.companyName', { ns: 'enquiryform' }),
                            data:
                                (
                                    <WvwText>
                                        {acceptedOffer?.fromValuer.workingForCompanyName}
                                    </WvwText>
                                ),
                        },
                        {
                            title: t('acceptedOffer.valuer.name', { ns: 'enquiryform' }),
                            data:
                                (
                                    <WvwText>
                                        {`${acceptedOffer?.fromValuer.firstName} ${acceptedOffer?.fromValuer.lastName}`}
                                    </WvwText>
                                ),
                        },
                        {
                            title: t('acceptedOffer.piCover', { ns: 'enquiryform' }),
                            data:
                                (
                                    <WvwText>
                                        {t('piCover_or_statement', {
                                            ns: 'common',
                                            coverValue: acceptedOffer?.piCoverValue === 0 || null ? '-' : delimitNumber(acceptedOffer?.piCoverValue?.toString()),
                                            coverPercentage: acceptedOffer?.piCoverPercentage === 0 || null ? '-' : acceptedOffer?.piCoverPercentage,
                                        })}
                                    </WvwText>
                                ),
                        },
                        {
                            title: t('acceptedOffer.price', { ns: 'enquiryform' }),
                            data:
                                (
                                    <WvwText>
                                        {`${t('unitSymbol.currency', { ns: 'common' })} ${delimitNumber(acceptedOffer?.price)}`}
                                    </WvwText>
                                ),
                        },
                        {
                            title: t('acceptedOffer.earliestInspection', { ns: 'enquiryform' }),
                            data:
                                (
                                    <WvwText>
                                        {acceptedOffer?.availability}
                                    </WvwText>
                                ),
                        },
                        {
                            title: t('acceptedOffer.timeline', { ns: 'enquiryform' }),
                            data: (
                                <WvwText>
                                    {`${acceptedOffer?.timeline} ${t('days', { ns: 'common' })}`}
                                </WvwText>
                            ),
                        },
                    ],
                },
                {
                    header: (userIsCompany || userIsValuer) ? t('newEnquiry.overview.valuer.requirements') : undefined,
                    tableData: [
                        {
                            title: userIsClient ? t('valuerRequirements.valuerPanel', { ns: 'enquiries' }) : undefined,
                            data:
                                (
                                    <VStack align="left">
                                        {selectedValuers?.map(valuer => (
                                            <WvwText key={valuer.id}>
                                                {valuer.name}
                                            </WvwText>
                                        ))}
                                        {selectedValuers?.length === 0 && (
                                            <WvwText color="wvwGrey40">
                                                {t('noRequirement', { ns: 'enquiryform' })}
                                            </WvwText>
                                        )}
                                    </VStack>
                                ),
                        },
                        {
                            title: t('newEnquiry.overview.valuer.qualifications'),
                            data:
                                (
                                    <VStack align="left">
                                        {valuerQualifications.length === 0
                                            ? (
                                                <WvwText color="wvwGrey40">
                                                    {t('noRequirement')}
                                                </WvwText>
                                            ) : (
                                                valuerQualifications.map(
                                                    qualification => (
                                                        <WvwText key={qualification}>
                                                            {qualification === 'Other' ? '' : t(qualification, { ns: 'formik', defaultValue: qualification })}
                                                        </WvwText>
                                                    ),
                                                )
                                            )}
                                        {valuerQualificationsOther && (
                                            <WvwText>
                                                {valuerQualificationsOther}
                                            </WvwText>
                                        )}
                                    </VStack>
                                ),
                        },
                        {
                            title: t('newEnquiry.overview.valuer.minExperience'),
                            data:
                                (
                                    <WvwText color={valuerExperience === '0' ? 'wvwGrey40' : 'black'}>
                                        {t(`newEnquiry.overview.valuerRequirements.minValuerExperience.${valuerExperience}`, { ns: 'enquiryform', defaultValue: `${t('noRequirement')}` })}
                                    </WvwText>
                                ),
                        },
                        {
                            title: t('newEnquiry.overview.valuer.minPICover'),
                            data:
                                (
                                    <WvwText color={valuerMinimumPICoverPercentage <= 0 ? 'wvwGrey40' : 'black'}>
                                        {/* eslint-disable-next-line no-nested-ternary */}
                                        {valuerMinimumPICover ? (
                                            `€ ${delimitNumber(valuerMinimumPICover)}`
                                        ) : valuerMinimumPICoverPercentage === 0
                                            ? (
                                                t('noRequirement')
                                            ) : (
                                                `${valuerMinimumPICoverPercentage} %`
                                            )}
                                    </WvwText>
                                ),
                        },
                        {
                            title: t('newEnquiry.overview.valuer.minPartners'),
                            data:
                                (
                                    <WvwText color={valuerMinimumPartners === '0' ? 'wvwGrey40' : 'black'}>
                                        {t(`newEnquiry.overview.valuerRequirements.minPartners.${valuerMinimumPartners}`, { ns: 'enquiryform', defaultValue: `${t('noRequirement')}` })}
                                    </WvwText>
                                ),
                        },
                        {
                            title: t('newEnquiry.overview.valuer.maxDistance'),
                            data:
                                (
                                    <WvwText color={valuerMaxOfficeDistance === 'none' ? 'wvwGrey40' : 'black'}>
                                        {t(`newEnquiry.overview.valuerRequirements.maxDistance.${valuerMaxOfficeDistance}`, { ns: 'enquiryform', defaultValue: `${t('noRequirement')}` })}
                                    </WvwText>
                                ),
                        },
                        {
                            title: t('newEnquiry.overview.valuer.additionalRequirements'),
                            data:
                                (
                                    additionalRequirements === '' ? (
                                        <WvwText color="wvwGrey40">
                                            {t('noRequirement')}
                                        </WvwText>
                                    ) : (
                                        <WvwText>
                                            {additionalRequirements}
                                        </WvwText>
                                    )
                                ),
                        },
                    ],
                },
                {
                    header: t('newEnquiry.overview.property.detailsSectionTitle'),
                    tableData: [
                        {
                            title: 'properties',
                            data:
                                (
                                    <VStack width="100%">
                                        {properties === undefined && (
                                            <WvwText>
                                                {t('fieldNotProvided.website', { ns: 'common' })}
                                            </WvwText>
                                        )}

                                        {properties.map(property => (
                                            <AccordionContainer
                                                title={`${property.street}, ${property.city}`}
                                                key={`${property.street} ${property.city}`}
                                            >
                                                <PropertyOverview
                                                    property={property}
                                                    obscureAccessDetails={obscureAccessDetails}
                                                />
                                            </AccordionContainer>
                                        ))}
                                    </VStack>
                                ),
                        },
                    ],
                },
                {
                    header: (userIsCompany && userIsClient && isInDashboard) ? t('companyDetails.sectionTitle', { ns: 'account' }) : undefined,
                    tableData: [
                        {
                            title: t('companyDetails.website', { ns: 'account' }),
                            data:
                                (
                                    user?.entity?.websiteAddress ? (
                                        <Link
                                            href={toUrl(user?.entity?.websiteAddress)}
                                            isExternal
                                            textOverflow="ellipsis"
                                            noOfLines={1}
                                        >
                                            {user?.entity?.websiteAddress}
                                        </Link>
                                    ) : (
                                        <WvwText color="wvwGrey40">
                                            {t('fieldNotProvided.website', { ns: 'common' })}
                                        </WvwText>
                                    )
                                ),
                        },
                        {
                            title: t('companyDetails.email', { ns: 'account' }),
                            data:
                                (
                                    user?.entity?.companyEmail ? (
                                        <WvwText>
                                            {user?.entity?.companyEmail}
                                        </WvwText>
                                    ) : (
                                        <WvwText color="wvwGrey40">
                                            {t('fieldNotProvided.email', { ns: 'common' })}
                                        </WvwText>
                                    )
                                ),
                        },
                        {
                            title: t('companyDetails.phoneNumber', { ns: 'account' }),
                            data:
                                (
                                    user?.entity?.contactNumber ? (
                                        <WvwText>
                                            {`${user?.entity?.contactNumberDialCode} ${user?.entity?.contactNumber}`}
                                        </WvwText>
                                    ) : (
                                        <WvwText color="wvwGrey40">
                                            {t('fieldNotProvided.phoneNumber', { ns: 'common' })}
                                        </WvwText>
                                    )
                                ),
                        },
                        {
                            title: t('companyDetails.location', { ns: 'account' }),
                            data:
                                (
                                    user?.entity?.street ? (
                                        <VStack align="left">
                                            <WvwText>
                                                {`${user?.entity?.street}, ${user?.entity?.postalCode}`}
                                            </WvwText>

                                            <WvwText>
                                                {`${user?.entity?.city}, ${user?.entity?.country}`}
                                            </WvwText>
                                        </VStack>
                                    ) : (
                                        <WvwText color="wvwGrey40">
                                            {t('fieldNotProvided.location', { ns: 'common' })}
                                        </WvwText>
                                    )
                                ),
                        },
                    ],
                },
            ]}
        />
    );
};

GeneralEnquiryOverview.defaultProps = {
    acceptedOffer: null,
    variant: 'white',
    obscureAccessDetails: false,
};

export default GeneralEnquiryOverview;
