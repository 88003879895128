import {
    Flex,
    Link,
    List,
    ListItem,
    Text,
} from '@chakra-ui/react';
import { useSmartTranslation } from '../../../../common/hooks';
import { UserType } from '../../../../types';
import FormSectionProfileEdit from './FormSectionProfileEdit';
import { FirebaseCertificateType } from '../../../../utils/storage/storage';

type PropsType = {
    user: UserType;
    certificates: FirebaseCertificateType[];
};

const ProfileQualifications = (props: PropsType) => {
    const { user, certificates } = props;

    const t = useSmartTranslation('profile');

    const {
        standardQualifications = [],
        otherQualifications = [],
    } = user;

    return (
        <FormSectionProfileEdit
            header={t('heading.membershipsAndQualifications')}
        >
            <Flex
                alignItems="flex-start"
                minH="2rem"
                w="100%"
            >
                <Text w="17rem">
                    {t('membershipsAndQualifications.qualifications')}
                </Text>

                <List spacing={2}>
                    {standardQualifications.length === 0 && (
                        <ListItem color="wvwGrey40">
                            {t('none', { context: 'female', ns: 'common' })}
                        </ListItem>
                    )}

                    {standardQualifications.sort().map(i => {
                        const files = certificates
                            .filter(
                                certificate => (
                                    certificate.type.toLowerCase() === i.toLowerCase()
                                ),
                            );

                        return (
                            <ListItem key={i}>
                                <Flex w="100%">
                                    <Text w="10rem">
                                        {t(i, { ns: 'formik', defaultValue: i })}
                                    </Text>

                                    <List>
                                        {files.map(file => (
                                            <ListItem key={file.file.fullPath}>
                                                <Link
                                                    href={file.url}
                                                    isExternal
                                                    target="_blank"
                                                    color="wvwGreen"
                                                >
                                                    {file.label}
                                                </Link>
                                            </ListItem>
                                        ))}

                                        {files.length === 0 && (
                                            <ListItem
                                                color="wvwGrey40"
                                            >
                                                {t('membershipsAndQualifications.noCertificate')}
                                            </ListItem>
                                        )}
                                    </List>
                                </Flex>
                            </ListItem>
                        );
                    })}
                </List>
            </Flex>

            {otherQualifications && (
                <Flex
                    alignItems="flex-start"
                    minH="2rem"
                    w="100%"
                >
                    <Text w="17rem">
                        {t('membershipsAndQualifications.otherQualifications')}
                    </Text>

                    <List spacing={2}>
                        {otherQualifications.length === 0 && (
                            <ListItem
                                color="wvwGrey40"
                            >
                                {t('none', { context: 'female', ns: 'common' })}
                            </ListItem>
                        )}

                        {otherQualifications.sort().map(i => {
                            const files = certificates
                                .filter(
                                    certificate => (
                                        certificate.type === i.toLowerCase()
                                    ),
                                );

                            return (
                                <ListItem key={i}>
                                    <Flex w="100%">
                                        <Text w="10rem">
                                            {i}
                                        </Text>

                                        <List>
                                            {files.map(file => (
                                                <ListItem key={file.file.fullPath}>
                                                    <Link
                                                        href={file.url}
                                                        isExternal
                                                        target="_blank"
                                                        color="wvwGreen"
                                                    >
                                                        {file.label}
                                                    </Link>
                                                </ListItem>
                                            ))}

                                            {files.length === 0 && (
                                                <ListItem
                                                    color="wvwGrey40"
                                                >
                                                    {t('membershipsAndQualifications.noCertificate')}
                                                </ListItem>
                                            )}
                                        </List>
                                    </Flex>
                                </ListItem>
                            );
                        })}
                    </List>
                </Flex>
            )}
        </FormSectionProfileEdit>
    );
};

export default ProfileQualifications;
