/* eslint-disable max-len */
import { HStack, VStack } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { useSmartTranslation } from '../../../../common/hooks';
import {
    // FormikAddressInputWithMap,
    FormikCheckbox,
    FormikCountrySelect,
    FormikInput,
} from '../../../../common/forms';
import FormSectionProfileEdit from './FormSectionProfileEdit';
import { useMyEntity } from '../../../../utils/api/hooks';
import LoadingSpinner from '../../../../common/components/LoadingSpinner';
import FormikMapsAutocomplete from '../../../../common/forms/FormikMapsAutocomplete';

type PropsType = {
    userIsValuer: boolean;
    userIsCompanyAdmin: boolean;
    streetName: string;
    postalCodeName: string;
    cityName: string;
    countryName: string;
    latName: string;
    lngName: string;
    officeTelDialCodeName: string;
    officeTelName: string;
    officeFaxDialCodeName: string;
    officeFaxName: string;
};

/**
 * Formik form section for editing the current user's office details.
 */
const ProfileOfficeDetailsEdit = (props: PropsType) => {
    const {
        userIsValuer,
        userIsCompanyAdmin,
        streetName,
        postalCodeName,
        cityName,
        countryName,
        latName,
        lngName,
        officeTelDialCodeName,
        officeTelName,
        officeFaxDialCodeName,
        officeFaxName,
    } = props;

    const t = useSmartTranslation('profile');

    const {
        data: entity,
        isLoading: entityLoading,
    } = useMyEntity();

    const renderInputRow = (inputOne: ReactNode, inputTwo: ReactNode) => (
        <HStack
            align="start"
            spacing="6"
            w="100%"
        >
            {inputOne}
            {inputTwo}
        </HStack>
    );

    if (entityLoading) return <LoadingSpinner />;

    return (
        <FormSectionProfileEdit
            header={t('heading.officeLocation')}
            subHeader={t('subHeading.workLocation')}
        >
            <VStack
                width="100%"
                align="left"
            >
                {userIsCompanyAdmin && (
                    <FormikCheckbox
                        name="useCompanyAddress"
                        label={t('profileCompletionWizard.useCompanyDetails', { ns: 'profile' })}
                        onChange={(event, field) => {
                            const newValue = !field?.value;

                            if (newValue) {
                                field?.onChange({ target: { name: streetName, value: entity.street } });
                                field?.onChange({ target: { name: postalCodeName, value: entity.postalCode } });
                                field?.onChange({ target: { name: cityName, value: entity.city } });
                                field?.onChange({ target: { name: countryName, value: entity.country } });
                            }

                            if (!newValue) {
                                field?.onChange({ target: { name: streetName, value: '' } });
                                field?.onChange({ target: { name: postalCodeName, value: '' } });
                                field?.onChange({ target: { name: cityName, value: '' } });
                                field?.onChange({ target: { name: countryName, value: '' } });
                            }
                        }}
                    />
                )}
                {!userIsValuer && renderInputRow(
                    <HStack
                        alignItems="top"
                        spacing="6"
                        w="100%"
                        flexWrap={{
                            base: 'wrap',
                            md: 'nowrap',
                        }}
                    >
                        <FormikInput
                            noSubmitOnEnter
                            name={streetName}
                            label={t('profileDetails.street')}
                            placeholder={t('profileDetails.street')}
                        />
                        <FormikInput
                            noSubmitOnEnter
                            name={postalCodeName}
                            label={t('profileDetails.postalCode')}
                            placeholder={t('profileDetails.postalCode')}
                        />
                    </HStack>,
                    <HStack
                        alignItems="top"
                        spacing="6"
                        w="100%"
                        flexWrap={{
                            base: 'wrap',
                            md: 'nowrap',
                        }}
                    >
                        <FormikInput
                            noSubmitOnEnter
                            name={cityName}
                            label={t('profileDetails.city')}
                            placeholder={t('profileDetails.city')}
                        />

                        <FormikCountrySelect
                            name={countryName}
                            label={t('profileDetails.country')}
                            w="100%"
                        />
                    </HStack>,
                )}

                {userIsValuer && (
                    <FormikMapsAutocomplete
                        cityName={cityName}
                        countryName={countryName}
                        streetName={streetName}
                        postalCodeName={postalCodeName}
                        latName={latName}
                        lngName={lngName}
                    />
                    // <FormikAddressInputWithMap
                    //     cityName={cityName}
                    //     countryName={countryName}
                    //     postalCodeName={postalCodeName}
                    //     streetName={streetName}
                    //     latName={latName}
                    //     lngName={lngName}
                    //     mapTitle={t('profileDetails.exactLocation')}
                    //     mapInstruction={t('profileDetails.exactLocationSubtitle')}
                    // />
                )}
            </VStack>

            {renderInputRow(
                <FormikCountrySelect
                    name={officeTelDialCodeName}
                    label={t('profileDetails.dialCode')}
                    selectDialCode
                    w="100%"
                />,
                <FormikInput
                    noSubmitOnEnter
                    name={officeTelName}
                    label={t('profileDetails.officeTelNumber')}
                    placeholder={t('profileDetails.officeTelNumber')}
                />,
            )}

            {renderInputRow(
                <FormikCountrySelect
                    name={officeFaxDialCodeName}
                    label={t('profileDetails.dialCode')}
                    selectDialCode
                    w="100%"
                />,
                <FormikInput
                    noSubmitOnEnter
                    name={officeFaxName}
                    label={t('profileDetails.officeFaxNumber')}
                    placeholder={t('profileDetails.officeFaxNumber')}
                />,
            )}
        </FormSectionProfileEdit>
    );
};

export default ProfileOfficeDetailsEdit;
