/* eslint-disable max-len */
import { HStack, VStack } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { useSmartTranslation } from '../../../../../common/hooks';
import FormSection from '../../../profile/components/FormSection';
import {
    FormikCheckbox, FormikCountrySelect, FormikInput,
} from '../../../../../common/forms';
import { useMe } from '../../../../../utils/api/hooks';
import LoadingSpinner from '../../../../../common/components/LoadingSpinner';

/**
 * Formik form section for editing the current user's company contact details.
 */

const CompanyDetailsContact = () => {
    const t = useSmartTranslation('account');
    const {
        data: user,
        isLoading: userLoading,
    } = useMe();

    const renderInputRow = (inputOne: ReactNode, inputTwo: ReactNode) => (
        <HStack
            align="start"
            spacing="6"
            w="100%"
        >
            {inputOne}
            {inputTwo}
        </HStack>
    );

    if (userLoading) return <LoadingSpinner />;

    return (
        <FormSection
            header={t('companyDetails.contactInfo')}
            variant="white"
            element={(
                <VStack
                    w="100%"
                >
                    <VStack width="100%">
                        <FormikCheckbox
                            name="useMyDetails"
                            label={t('modal.accessDetails.form.myDetails', { ns: 'inspection' })}
                            onChange={(event, field) => {
                                const newValue = !field?.value;

                                if (newValue) {
                                    field?.onChange({ target: { name: 'contactFirstName', value: user.firstName } });
                                    field?.onChange({ target: { name: 'contactLastName', value: user.lastName } });
                                    field?.onChange({ target: { name: 'companyEmail', value: user.email } });
                                    field?.onChange({ target: { name: 'contactNumberDialCode', value: user.mobileDialCode } });
                                    field?.onChange({ target: { name: 'contactNumber', value: user.mobile } });
                                }

                                if (!newValue) {
                                    field?.onChange({ target: { name: 'contactFirstName', value: '' } });
                                    field?.onChange({ target: { name: 'contactLastName', value: '' } });
                                    field?.onChange({ target: { name: 'companyEmail', value: '' } });
                                    field?.onChange({ target: { name: 'contactNumberDialCode', value: '' } });
                                    field?.onChange({ target: { name: 'contactNumber', value: '' } });
                                }
                            }}
                        />

                        <HStack
                            alignItems="top"
                            spacing="6"
                            w="100%"
                        >
                            <FormikInput
                                name="contactFirstName"
                                label={t('firstName')}
                                placeholder={t('firstName')}
                            />

                            <FormikInput
                                name="contactLastName"
                                label={t('lastName')}
                                placeholder={t('lastName')}
                            />
                        </HStack>

                        <FormikInput
                            name="companyEmail"
                            label={t('companyDetails.email')}
                            placeholder={t('fieldPlaceholder.email', { ns: 'common' })}
                            type="email"
                        />
                    </VStack>

                    {renderInputRow(
                        <FormikCountrySelect
                            label={t('companyDetails.dialCode')}
                            name="contactNumberDialCode"
                            selectDialCode
                            w="100%"
                        />,
                        <FormikInput
                            name="contactNumber"
                            label={t('companyDetails.phoneNumber')}
                            placeholder={t('fieldPlaceholder.phoneNumber', { ns: 'common' })}
                        />,
                    )}

                    <FormikInput
                        name="websiteAddress"
                        label={t('companyDetails.website')}
                        placeholder={t('fieldPlaceholder.url', { ns: 'common' })}
                    />
                </VStack>
            )}
        />
    );
};

export default CompanyDetailsContact;
