import { AddIcon } from '@chakra-ui/icons';
import {
    VStack,
    Heading,
    Button,
    HStack,
    Text,
    Box,
    Flex,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from '../../../../common/components/LoadingSpinner';
import { FormikListSelect, FormikRadioGroup } from '../../../../common/forms';
import { useEntityPanelValuers } from '../../../../utils/api/hooks';

const Valuers = () => {
    const { t } = useTranslation(['enquiryform', 'formik', 'common']);

    const navigate = useNavigate();

    const {
        valuerList,
        isLoading,
        error,
    } = useEntityPanelValuers();

    if (isLoading) {
        return <LoadingSpinner />;
    }

    return (
        <>
            {isLoading && (
                <LoadingSpinner />
            )}

            {error !== null && (
                <VStack>
                    <Heading
                        size="sm"
                    >
                        {t('error.weAreSorry')}
                    </Heading>

                    <Text>
                        {t('error.valuerList')}
                    </Text>
                </VStack>
            )}

            {valuerList?.length === 0 && (
                <VStack>
                    <Text>
                        {t('newEnquiry.valuerSection.noValuers')}
                    </Text>
                </VStack>
            )}

            <FormikListSelect
                width="100%"
                name="toEntityIds"
                options={valuerList?.map(valuer => ({
                    label: valuer.name,
                    value: valuer.id,
                }))}
            />

            <Button
                variant="none"
                color="wvwGreen"
                fontWeight="500"
                alignSelf="flex-end"
                onClick={() => navigate('/dashboard/account/valuer-panel')}
            >
                <HStack>
                    <AddIcon width=".8rem" />

                    <Text>
                        {t('button.addValuers', { ns: 'common' })}
                    </Text>
                </HStack>
            </Button>

            <Flex
                align="center"
            >
                <Box
                    w="100%"
                    bg="wvwGrey05"
                    borderRadius="10"
                    padding="1rem"
                >
                    <Heading
                        size="md"
                        paddingBottom=".5rem"
                        color="wvwGreen"
                    >
                        {t('newEnquiry.valuerSection.allowedValuers.sectionTitle')}
                    </Heading>

                    <Text>
                        {t('newEnquiry.valuerSection.allowedValuers.blurb')}
                    </Text>

                    <Box
                        display="inline-block"
                        maxW="30rem"
                        width="100%"
                    >
                        <FormikRadioGroup
                            name="allowFullMatchmaking"
                            options={[
                                {
                                    label: t('yes', { ns: 'common' }),
                                    value: 'true',
                                },
                                {
                                    label: t('no', { ns: 'common' }),
                                    value: 'false',
                                },
                            ]}
                        />
                    </Box>
                </Box>
            </Flex>
        </>

    );
};

export default Valuers;
