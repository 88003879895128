import { ChevronDownIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    Container,
    Flex,
    HStack,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Spacer,
    Text,
} from '@chakra-ui/react';
import { Logout } from 'iconsax-react';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import LanguageToggleButton from '../LanguageToggleButton';
import Logo from '../Logo';
import useMe from '../../../utils/api/hooks/useMe';
import SiteLoading from '../SiteLoading';
import { useSmartTranslation } from '../../hooks';

type PropsType = {
    children: ReactNode;
};

const PageContainerBasicWithUser = (props: PropsType) => {
    const { children } = props;

    const t = useSmartTranslation('menubar');
    const navigate = useNavigate();

    const {
        data: user,
        isLoading: userLoading,
    } = useMe();

    if (userLoading) {
        return <SiteLoading />;
    }

    return (
        <Box
            bgColor="wvwGreen"
            paddingTop="1.5rem"
            h="100vh"
            w="100%"
        >
            <Container
                maxW="container.lg"
                bg="white"
                borderRadius="lg"
                p="2em"
                w="100%"
                justifyContent="center"
                alignContent="center"
            >
                <Flex
                    bg="white"
                    w="100%"
                    boxShadow="0 0 8px lightwvwGrey"
                    align="center"
                >
                    <Logo withColor />

                    <Spacer />

                    <HStack
                        align="center"
                        spacing="1rem"
                    >
                        <Box paddingInline=".8rem">
                            <LanguageToggleButton />
                        </Box>

                        <Text
                            pr=".5rem"
                            fontWeight="500"
                        >
                            {`${user?.firstName} ${user?.lastName}`}
                        </Text>

                        <Menu>
                            <MenuButton
                                as={Button}
                                bg="white"
                                rightIcon={<ChevronDownIcon />}
                                w="1rem"
                            />

                            <MenuList>
                                <MenuItem
                                    paddingBlock=".8rem"
                                    onClick={() => navigate('/logout')}
                                    icon={<Logout />}
                                >
                                    <Text paddingInline=".5rem">
                                        {t('Logout')}
                                    </Text>
                                </MenuItem>
                            </MenuList>
                        </Menu>
                    </HStack>
                </Flex>

                {children}
            </Container>
        </Box>
    );
};

export default PageContainerBasicWithUser;
