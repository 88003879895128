/* eslint-disable prefer-arrow-callback */
import { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { FormikForm } from '../../common/forms';
import { DirectEnquiryDataType } from './types';
import EnquiryFormBox from '../create-client-enquiry/common/EnquiryFormBox';
import ValuationStandards from '../create-client-enquiry/enquiry-valuation-info/valuation-forms/ValuationStandards';
import ValuationPurpose from '../create-client-enquiry/enquiry-valuation-info/valuation-forms/ValuationPurpose';
import CompletionDate from '../create-client-enquiry/enquiry-valuation-info/valuation-forms/CompletionDate';
import ValuationType from '../create-client-enquiry/enquiry-valuation-info/valuation-forms/ValuationType';
import EnquiryContainer from '../../common/components/display/enquiry-form/display/EnquiryContainer';
import { DIRECT_ENQUIRY_STEPS, DIRECT_ENQUIRY_STEPS_VALUATION } from './DirectEnquirySteps';
import { FlowContextStateful } from '../../common/components/display/ProcessFlowStateful';
import { enquiryCommonValuerMemberships, enquiryOtherValuerMemberships } from '../../common/vars/valuationsAndMembershipTypes';

const baseValiadtionSchema = {
    valuationType: Yup.string().required('errors.selectValuationType'),
    valuationSpecial: Yup.array()
        .test('valuationSpecial', 'errors.required', function (value = []) {
            const {
                valuationType = '',
                valuationOther = '',
            } = this.parent;

            if (valuationType === 'Other Valuation') {
                if (value.length === 0 && !valuationOther) {
                    return false;
                }
            }

            return true;
        }),
    valuationOther: Yup.string()
        .test('valuationSpecial', 'errors.required', function (value) {
            const {
                valuationType = '',
                valuationSpecial = [],
            } = this.parent;

            if (valuationType === 'Other Valuation') {
                if (valuationSpecial.length === 0 && !value) {
                    return false;
                }
            }

            return true;
        }),
    valuationPurposes: Yup.array()
        .min(1, 'errors.selectValuationPurpose')
        .required('errors.selectValuationPurpose'),
    completionDate: Yup.string().required('errors.selectCompletionDate'),
};

const individualValidationSchema = Yup.object(baseValiadtionSchema);

const entityValidationSchema = Yup.object({
    ...baseValiadtionSchema,
    valuationStandards: Yup.string().required('errors.selectValuationStandard'),
    valuationStandardsExtra: Yup.string()
        .test('valuationStandardsExtra', 'errors.required', function (value) {
            const {
                valuationStandards = '',
                valuationStandardsOther = '',
            } = this.parent;

            if (valuationStandards === 'More') {
                if (!value && !valuationStandardsOther) {
                    return false;
                }
            }

            return true;
        }),
    valuationStandardsOther: Yup.string()
        .test('valuationStandardsOther', 'errors.required', function (value) {
            const {
                valuationStandards = '',
                valuationStandardsExtra = '',
            } = this.parent;

            if (valuationStandards === 'More') {
                if (!value && valuationStandardsExtra === 'Other') {
                    return false;
                }
            }

            return true;
        }),
});

type PropTypes = {
    onContinue: (values: any, goNext: () => void) => void;
    enquiryData: DirectEnquiryDataType;
};

const DirectValuationType = (props: PropTypes) => {
    const { onContinue, enquiryData } = props;

    const { clientType = 'individual' } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation(['enquiryform', 'common']);

    const { goNext } = useContext(FlowContextStateful);

    const useIndividualForm = clientType === 'individual';

    const validationSchema = useIndividualForm
        ? individualValidationSchema : entityValidationSchema;

    const valuationTypeSection = {
        key: 'valuationType',
        header: t('newEnquiry.valuationSection.valuationScope.sectionTitle'),
        subHeader: t('newEnquiry.valuationSection.valuationScope.leadQuestion'),
        element: <ValuationType />,
    };

    const valuationStandardsSection = {
        key: 'valuationStandards',
        header: t('newEnquiry.valuationSection.valuationStandards.sectionTitle'),
        subHeader: t('newEnquiry.valuationSection.valuationStandards.leadQuestion'),
        element: <ValuationStandards />,
    };

    const valuationPurposeSection = {
        key: 'valuationPurposes',
        header: t('newEnquiry.valuationSection.valuationPurpose.sectionTitle'),
        subHeader: t('newEnquiry.valuationSection.valuationPurpose.leadQuestion'),
        element: <ValuationPurpose reducedOptions={useIndividualForm} />,
    };

    const completionDateSection = {
        key: 'completionDate',
        header: t('newEnquiry.valuationSection.completionDate.sectionTitle'),
        subHeader: t('newEnquiry.valuationSection.completionDate.leadQuestion'),
        element: <CompletionDate />,
    };

    const individualContent = [
        valuationTypeSection,
        valuationPurposeSection,
        completionDateSection,
    ];

    const entityContent = [
        valuationTypeSection,
        valuationStandardsSection,
        valuationPurposeSection,
        completionDateSection,
    ];

    const content = useIndividualForm ? individualContent : entityContent;

    let valuationStandards = '';
    let valuationStandardsExtra = '';

    if (!enquiryData.valuationStandards) {
        valuationStandards = '';
    } else if (enquiryCommonValuerMemberships.includes(enquiryData.valuationStandards)) {
        valuationStandards = enquiryData.valuationStandards;
    } else if (enquiryOtherValuerMemberships.includes(enquiryData.valuationStandards)) {
        valuationStandards = 'More';
        valuationStandardsExtra = enquiryData.valuationStandards;
    }

    return (
        <EnquiryContainer
            currentStep={DIRECT_ENQUIRY_STEPS_VALUATION}
            steps={DIRECT_ENQUIRY_STEPS}
        >
            <FormikForm
                validationSchema={validationSchema}
                initialValues={{
                    valuationType: enquiryData.valuationType || '',
                    valuationSpecial: enquiryData.valuationSpecial || [],
                    retypeDocuments: enquiryData.retypeDocuments || [],
                    valuationOther: enquiryData.valuationOther || '',
                    completionDate: enquiryData.completionDate || '',
                    valuationStandards,
                    valuationStandardsExtra,
                    valuationStandardsOther: enquiryData.valuationStandardsOther || '',
                    valuationPurposes: enquiryData.valuationPurposes || [],
                    valuationPurposesOther: enquiryData.valuationPurposesOther || '',
                }}
                onSubmit={values => {
                    let valuationStandard = values.valuationStandards;

                    if (values.valuationStandards === 'More') {
                        valuationStandard = values.valuationStandardsExtra;
                    }

                    onContinue(
                        {
                            ...values,
                            valuationStandards: valuationStandard,
                        },
                        goNext,
                    );
                }}
            >
                <EnquiryFormBox
                    onBack={() => navigate('/dashboard')}
                    content={content}
                />
            </FormikForm>
        </EnquiryContainer>
    );
};

export default DirectValuationType;
