import { ChakraProvider, createStandaloneToast } from '@chakra-ui/react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { QueryClient, QueryClientProvider } from 'react-query';
import './App.css';
import Router from './Router';
import theme from './theme';
import MaintenanceMode from './maintenance-mode/MaintenanceMode';

const { REACT_APP_MAINTENANCE_MODE = 'true' } = process.env;

const inMaintenanceMode = REACT_APP_MAINTENANCE_MODE === 'true';

const queryClient = new QueryClient();

const { ToastContainer } = createStandaloneToast();

const App = () => (
    <QueryClientProvider client={queryClient}>
        <DndProvider backend={HTML5Backend}>
            <ChakraProvider theme={theme}>
                {inMaintenanceMode && (
                    <MaintenanceMode />
                )}

                {!inMaintenanceMode && (
                    <Router />
                )}
            </ChakraProvider>
        </DndProvider>

        <ToastContainer />
    </QueryClientProvider>
);

export default (App);
