import { HStack, VStack } from '@chakra-ui/react';
import { useRole, useSmartTranslation, useStandards } from '../../../../../common/hooks';
import CompanyDetailsPiCoverEdit from './CompanyDetailsPiCoverEdit';
import LoadingSpinner from '../../../../../common/components/LoadingSpinner';
import { FormikInput, FormikSelect } from '../../../../../common/forms';
import FormSection from '../../../profile/components/FormSection';

const CompanyDetailsOther = () => {
    const t = useSmartTranslation('account');
    const {
        userIsValuer,
        userIsCompanyAdmin,
        roleLoading,
    } = useRole();

    const { yearsOfExperience } = useStandards();

    if (roleLoading) return <LoadingSpinner />;

    if (userIsValuer && userIsCompanyAdmin) {
        return (
            <FormSection
                header={t('companyDetails.otherInformation')}
                variant="white"
                element={(
                    <VStack width="100%">
                        <HStack
                            align="start"
                            spacing="6"
                            w="100%"
                        >
                            <FormikInput
                                label={t('companyDetails.numberOfPartners')}
                                name="valuerNumberOfPartners"
                                placeholder={t('fieldPlaceholder.numberOfPartners', { ns: 'common' })}
                                type="number"
                            />

                            <FormikSelect
                                name="valuerYearsOfExperience"
                                label={t('companyDetails.yearsOfExperience')}
                                placeholder={t('fieldPlaceholder.numberOfYears', { ns: 'common' })}
                                options={yearsOfExperience}
                            />
                        </HStack>

                        <CompanyDetailsPiCoverEdit />
                    </VStack>
                )}
            />
        );
    }

    return null;
};

export default CompanyDetailsOther;
