import { VStack } from '@chakra-ui/react';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FlowContext } from '../../../../../common/components/display/ProcessFlowRouted';
import LoadingSpinner from '../../../../../common/components/LoadingSpinner';
import { FormikForm } from '../../../../../common/forms';
import { standardValuerMemberships } from '../../../../../common/vars/valuationsAndMembershipTypes';
import { useMe } from '../../../../../utils/api/hooks';
import ProfileCompletionFormFooter from './ProfileCompletionFormFooter';
import ProfileValuationStandardsEdit from '../../../profile/components/ProfileValuationStandardsEdit';

const ProfileCompletionQualifications = () => {
    const { goNext } = useContext(FlowContext);

    const [saveAndClose, setSaveAndClose] = useState(false);

    const navigate = useNavigate();

    const {
        data: user,
        isLoading,
        updateMe,
    } = useMe({
        onUpdateSuccess: () => {
            if (saveAndClose) {
                navigate('/dashboard', { replace: true });
            } else {
                goNext();
            }
        },
    });

    if (isLoading) return <LoadingSpinner />;

    const updateUser = (values: any) => {
        const {
            standardMemberships: formStandardMemberships,
            otherMemberships: formOtherMemberships,
        } = values;

        // Clean up form data before updating user
        const standardMemberships = formStandardMemberships
            .filter((i: string) => standardValuerMemberships.includes(i));

        const otherMemberships = formStandardMemberships.includes('Other') ? formOtherMemberships : [];

        updateMe({

            standardMemberships,
            otherMemberships,
        });
    };

    const {
        standardMemberships = [],
        otherMemberships = [],
    } = user;

    // Ensure that only standard qualifications are displayed, incase the standard list ever changes
    const formDataStandardMemberships = standardMemberships
        .filter(i => standardValuerMemberships.includes(i));

    if (otherMemberships.length > 0) {
        formDataStandardMemberships.push('Other');
    }

    return (
        <FormikForm
            initialValues={{
                standardMemberships: formDataStandardMemberships,
                otherMemberships,
            }}
            onSubmit={values => {
                updateUser({
                    standardQualifications: values.standardQualifications,
                    otherQualifications: values.otherQualifications,
                    standardMemberships: values.standardMemberships,
                    otherMemberships: values.otherMemberships,
                });
            }}
        >
            <VStack spacing={4}>
                <ProfileValuationStandardsEdit />

                <ProfileCompletionFormFooter handleSaveAndClose={() => setSaveAndClose(true)} />
            </VStack>
        </FormikForm>
    );
};

export default ProfileCompletionQualifications;
