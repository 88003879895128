import { VStack } from '@chakra-ui/react';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormikForm } from '../../../../../common/forms';
import CompanyDetailsDetailsEdit from '../../../account/index/common/CompanyDetailsDetailsEdit';
import LoadingSpinner from '../../../../../common/components/LoadingSpinner';
import { useMyEntity } from '../../../../../utils/api/hooks';
import ProfileCompletionFormFooter from './ProfileCompletionFormFooter';
import { FlowContext } from '../../../../../common/components/display/ProcessFlowRouted';
import { useRole } from '../../../../../common/hooks';
import parseDelimitedNumber from '../../../../../common/functions/parseDelimitedNumber';

/**
 * Form for editing the current user's company details in the profile completion wizard.
 */

const ProfileCompletionBilling = () => {
    const { goNext } = useContext(FlowContext);

    const [saveAndClose, setSaveAndClose] = useState(false);

    const navigate = useNavigate();

    const { userIsCompanyAdmin, userIsValuer, roleLoading } = useRole();

    const {
        data: entity,
        updateEntity,
        isLoading: entityLoading,
    } = useMyEntity({
        onUpdateSuccess: () => {
            if (saveAndClose) {
                navigate('/dashboard', { replace: true });
            } else {
                goNext();
            }
        },
    });

    if (entityLoading || roleLoading) {
        return <LoadingSpinner />;
    }

    const initialFormData = {
        name: entity.name || '',
        contactFirstName: entity.contactFirstName || '',
        contactLastName: entity.contactLastName || '',
        contactNumberDialCode: entity.contactNumberDialCode || '',
        contactNumber: entity.contactNumber || '',
        street: entity.street || '',
        city: entity.city || '',
        postalCode: entity.postalCode || '',
        country: entity.country || '',
        vatNumber: entity.vatNumber || '',
        websiteAddress: entity.websiteAddress || '',
        companyEmail: entity.companyEmail || '',
        valuerYearsOfExperience: entity.valuerYearsOfExperience || '',
        valuerNumberOfPartners: Number(entity.valuerNumberOfPartners) || '',
        piCoverValue: entity.piCoverValue || 0,
        piCoverPercentage: entity.piCoverPercentage || 0,
        piCoverValidityDate: entity.piCoverValidityDate || '',
        piCoverSpecialArrangementsAvailable: entity.piCoverSpecialArrangementsAvailable || false,
    };

    const update = (values: any) => {
        const {
            valuerNumberOfPartners,
            piCoverValue,
            piCoverPercentage,
            piCoverValidityDate,
            specialArrangementsAvailable,
        } = values;

        const valuerNumberOfPartnersAsString = valuerNumberOfPartners.toString();

        updateEntity({
            name: values.name,
            contactFirstName: values.contactFirstName,
            contactLastName: values.contactLastName,
            contactNumberDialCode: values.contactNumberDialCode,
            contactNumber: values.contactNumber,
            street: values.street,
            city: values.city,
            postalCode: values.postalCode,
            country: values.country,
            vatNumber: values.vatNumber,
            websiteAddress: values.websiteAddress,
            companyEmail: values.companyEmail,
        });

        if (userIsValuer) {
            const piCoverValueasNumber = Number(
                parseDelimitedNumber(piCoverValue.toString()),
            );

            const piCoverPercentageAsNumber = Number(
                parseDelimitedNumber(piCoverPercentage.toString()),
            );

            updateEntity({
                valuerYearsOfExperience: values.valuerYearsOfExperience,
                valuerNumberOfPartners: valuerNumberOfPartnersAsString,
                piCoverValue: piCoverValueasNumber,
                piCoverPercentage: piCoverPercentageAsNumber,
                piCoverValidityDate,
                piCoverSpecialArrangementsAvailable: specialArrangementsAvailable,
            });
        }
    };

    return (
        <FormikForm
            initialValues={initialFormData}
            onSubmit={values => {
                update(values);
            }}
        >
            <VStack spacing={4}>
                {userIsCompanyAdmin && (
                    <CompanyDetailsDetailsEdit />
                )}

                <ProfileCompletionFormFooter
                    back={false}
                    handleSaveAndClose={() => setSaveAndClose(true)}
                />
            </VStack>
        </FormikForm>
    );
};

export default ProfileCompletionBilling;
