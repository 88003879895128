import { useEffect, useRef, useState } from 'react';
import Autocomplete from 'react-google-autocomplete';
import GoogleMap from '../GoogleMap';

const { REACT_APP_GOOGLEMAPS_API_KEY } = process.env;

type ValuesType = {
    street: string | undefined;
    postalCode: string | undefined;
    city: string | undefined;
    country: string | undefined;
    lat: number | undefined;
    lng: number | undefined;
};

type PropsType = {
    values: ValuesType;
    placeholder?: string;
    onChange: (values: ValuesType) => void;
};

const MapsAutocomplete = (props: PropsType) => {
    const {
        values,
        placeholder,
        onChange,
    } = props;

    const ref = useRef<HTMLInputElement>(null);

    const [localValues, setLocalValues] = useState(values);

    const validValuesSaved = values.street
        && values.city
        && values.country
        && values.postalCode
        && values.lat
        && values.lng;

    let defaultValue = '';

    if (validValuesSaved) {
        defaultValue = `${values.street}, ${values.postalCode}, ${values.city}, ${values.country}`;
    }

    useEffect(() => {
        if (
            localValues.street
            && localValues.city
            && localValues.country
            && localValues.postalCode
            && localValues.lat
            && localValues.lng
        ) {
            onChange(localValues);
        } else {
            onChange({
                street: undefined,
                postalCode: undefined,
                city: undefined,
                country: undefined,
                lat: undefined,
                lng: undefined,
            });
        }
    }, [localValues]);

    return (
        <>
            <Autocomplete
                ref={ref}
                placeholder={placeholder}
                defaultValue={defaultValue}
                onChange={() => setLocalValues({
                    street: undefined,
                    postalCode: undefined,
                    city: undefined,
                    country: undefined,
                    lat: undefined,
                    lng: undefined,
                })}
                className="form-control"
                apiKey={REACT_APP_GOOGLEMAPS_API_KEY}
                options={{
                    types: ['address'],
                }}
                onPlaceSelected={place => {
                    let streetNumber = '';
                    let street;
                    let postalCode;
                    let city;
                    let country;
                    const lat = place?.geometry?.location?.lat();
                    const lng = place?.geometry?.location?.lng();

                    place?.address_components?.forEach(component => {
                        const { types, long_name: value } = component;

                        if (types.includes('street_number')) {
                            streetNumber = value;
                        }
                        if (types.includes('route')) {
                            street = value;
                        }
                        if (types.includes('locality')) {
                            city = value;
                        }
                        if (types.includes('postal_code')) {
                            postalCode = value;
                        }
                        if (types.includes('country')) {
                            country = value;
                        }
                    });

                    setLocalValues({
                        street: `${streetNumber} ${street}`.trim(),
                        postalCode,
                        city,
                        country,
                        lat,
                        lng,
                    });
                }}
                style={{
                    width: '100%',
                    padding: '10px',
                    fontSize: '16px',
                    border: '1px solid #e2e8f0',
                    borderRadius: '8px',
                }}
            />

            {validValuesSaved && (
                <GoogleMap
                    latitude={localValues.lat}
                    longitude={localValues.lng}
                />
            )}
        </>
    );
};

MapsAutocomplete.defaultProps = {
    placeholder: '',
};

export default MapsAutocomplete;
