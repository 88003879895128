import { Box, Divider, VStack } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { FormikCloudSelect, FormikConditionalSection, FormikListInput } from '../../../../common/forms';
import { useSmartTranslation } from '../../../../common/hooks';
import { standardValuerQualifications } from '../../../../common/vars/valuationsAndMembershipTypes';
import FormSectionProfileEdit from './FormSectionProfileEdit';
import { useMe } from '../../../../utils/api/hooks';
import { WvwText, WVWTitle } from '../../../../common/components/typography';
import UploadCertificate from '../../../../common/components/UploadCertificate';
import storage from '../../../../utils/storage/storage';
import LoadingSpinner from '../../../../common/components/LoadingSpinner';
import { wvwPalette } from '../../../../theme';

const VALUER_QUALIFICATIONS = [
    ...standardValuerQualifications,
    'Other',
];

/**
 * Formik form section for editing the current user's office details.
 */
const ProfileQualificationsEdit = () => {
    const t = useSmartTranslation('profile');

    const {
        data: user,
        myCertificates = [],
        myCertificatesRefresh,
        isLoading: loadingUser,
    } = useMe();

    const { values } = useFormikContext();

    if (loadingUser) return <LoadingSpinner />;

    const {
        standardQualifications: standardQualificationsWithOther = [],
        otherQualifications = [],
    } = values as any;

    const standardQualifications = standardQualificationsWithOther
        .filter((i: string) => standardValuerQualifications.includes(i));

    const selectedQualifications = [
        ...standardQualifications,
        ...otherQualifications,
    ];

    const { id: userId } = user;

    return (
        <FormSectionProfileEdit
            header={t('heading.membershipsAndQualifications')}
            subHeader={t('subHeading.membershipsAndQualifications')}
        >
            <FormikCloudSelect
                name="standardQualifications"
                options={VALUER_QUALIFICATIONS.map(qualification => ({
                    label: t(qualification, { ns: 'formik', defaultValue: qualification }),
                    value: qualification,
                }))}
            />

            <FormikConditionalSection
                formDataTarget="standardQualifications"
                condition={value => value.includes('Other')}
            >
                <VStack
                    align="start"
                    backgroundColor={wvwPalette.wvwBackground.main}
                    borderRadius="8"
                    padding="1rem"
                    spacing="4"
                    w="100%"
                >
                    <Box>
                        <WVWTitle
                            level="3"
                            color="black"
                            content={t('heading.otherQualifications')}
                        />

                        <WvwText>
                            {t('subHeading.otherQualifications')}
                        </WvwText>
                    </Box>

                    <FormikListInput
                        name="otherQualifications"
                        placeholder={t('membershipsAndQualifications.edit.addQualification')}
                    />
                </VStack>
            </FormikConditionalSection>

            <Divider />

            {selectedQualifications.map(qualification => {
                const files = myCertificates
                    .filter(
                        i => i.type.toLowerCase() === qualification.toLowerCase(),
                    )
                    .map(i => ({
                        name: i.label,
                        fileUrl: i.file.fullPath,
                    }));

                const isStandardQualification = standardValuerQualifications
                    .includes(qualification);

                const title = isStandardQualification ? t(qualification, { ns: 'formik', defaultValue: qualification }) : qualification;

                return (
                    <VStack
                        key={qualification}
                        align="start"
                        spacing="4"
                        w="100%"
                    >
                        <WVWTitle
                            level="2"
                            color="black"
                            content={`${title} ${t('membershipsAndQualifications.certificate', { count: 2 })}`}
                        />

                        <UploadCertificate
                            defaultFileName={files.length === 0 ? `${t(qualification, { ns: 'formik', defaultValue: qualification })} ${t('membershipsAndQualifications.certificate', { count: 1 })}` : undefined}
                            files={files}
                            onFileDelete={fileUrl => {
                                storage.deleteUserFile(fileUrl)
                                    .then(myCertificatesRefresh);
                            }}
                            onRename={(fileUrl, label) => {
                                storage.updateFileMetadata(fileUrl, label)
                                    .then(myCertificatesRefresh);
                            }}
                            onUpload={fileList => {
                                fileList.forEach(file => {
                                    storage.uploadUserCertificate(
                                        userId,
                                        file,
                                        qualification,
                                    ).then(myCertificatesRefresh);
                                });
                            }}
                        />
                    </VStack>
                );
            })}
        </FormSectionProfileEdit>
    );
};

export default ProfileQualificationsEdit;
