import {
    Alert,
    AlertIcon,
    Button,
    HStack,
    VStack,
    Heading,
    Text,
    Container,
    Spacer,
    Checkbox,
    Box,
    FormLabel,
    Flex,
    Link,
} from '@chakra-ui/react';
import {
    MultiFactorError,
    signInWithEmailAndPassword,
} from 'firebase/auth';
import { useState } from 'react';
import { Link as ReactLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import YupPassword from 'yup-password';
import { useTranslation } from 'react-i18next';
import { auth } from '../../../utils/firebase';
import Logo from '../../../common/components/Logo';
import { FormikForm, FormikInput, FormikPassword } from '../../../common/forms';
import LanguageToggleButton from '../../../common/components/LanguageToggleButton';
import { useMe } from '../../../utils/api/hooks';
import Footer from '../../../common/components/Footer';

YupPassword(Yup);

type PropTypes = {
    onLogin: (verify?: boolean) => void;
    setAuthError: (error: MultiFactorError) => void;
};

const validationSchema = Yup.object({
    email: Yup.string()
        .email('Invalid email address')
        .required('errors.required'),
    password: Yup.string()
        .password()
        .required('errors.required'),
});

type AlertBannerPropTypes = {
    alertText: string;
};

const AlertBanner = (props: AlertBannerPropTypes) => {
    const { alertText } = props;

    return (
        <Box
            bgColor="red20"
        >
            <Alert
                status="error"
            >
                <AlertIcon
                    color="red"
                />
                {alertText}
            </Alert>
        </Box>
    );
};

const Login = (props: PropTypes) => {
    const {
        onLogin,
        setAuthError,
    } = props;

    const { t } = useTranslation(['login', 'common', 'formik']);
    const navigate = useNavigate();

    const [error, setError] = useState('');

    const { refresh } = useMe();

    return (
        <Box
            w="100%"
            h="100vh"
            bgColor="wvwGreen"
            paddingTop="2rem"
            paddingInline="1rem"
        >
            <Container
                maxW="container.lg"
                padding="1rem"
                backgroundColor="white"
                borderRadius="lg"
            >
                <HStack
                    align="stretch"
                >
                    <Flex
                        flexDirection="column"
                        align="left"
                        padding="1.5rem"
                        maxW="45%"
                        color="white"
                        borderRadius="lg"
                        bgGradient={[
                            'linear-gradient(141.87deg, #10528F 8.08%, #3396BF 85.18%)',
                        ]}
                    >
                        <Logo />

                        <Spacer />

                        <Box
                            paddingBlock="8rem"
                        >
                            <Heading>
                                {t('wvwWelcome')}
                            </Heading>

                            <Spacer h="1.5rem" />

                            <Text>
                                {t('wvwBlurb')}
                            </Text>
                        </Box>

                    </Flex>

                    <VStack
                        paddingTop="1rem"
                        paddingBottom="3rem"
                        paddingInline="2.5rem"
                        w="55%"
                        align="left"
                        spacing="6"
                    >
                        <Box
                            w="100%"
                            display="flex"
                            justifyContent="flex-end"
                        >
                            <HStack
                                alignItems="center"
                                flexWrap="wrap"
                                spacing={0}
                            >
                                <Text
                                    paddingRight=".5rem"
                                >
                                    {t('noAccountQ')}
                                </Text>

                                <Link
                                    as={ReactLink}
                                    to="/register"
                                    color="wvwGreen"
                                    fontWeight="bold"
                                >
                                    {t('link.register')}
                                </Link>
                            </HStack>

                            <Box
                                pl="1rem"
                                alignSelf="right"
                            >
                                <LanguageToggleButton />
                            </Box>
                        </Box>

                        <Box paddingBlock="3rem">
                            <VStack
                                align="left"
                                spacing="6"
                            >
                                <Box>
                                    <Heading as="h2" size="lg">
                                        {t('loginHeading')}
                                    </Heading>

                                    <Spacer h="1rem" />
                                    <Text>{t('welcomeBlurb')}</Text>
                                </Box>

                                {error === 'Firebase: Error (auth/user-not-found).' && (
                                    <AlertBanner
                                        alertText={t('error.authError.userNotFound', { ns: 'common' })}
                                    />
                                )}

                                {error === 'Firebase: Error (auth/wrong-password).' && (
                                    <AlertBanner
                                        alertText={t('error.authError.wrongPassword', { ns: 'common' })}
                                    />
                                )}

                                {error !== 'Firebase: Error (auth/user-not-found).' && error !== 'Firebase: Error (auth/wrong-password).' && error !== '' && (
                                    <AlertBanner
                                        alertText={t('error.authError.tryAgain', { ns: 'common' })}
                                    />
                                )}

                                <FormikForm
                                    initialValues={{
                                        email: '',
                                        password: '',
                                    }}
                                    validationSchema={validationSchema}
                                    onSubmit={values => {
                                        signInWithEmailAndPassword(
                                            auth,
                                            values.email,
                                            values.password,
                                        )
                                            .then(() => {
                                                refresh();
                                                onLogin();
                                            })
                                            .catch((err: MultiFactorError) => {
                                                if (err.code === 'auth/multi-factor-auth-required') {
                                                    setAuthError(err);

                                                    onLogin(true);

                                                    return;
                                                }

                                                setError(err.message);
                                            });
                                    }}
                                >
                                    <FormLabel color="wvwGreen">{t('Email', { ns: 'common' })}</FormLabel>
                                    <FormikInput
                                        name="email"
                                        type="email"
                                        placeholder={t('instruction.pleaseEnter', { item: t('instruction.email') })}
                                    />

                                    <Spacer h="1rem" />

                                    <FormLabel color="wvwGreen">{t('Password', { ns: 'common' })}</FormLabel>
                                    <FormikPassword
                                        name="password"
                                        placeholder={t('instruction.pleaseEnter', { item: t('instruction.password') })}
                                    />

                                    <Spacer h="1rem" />

                                    <HStack
                                        flexWrap="wrap"
                                        justifyContent="space-between"
                                        w="100%"
                                    >
                                        <Checkbox>{t('rememberMe')}</Checkbox>

                                        <Button
                                            color="wvwGreen"
                                            padding={0}
                                            variant="none"
                                            flexWrap="wrap"
                                            onClick={() => { navigate('/forgot-password'); }}
                                        >
                                            {t('link.resetPassword')}
                                        </Button>
                                    </HStack>

                                    <Button
                                        variant="primaryYellow"
                                        width={{
                                            sm: '10rem',
                                            md: '14rem',
                                        }}
                                        marginBlock="1.5rem"
                                        type="submit"
                                    >
                                        {t('button.login', { ns: 'common' })}
                                    </Button>
                                </FormikForm>
                            </VStack>

                        </Box>
                    </VStack>
                </HStack>
            </Container>

            <Footer invertColor />
        </Box>
    );
};

export default Login;
