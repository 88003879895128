import {
    Badge,
    Box,
    Button,
    HStack,
    Spacer,
    VStack,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEnquiry, useMyValuerPanels, usePublicUser } from '../../../../../utils/api/hooks';
import { EnquiryType } from '../../../../../types';
import LoadingSpinner from '../../../../../common/components/LoadingSpinner';
import ValuerOfferBanner from './ValuerOfferBanner';
import GeneralEnquiryOverview from '../../../../../common/components/display/enquiry-form/overview/GeneralEnquiryOverview';
import CancelledEnquiryBanner from './CancelledEnquiryBanner';
import DraftEnquiryBanner from './DraftEnquiryBanner';
import { WVWLabel, WVWTitle } from '../../../../../common/components/typography';
import { ContentBox } from '../../../../../common/components/display';
import PublicUserSlider from '../../../../../common/components/user-details/PublicUserSlider';
import ProfilePicture from '../../../../../common/components/display/ProfilePicture';

const ValuerEnquiryOverview = () => {
    const { enquiryId = '' } = useParams();
    const { t } = useTranslation(['enquiryform', 'enquiries', 'common', 'account']);

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const {
        enquiry: enquiryData = {} as unknown as EnquiryType,
        isLoading: enquiryLoading,
    } = useEnquiry({ enquiryId });

    const {
        data: valuerPanels = [],
        isLoading: valuerPanelsLoading,
    } = useMyValuerPanels();

    const isPanelClient = valuerPanels.some(panel => panel.id === enquiryData.fromEntityId);

    const obscureClient = !isPanelClient;

    const {
        data: client,
        isLoading: clientLoading,
        profilePictureUrl = '',
        profilePictureUrlIsLoading,
    } = usePublicUser(enquiryData.fromUserId, obscureClient);

    if (
        enquiryLoading
        || clientLoading
        || valuerPanelsLoading
        || !client
        || profilePictureUrlIsLoading
    ) return <LoadingSpinner />;

    return (
        <VStack
            w="100%"
            align="left"
            paddingBottom="4rem"
        >
            {enquiryData.status === 'CANCELLED' && (
                <CancelledEnquiryBanner />
            )}

            {enquiryData.status === 'DRAFT' && (
                <DraftEnquiryBanner />
            )}

            {enquiryData.status !== 'CANCELLED' && enquiryData.status !== 'DRAFT' && (
                <ValuerOfferBanner />
            )}

            <ContentBox color="white">
                <HStack
                    alignItems="center"
                    paddingInline="1.5rem"
                    spacing="4"
                >
                    <ProfilePicture
                        url={profilePictureUrl}
                        size="4rem"
                    />

                    <Box
                        paddingBlock=".8rem"
                        w="100%"
                    >
                        <WVWTitle
                            level="2"
                            content={`${client.firstName} ${client.lastName}`}
                        />

                        <WVWLabel content={client.entity.name} />

                        {isPanelClient && (
                            <Button
                                variant="none"
                                size="max-content"
                                color="wvwYellow"
                                alignSelf="flex-start"
                                fontWeight="400"
                                onClick={() => setIsDrawerOpen(true)}
                            >
                                {t('button.viewClient', { ns: 'common' })}
                            </Button>
                        )}
                    </Box>

                    <Spacer />

                    {isPanelClient && (
                        <Box
                            paddingInline=".8rem"
                            alignSelf="flex-start"
                        >
                            <Badge
                                variant="subtle"
                                textColor="wvwGreen"
                                borderRadius=".3rem"
                                paddingInline="1rem"
                            >
                                {client.entity.companyOrIndividual === 'COMPANY' ? `${t('integration.customerTypes.institution', { ns: 'account' })}` : `${t('integration.customerTypes.private', { ns: 'account' })}`}
                            </Badge>
                        </Box>
                    )}
                </HStack>

                <PublicUserSlider
                    isOpen={isDrawerOpen}
                    onClose={() => setIsDrawerOpen(false)}
                    userId={enquiryData.fromUserId}
                    viewMode="ENQUIRY"
                />
            </ContentBox>

            <GeneralEnquiryOverview
                variant="white"
                overviewData={enquiryData}
                obscureAccessDetails
            />
        </VStack>
    );
};

export default ValuerEnquiryOverview;
