import { VStack } from '@chakra-ui/react';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormikForm } from '../../../../common/forms';
import ProfileValuationOccasionsEdit from '../../profile/components/ProfileValuationOccasionsEdit';
import ProfileValuationTypesEdit from '../../profile/components/ProfileValuationTypesEdit';
import ProfileCompletionFormFooter from './components/ProfileCompletionFormFooter';
import { FlowContext } from '../../../../common/components/display/ProcessFlowRouted';
import { useMe } from '../../../../utils/api/hooks';
import LoadingSpinner from '../../../../common/components/LoadingSpinner';
import { standardValuationTypes } from '../../../../common/vars/valuationsAndMembershipTypes';
import ProfilePropertyTypesEdit from '../../profile/components/ProfilePropertyTypesEdit';

const ProfileCompletionCompetencies = () => {
    const { goNext } = useContext(FlowContext);

    const [saveAndClose, setSaveAndClose] = useState(false);

    const navigate = useNavigate();

    const {
        data: user,
        isLoading,
        updateMe,
    } = useMe({
        onUpdateSuccess: () => {
            if (saveAndClose) {
                navigate('/dashboard', { replace: true });
            } else {
                goNext();
            }
        },
    });

    if (isLoading) {
        return <LoadingSpinner />;
    }

    const updateUser = (values: any) => {
        const {
            valuationTypes: formValuationTypes,
            valuationOccasions,
            otherValuationOccasions: formOtherValuationOccasions,
            ...rest
        } = values;

        // Clean up form data before updating user
        const valuationTypes = formValuationTypes
            .filter((i: string) => standardValuationTypes.includes(i));

        const otherValuationOccasions = valuationOccasions.includes('Other') ? formOtherValuationOccasions : [];

        updateMe({
            ...rest,
            valuationTypes,
            valuationOccasions,
            otherValuationOccasions,
        });
    };

    const {
        valuationTypes = [],
        specialValuationTypes = [],
        otherValuationTypes = [],
        valuationOccasions = [],
        otherValuationOccasions = [],
        valuationPropertyTypes = [],
    } = user;

    return (
        <FormikForm
            initialValues={{
                valuationTypes,
                specialValuationTypes,
                otherValuationTypes,
                valuationOccasions,
                otherValuationOccasions,
                valuationPropertyTypes,
            }}
            onSubmit={values => updateUser(values)}
        >
            <VStack spacing={4}>
                <ProfileValuationTypesEdit />

                <ProfilePropertyTypesEdit />

                <ProfileValuationOccasionsEdit />

                <ProfileCompletionFormFooter handleSaveAndClose={() => setSaveAndClose(true)} />
            </VStack>
        </FormikForm>
    );
};

export default ProfileCompletionCompetencies;
