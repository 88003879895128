import {
    createStandaloneToast,
    Heading,
    VStack,
    Text,
    Flex,
    Box,
    Button,
    Spacer,
    useDisclosure,
    Divider,
    HStack,
    Link,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    useMyValuerPanels,
    useValuerInvitesReceived,
    useValuerInvitesRespond,
    useEntityRemoveMeFromPanel,
    useUsersByEntity,
    useMyEntity,
} from '../../../../../utils/api/hooks';
import CustomerInviteForm from './CustomerInviteForm';
import LoadingSpinner from '../../../../../common/components/LoadingSpinner';
import ConfirmationModal from '../../../../../common/components/ConfirmationModal';
import ValuerSentClientInvites from './ValuerSentClientInvites';
import { useRole } from '../../../../../common/hooks';
import ProfilePictureDefault from '../../../../../common/components/display/ProfilePictureDefault';
import PublicCompanyProfilePicture from '../../../../../common/components/PublicCompanyProfilePicture';
import PublicEntitySlider from '../../../../../common/components/entity-details/PublicEntitySlider';
import { WvwButton } from '../../../../../common/components/inputs';

type EntityDrawerProps = {
    isOpen: boolean;
    entityId: number;
};

const ValuerPanelPermissions = () => {
    const [openDrawer, setOpenDrawer] = useState<EntityDrawerProps>({
        isOpen: false,
        entityId: 0,
    });

    const {
        isOpen: isDeclineOpen,
        onOpen: onDeclineOpen,
        onClose: onDeclineClose,
    } = useDisclosure();

    const {
        isOpen: isAddOpen,
        onOpen: onAddOpen,
        onClose: onAddClose,
    } = useDisclosure();

    const {
        isOpen,
        onOpen,
        onClose,
    } = useDisclosure();

    const { t } = useTranslation(['account', 'common']);

    const [selectedValuer, setSelectedValuer] = useState<string | number>('');
    const [selectedInvite, setSelectedInvite] = useState<string | number>('');

    const { toast } = createStandaloneToast();

    const { userIsCompanyAdmin: userIsAdmin, userIsUser } = useRole();

    const {
        data: entity,
    } = useMyEntity();

    const {
        usersList = [],
    } = useUsersByEntity(entity?.id);

    const adminEmails = usersList.filter(u => u.role === 'ADMIN').map(u => u.email);

    const {
        data: valuerPanels,
        isLoading: valuerPanelsLoading,
        error: valuerPanelsError,
        refresh: refreshValuerPanels,
    } = useMyValuerPanels();

    const {
        data: pendingInvites,
        isLoading: pendingInvitesLoading,
        error: pendingInvitesError,
        refresh,
    } = useValuerInvitesReceived();

    const { update: updateRemoveMe } = useEntityRemoveMeFromPanel({
        onSuccess: () => {
            refreshValuerPanels();
        },
        onError: err => {
            toast({
                title: t('error.error', { ns: 'common' }),
                description: err.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        },
    });

    const { update } = useValuerInvitesRespond({
        onSuccess: () => {
            refresh();
            refreshValuerPanels();
        },
        onError: err => {
            toast({
                title: t('error.error', { ns: 'common' }),
                description: err.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        },
    });

    return (
        <VStack
            w="100%"
            align="left"
            paddingInline=".5rem"
            paddingBlock="2rem"
            spacing="10"
        >
            <Flex>
                <VStack
                    alignSelf="flex-start"
                    spacing="4"
                    w="25%"
                >
                    <Heading
                        as="h4"
                        size="md"
                        fontWeight="light"
                        w="100%"
                    >
                        {t('valuerPanels')}
                    </Heading>

                    <Text color="wvwGrey">
                        {t('valuerPanelsBlurb')}
                    </Text>

                    {userIsAdmin && (
                        <>
                            <Button
                                fontWeight="light"
                                onClick={onAddOpen}
                                variant="primaryYellow"
                                w="9rem"
                            >
                                {t('addCustomer')}
                            </Button>

                            <CustomerInviteForm
                                isOpen={isAddOpen}
                                onClose={onAddClose}
                            />
                        </>
                    )}

                    {userIsUser && (
                        <Link
                            href={`mailto:${adminEmails}?subject=${t('customerPanelDetails.button.requestCustomerInvite', { ns: 'account' })}`}
                        >
                            <Button
                                fontWeight="light"
                                variant="primaryYellow"
                                w="fit"
                            >
                                {t('customerPanelDetails.button.requestCustomerInvite', { ns: 'account' })}
                            </Button>
                        </Link>
                    )}

                </VStack>

                <Spacer />

                <VStack
                    w="70%"
                    paddingTop="1.5rem"
                >
                    {valuerPanelsLoading && (
                        <LoadingSpinner />
                    )}

                    {valuerPanelsError !== null && (
                        <VStack>
                            <Heading size="sm">
                                {t('error.weAreSorry')}
                            </Heading>

                            <Text>
                                {t('vListError')}
                            </Text>
                        </VStack>
                    )}

                    {valuerPanels?.length === 0 && (
                        <Text>
                            {t('notInAPanel')}
                        </Text>
                    )}

                    {valuerPanels?.map(company => (
                        <Box
                            key={company.id}
                            w="100%"
                        >
                            <Flex
                                alignItems="center"
                                w="100%"
                                height="3.5rem"
                                border="1px solid"
                                borderColor="wvwGrey40"
                                bg="wvwGrey05"
                                borderRadius="8"
                                paddingInline="1rem"
                                color="wvwGreen"
                                _hover={{ color: 'wvwYellow', cursor: 'pointer' }}
                                onClick={() => {
                                    setOpenDrawer({
                                        isOpen: true,
                                        entityId: company.id,
                                    });
                                }}
                            >
                                <HStack>
                                    <PublicCompanyProfilePicture
                                        companyId={company.id}
                                        size="2.5rem"
                                    />

                                    <Box paddingBlock="1rem">
                                        <Text
                                            color="inherit"
                                            fontWeight="medium"
                                        >
                                            {company.name ? company.name : t('companyDetails.noNameProvided')}
                                        </Text>
                                    </Box>
                                </HStack>

                                <Spacer />

                                {userIsAdmin && (
                                    <WvwButton
                                        variant="danger"
                                        size="sm"
                                        onClick={e => {
                                            e.stopPropagation();
                                            onOpen();
                                            setSelectedValuer(company.id);
                                        }}
                                        content={t('button.leave', { ns: 'common' })}
                                    />
                                )}
                            </Flex>
                        </Box>
                    ))}
                </VStack>
            </Flex>

            <ConfirmationModal
                cancelButton={t('button.cancel', { ns: 'common' })}
                continueButton={t('button.leave', { ns: 'common' })}
                isOpen={isOpen}
                title={t('warning.leavePanel.title', { ns: 'common' })}
                content={t('warning.leavePanel.prompt', { ns: 'common' })}
                onCancel={onClose}
                onContinue={() => {
                    updateRemoveMe(selectedValuer);
                    onClose();
                }}
            />

            {userIsAdmin && (
                <>
                    <Divider />

                    <Flex>
                        <Box w="25%">
                            <Heading
                                as="h4"
                                size="md"
                                fontWeight="light"
                            >
                                {t('customerInvites.sectionTitle')}
                            </Heading>

                            <Text
                                color="wvwGrey"
                                paddingTop="1rem"
                            >
                                {t('invitedToClients')}
                            </Text>
                        </Box>

                        <Spacer />

                        <VStack
                            w="70%"
                            paddingTop="1.5rem"
                        >
                            {pendingInvitesLoading && (
                                <LoadingSpinner />
                            )}

                            {pendingInvitesError !== null && (
                                <VStack>
                                    <Heading
                                        size="sm"
                                    >
                                        {t('error.weAreSorry')}
                                    </Heading>

                                    <Text>
                                        {t('inviteLoadError')}
                                    </Text>
                                </VStack>
                            )}

                            {pendingInvites?.length === 0 && (
                                <Text>
                                    {t('valuerInvites.noPendingInvitesMsg')}
                                </Text>
                            )}

                            {pendingInvites?.map(invite => (
                                <Flex
                                    w="100%"
                                    height="3.5rem"
                                    border="1px solid"
                                    borderColor="wvwGrey40"
                                    bg="wvwGrey05"
                                    borderRadius="8"
                                    paddingInline="1rem"
                                    key={invite.id}
                                >
                                    <HStack>
                                        <ProfilePictureDefault
                                            accountType="CLIENT"
                                            userType="ENTITY"
                                            size="2.5rem"
                                        />

                                        <Box paddingBlock="1rem">
                                            <Text
                                                color="wvwGrey80"
                                                fontWeight="medium"
                                            >
                                                {invite.fromEntityName}
                                            </Text>
                                        </Box>
                                    </HStack>

                                    <Spacer />

                                    <HStack spacing="4">
                                        <WvwButton
                                            variant="danger"
                                            onClick={() => {
                                                setSelectedInvite(invite.id);
                                                onDeclineOpen();
                                            }}
                                            content={t('button.decline', { ns: 'common' })}
                                        />

                                        <WvwButton
                                            variant="success"
                                            onClick={() => update({ inviteId: invite.id, valuerResponse: 'ACCEPTED' })}
                                            content={t('button.accept', { ns: 'common' })}
                                        />
                                    </HStack>
                                </Flex>
                            ))}
                        </VStack>
                    </Flex>

                    <Divider />

                    <ValuerSentClientInvites />
                </>
            )}

            <ConfirmationModal
                cancelButton={t('button.close', { ns: 'common' })}
                continueButton={t('button.continue', { ns: 'common' })}
                isOpen={isDeclineOpen}
                title={t('warning.declineInvitation.title', { ns: 'common' })}
                content={t('warning.declineInvitation.prompt', { ns: 'common' })}
                onCancel={onDeclineClose}
                onContinue={() => {
                    onDeclineClose();
                    update({ inviteId: Number(selectedInvite), valuerResponse: 'DECLINED' });
                }}
            />

            {openDrawer.isOpen && (
                <PublicEntitySlider
                    isOpen={openDrawer.isOpen}
                    onClose={() => setOpenDrawer({ isOpen: false, entityId: 0 })}
                    entityId={openDrawer.entityId}
                />
            )}
        </VStack>
    );
};

export default ValuerPanelPermissions;
