import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalCloseButton,
    VStack,
    ModalFooter,
    Box,
    ModalHeader,
    HStack,
} from '@chakra-ui/react';
import * as Yup from 'yup';
import {
    FormikForm,
    FormikFileUpload,
    FormikAddressInputWithMap,
    FormikSubmitButton,
} from '../forms';
import delimitNumber from '../functions/delimitNumber';
import parseDelimitedNumber from '../functions/parseDelimitedNumber';
import ConfirmationModal from './ConfirmationModal';
import { Property } from '../../views/create-client-enquiry/enquiry-property-info/PropertyFormPropertyType';
import Logo from './Logo';
import { WvwText, WVWTitle } from './typography';
import { wvwPalette } from '../../theme';
import PropertyType from '../../views/create-client-enquiry/enquiry-property-info/property-forms/PropertyType';
import ExtraPropertyFeatures from '../../views/create-client-enquiry/enquiry-property-info/property-forms/ExtraPropertyFeatures';
import PropertyCondition from '../../views/create-client-enquiry/enquiry-property-info/property-forms/PropertyCondition';
import AccessDetails from '../../views/create-client-enquiry/enquiry-property-info/property-forms/AccessDetails';
import { useMe } from '../../utils/api/hooks';
import { UserType } from '../../types';

const validationSchema = Yup.object({
    street: Yup.string().required('errors.required'),
    city: Yup.string().required('errors.required'),
    country: Yup.string().required('errors.required'),
    postalCode: Yup.string().required('errors.required'),
    latitude: Yup.number().required('errors.required'),
    longitude: Yup.number().required('errors.required'),
    areaGrossInternal: Yup.string().required('errors.required'),
    estimateValue: Yup.string().required('Please enter an amount'),
    accessFName: Yup.string().required('errors.required'),
    accessLName: Yup.string().required('errors.required'),
    accessEmail: Yup.string().email('Invalid email address').required('errors.required'),
    accessMobileDialCode: Yup.string().required('errors.required').nullable(),
    accessMobile: Yup.number()
        .required('errors.required').typeError('errors.enterNumber'),
    propertyTypes: Yup.array().min(1, 'errors.required'),
});

type PropTypes = {
    onCancel: () => void;
    property: Property | undefined;
    saveProperty: (property: Property, updatedProp: Property | undefined) => void;
};

type PropertyFormPropTypes = {
    content: {
        key: string;
        header: string;
        subHeader?: string;
        element: React.ReactNode;
    }[];
};

const PropertyFormsContainer = (props: PropertyFormPropTypes) => {
    const { content } = props;

    return (
        <VStack
            align="center"
            spacing={4}
            w="100%"
            maxWidth="1600px"
            marginLeft="auto"
            marginRight="auto"
            paddingInline={['2rem', '2rem', '4rem', '8rem']}
            paddingBlock="1rem"
        >
            {content.map(item => (
                <VStack
                    w="100%"
                    bg="white"
                    borderRadius="10"
                    padding="1.5rem"
                    align="left"
                    key={item.key}
                >
                    <Box>
                        <WVWTitle
                            content={item.header}
                            color="black"
                            level="2"
                        />

                        <WvwText>
                            {item.subHeader}
                        </WvwText>
                    </Box>
                    {item.element}
                </VStack>
            ))}
        </VStack>
    );
};

const PropertyForm = (props: PropTypes) => {
    const {
        onCancel,
        property,
        saveProperty,
    } = props;

    const [confirmCancel, setConfirmCancel] = useState(false);

    const { t } = useTranslation(['enquiryform', 'common', 'enquiries']);

    const [isError, setIsError] = useState(false);

    const { data: user = {} as UserType } = useMe();

    const {
        firstName,
        lastName,
        email,
        mobileDialCode,
        mobile,
    } = user;

    const useMyDetails = (
        property?.accessFName === firstName
        && property?.accessLName === lastName
        && property?.accessEmail === email
        && property?.accessMobileDialCode === mobileDialCode
        && property?.accessMobile === mobile
    );

    if (!property) return null;

    return (
        <Modal
            isOpen
            onClose={() => saveProperty(property, undefined)}
            size="full"
            closeOnOverlayClick={false}
        >
            <ModalOverlay
                width="100%"
            />

            <ModalContent
                w="100%"
                minWidth="fit-content"
            >
                <FormikForm
                    validationSchema={validationSchema}
                    initialValues={{
                        street: property?.street || '',
                        city: property?.city || '',
                        country: property?.country || '',
                        postalCode: property?.postalCode || '',
                        latitude: property?.latitude,
                        longitude: property?.longitude,
                        buildYear: property?.buildYear,
                        propertyCondition: property?.propertyCondition || '',
                        propertyTypes: property?.propertyTypes || [],
                        agricultural: property?.agricultural || [],
                        land: property?.land || [],
                        commercial: property?.commercial || [],
                        development: property?.development || [],
                        residential: property?.residential || [],
                        specialRealEstate: property?.specialRealEstate || [],
                        extraFeatures: [],
                        propertyFeatures: property?.propertyFeatures || [],
                        otherFeatures: property?.otherFeatures || [],
                        documents: property?.documents || [],
                        areaGrossInternal: delimitNumber(property?.areaGrossInternal),
                        areaOverall: delimitNumber(property?.areaOverall),
                        estimateValue: delimitNumber(property?.estimateValue),
                        estimateGVD: delimitNumber(property?.estimateGVD),
                        useMyDetails,
                        accessFName: property?.accessFName || '',
                        accessLName: property?.accessLName || '',
                        accessEmail: property?.accessEmail || '',
                        accessMobileDialCode: property?.accessMobileDialCode || '',
                        accessMobile: property?.accessMobile || '',
                        accessNote: property?.accessNote || '',
                    }}
                    onSubmit={values => {
                        const hasFeatures = values.agricultural.concat(
                            values.land,
                            values.commercial,
                            values.development,
                            values.residential,
                            values.specialRealEstate,
                        );

                        if (hasFeatures == null) {
                            setIsError(true);
                            return;
                        }

                        const newProperty: Property = {
                            street: values.street,
                            city: values.city,
                            country: values.country,
                            postalCode: values.postalCode,
                            latitude: values.latitude.toString(),
                            longitude: values.longitude.toString(),
                            buildYear: values.buildYear,
                            propertyCondition: values.propertyCondition,
                            propertyTypes: values.propertyTypes,
                            agricultural: values.agricultural,
                            land: values.land,
                            commercial: values.commercial,
                            development: values.development,
                            residential: values.residential,
                            specialRealEstate: values.specialRealEstate,
                            propertyFeatures: values.propertyFeatures,
                            documents: values.documents,
                            areaGrossInternal: Number(
                                parseDelimitedNumber(values.areaGrossInternal),
                            ),
                            areaOverall: Number(
                                parseDelimitedNumber(values.areaOverall),
                            ),
                            estimateValue: Number(
                                parseDelimitedNumber(values.estimateValue),
                            ),
                            estimateGVD: Number(
                                parseDelimitedNumber(values.estimateGVD === '' ? 0 : values.estimateGVD),
                            ),
                            otherFeatures: values.otherFeatures,
                            useMyDetails: values.useMyDetails,
                            accessFName: values.accessFName,
                            accessLName: values.accessLName,
                            accessEmail: values.accessEmail,
                            accessMobileDialCode: values.accessMobileDialCode,
                            accessMobile: values.accessMobile,
                            accessNote: values.accessNote,
                        };

                        saveProperty(property, newProperty);
                    }}
                >
                    <ModalHeader
                        bgColor="white"
                        width="100%"
                    >
                        <HStack spacing="0">
                            <Logo size="5rem" withColor />

                            <WVWTitle
                                color="black"
                                level="1"
                                content={property !== undefined && Object.keys(property).length === 0 ? t('newEnquiry.addingProperty.sectionTitle.add') : t('newEnquiry.addingProperty.sectionTitle.edit')}
                            />
                        </HStack>
                    </ModalHeader>

                    <ModalCloseButton />

                    <ModalBody
                        bgColor={wvwPalette.wvwBackground.main}
                        width="100%"
                    >
                        <PropertyFormsContainer
                            content={[
                                {
                                    key: 'propertyDetails',
                                    header: t('newEnquiry.addingProperty.address.sectionTitle'),
                                    subHeader: '',
                                    element: <FormikAddressInputWithMap
                                        cityName="city"
                                        countryName="country"
                                        postalCodeName="postalCode"
                                        streetName="street"
                                        latName="latitude"
                                        lngName="longitude"
                                        mapTitle={t('mapInputInstructions.propertyAccess', { ns: 'formik' })}
                                        mapInstruction={t('mapInputInstructions.confirmLocation', { ns: 'formik' })}
                                    />,
                                },
                                {
                                    key: 'propertyDescription',
                                    header: t('newEnquiry.addingProperty.propertyDescription.sectionTitle'),
                                    subHeader: t('newEnquiry.addingProperty.propertyDescription.typeQ'),
                                    element: <PropertyType />,
                                },
                                {
                                    key: 'extraFeatures',
                                    header: t('newEnquiry.addingProperty.propertyDescription.extraFeatures'),
                                    element: <ExtraPropertyFeatures />,
                                },
                                {
                                    key: 'propertyCondition',
                                    header: t('newEnquiry.addingProperty.propertyDescription.propertyCondition'),
                                    element: <PropertyCondition isError={isError} />,
                                },
                                {
                                    key: 'supportingDocuments',
                                    header: t('newEnquiry.addingProperty.propertyDescription.supportDocs.sectionTitle'),
                                    element: (
                                        <VStack align="left">
                                            <WvwText>
                                                {t('newEnquiry.addingProperty.propertyDescription.supportDocs.instruction')}
                                            </WvwText>
                                            <FormikFileUpload name="documents" />
                                        </VStack>
                                    ),
                                },
                                {
                                    key: 'accessDetails',
                                    header: t('modal.accessDetails.title', { ns: 'inspection' }),
                                    subHeader: t('modal.accessDetails.form.instruction', { ns: 'inspection' }),
                                    element: <AccessDetails client={user} />,
                                },
                            ]}
                        />
                    </ModalBody>

                    <ModalFooter bgColor={wvwPalette.wvwBackground.main}>
                        <Box width="15rem">
                            <FormikSubmitButton
                                block
                                content={Object.keys(property).length === 0 ? t('button.save', { ns: 'common' }) : t('button.update', { ns: 'common' })}
                            />
                        </Box>
                    </ModalFooter>
                </FormikForm>
            </ModalContent>

            <ConfirmationModal
                isOpen={confirmCancel}
                title={t('newEnquiry.addingProperty.cancelProperty.sectionTitle', { ns: 'enquiryform' })}
                content={t('newEnquiry.addingProperty.cancelProperty.blurb', { ns: 'enquiryform' })}
                continueButtonVariant="danger"
                continueButton={t('button.leave', { ns: 'common' })}
                cancelButtonVariant="success"
                cancelButton={t('button.stay', { ns: 'common' })}
                onContinue={() => {
                    onCancel();
                    setConfirmCancel(false);
                }}
                onCancel={() => {
                    setConfirmCancel(false);
                }}
            />

        </Modal>
    );
};

export default PropertyForm;
