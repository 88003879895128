/* eslint-disable max-len */
import { Flex, HStack, VStack } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { useSmartTranslation } from '../../../../../common/hooks';
import FormSection from '../../../profile/components/FormSection';
import { WvwText } from '../../../../../common/components/typography';
import ProfilePictureEditor from '../../../../../common/components/ProfilePictureEditor';
import {
    FormikCountrySelect, FormikInput,
} from '../../../../../common/forms';

/**
 * Formik form section for editing the current user's company billing details.
 */

const CompanyDetailsBilling = () => {
    const t = useSmartTranslation('account');

    const renderInputRow = (inputOne: ReactNode, inputTwo: ReactNode) => (
        <HStack
            align="start"
            spacing="6"
            w="100%"
        >
            {inputOne}
            {inputTwo}
        </HStack>
    );

    return (
        <FormSection
            header={t('companyDetails.billingAddress')}
            variant="white"
            element={(
                <Flex justifyContent="space-between" flexWrap={{ sm: 'wrap-reverse', md: 'revert' }}>
                    <VStack
                        spacing="6"
                        w="100%"
                    >
                        {renderInputRow(
                            <FormikInput
                                name="name"
                                label={t('companyDetails.name')}
                                placeholder={t('fieldPlaceholder.companyName', { ns: 'common' })}
                            />,
                            <FormikInput
                                name="street"
                                label={t('companyDetails.street')}
                                placeholder={t('companyDetails.street')}
                            />,
                        )}
                        {renderInputRow(
                            <HStack
                                align="flex-start"
                                w="100%"
                                rowGap="4"
                                columnGap="4"
                                flexWrap={{
                                    base: 'wrap',
                                    md: 'nowrap',
                                }}
                            >
                                <FormikInput
                                    name="postalCode"
                                    label={t('companyDetails.postalCode')}
                                    placeholder={t('companyDetails.postalCode')}
                                />

                                <FormikInput
                                    name="city"
                                    label={t('companyDetails.city')}
                                    placeholder={t('companyDetails.city')}
                                />
                            </HStack>,
                            <HStack
                                alignItems="top"
                                w="100%"
                                rowGap="4"
                                columnGap="4"
                                flexWrap={{
                                    base: 'wrap',
                                    md: 'nowrap',
                                }}
                            >
                                <FormikCountrySelect
                                    name="country"
                                    label={t('companyDetails.country')}
                                    w="100%"
                                />

                                <FormikInput
                                    name="vatNumber"
                                    label={t('companyDetails.vatNumber')}
                                    placeholder={t('companyDetails.vatNumber')}
                                />
                            </HStack>,
                        )}
                    </VStack>

                    <VStack
                        marginBottom="1rem"
                        paddingInline={{ base: '0rem', md: '2rem' }}
                    >
                        <WvwText>
                            {t('companyDetails.companyLogo')}
                        </WvwText>

                        <ProfilePictureEditor forEntity />
                    </VStack>
                </Flex>
            )}
        />
    );
};

export default CompanyDetailsBilling;
