import {
    Box,
    Grid,
    GridItem,
    SimpleGrid,
    Text,
    VStack,
} from '@chakra-ui/react';
import { FormikInput, FormikCountrySelect } from '../../../../common/forms';
import { useSmartTranslation } from '../../../../common/hooks';

const EnquiryContactForm = () => {
    const t = useSmartTranslation('enquiryform');

    return (
        <VStack
            w="100%"
            align="left"
        >
            <Grid
                templateColumns="repeat(2, 1fr)"
                gap="6"
            >
                <GridItem>
                    <FormikInput
                        name="invoiceFName"
                        label={t('fieldPlaceholder.firstName', { ns: 'common' })}
                        placeholder={t('fieldPlaceholder.firstName', { ns: 'common' })}
                        type="text"
                    />
                </GridItem>

                <GridItem>
                    <FormikInput
                        name="invoiceLName"
                        label={t('fieldPlaceholder.lastName', { ns: 'common' })}
                        placeholder={t('fieldPlaceholder.lastName', { ns: 'common' })}
                        type="text"
                    />
                </GridItem>

                <GridItem colSpan={2}>
                    <Box>
                        <Text>
                            {t('newEnquiry.invoiceSection.contactDetails.emailClarifier1')}
                        </Text>

                        <Text>
                            {t('newEnquiry.invoiceSection.contactDetails.emailClarifier2')}
                        </Text>
                    </Box>
                </GridItem>

                <GridItem>
                    <FormikInput
                        name="invoiceEmail"
                        label={t('fieldPlaceholder.email', { ns: 'common' })}
                        placeholder={t('fieldPlaceholder.email', { ns: 'common' })}
                        type="email"
                    />
                </GridItem>

                <GridItem>
                    <SimpleGrid columns={2} spacing="6">
                        <Box>
                            <FormikCountrySelect
                                name="invoiceMobileDialCode"
                                label={t('fieldPlaceholder.country', { ns: 'common' })}
                                selectDialCode
                            />
                        </Box>

                        <Box>
                            <FormikInput
                                name="invoiceMobile"
                                label={t('fieldPlaceholder.phoneNumber', { ns: 'common' })}
                                placeholder={t('fieldPlaceholder.phoneNumber', { ns: 'common' })}
                            />
                        </Box>
                    </SimpleGrid>
                </GridItem>
            </Grid>
        </VStack>
    );
};

export default EnquiryContactForm;
