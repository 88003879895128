import {
    Box,
    Button,
    ButtonGroup,
    Divider,
    Flex,
    HStack,
    Spacer,
    Text,
    VStack,
} from '@chakra-ui/react';
import { DocumentCloud } from 'iconsax-react';
import { useState } from 'react';
import {
    useDrag,
    useDrop,
} from 'react-dnd';
import { useTranslation } from 'react-i18next';
import {
    DOCUMENT_IN_ACTION_AREA,
    DocumentInActionAreaType,
    DocumentRequestDocumentType,
    RequestDropTargetType,
} from '../../../../../types';
import { FormikForm, FormikInput } from '../../../../../common/forms';
import { useDocumentRequestUpdateDocument } from '../../../../../utils/api/hooks';
import ConfirmationModal from '../../../../../common/components/ConfirmationModal';
import WvwDeleteIconButton from '../../../../../common/components/WvwDeleteIconButton';
import WvwEditIconButton from '../../../../../common/components/display/WvwEditIconButton';

type PropTypes = {
    document: DocumentRequestDocumentType;
    isDeleteable?: boolean;
    isEditable?: boolean;
    moveItem: (id: number, to: number) => void;
    findItem: (id: number) => { index: number };
    resetOrder: () => void;
};

const DocumentListItem = (props: PropTypes) => {
    const {
        document,
        isDeleteable,
        isEditable,
        moveItem,
        findItem,
        resetOrder,
    } = props;

    const {
        id: documentId,
        caseId = null as unknown as number,
        name,
    } = document;

    const { t } = useTranslation('common');

    const [editing, setEditing] = useState(false);
    const [deleting, setDeleting] = useState(false);

    const {
        update: updateDocument,
        busy: documentUpdating,
    } = useDocumentRequestUpdateDocument({
        caseId,
        documentId,
    });

    const originalIndex = findItem(documentId).index;

    const [{ isDragging }, drag] = useDrag(
        () => ({
            type: DOCUMENT_IN_ACTION_AREA,
            item: {
                type: DOCUMENT_IN_ACTION_AREA,
                originalIndex,
                documentId: document.id,
                documentName: document.name,
            },
            collect: monitor => ({
                isDragging: monitor.isDragging(),
            }),
            canDrag: () => !editing,
            end: (item, monitor) => {
                const didDrop = monitor.didDrop();
                const dropResult = monitor.getDropResult() as RequestDropTargetType;

                if (!didDrop) {
                    resetOrder();
                } else {
                    updateDocument({
                        documentId: item.documentId,
                        sortIndex: findItem(item.documentId).index,
                        documentRequestId: dropResult.requestId,
                        folderId: dropResult.folderId,
                    });
                }
            },
        }),
        [document, originalIndex, moveItem],
    );

    const [, drop] = useDrop(
        () => ({
            accept: DOCUMENT_IN_ACTION_AREA,
            hover(item: DocumentInActionAreaType) {
                if (item.documentId !== documentId) {
                    const { index: overIndex } = findItem(documentId);

                    moveItem(item.documentId, overIndex);
                }
            },
        }),
        [findItem, moveItem],
    );

    const opacity = isDragging ? 0 : 1;

    return (
        <Box
            opacity={opacity}
            ref={node => drag(drop(node))}
        >
            <VStack
                opacity={isDragging ? 0 : 1}
                paddingInline=".5rem"
                paddingTop="1rem"
                w="100%"
            >
                <Flex w="100%">
                    {editing && (
                        <FormikForm
                            submitting={documentUpdating}
                            initialValues={{ name }}
                            onSubmit={values => {
                                updateDocument({ documentId, name: values.name });

                                setEditing(false);
                            }}
                        >
                            <VStack
                                align="left"
                                spacing={3}
                                w="100%"
                            >
                                <FormikInput
                                    name="name"
                                    placeholder={t('renameFile', { ns: 'documentmanagement' })}
                                />

                                <ButtonGroup
                                    alignSelf="flex-end"
                                    paddingBottom=".8rem"
                                >
                                    <Button
                                        onClick={() => setEditing(false)}
                                        variant="primary"
                                    >
                                        {t('button.cancel')}
                                    </Button>

                                    <Button
                                        type="submit"
                                        variant="primaryYellow"
                                        alignSelf="flex-end"
                                    >
                                        {t('button.save')}
                                    </Button>
                                </ButtonGroup>
                            </VStack>
                        </FormikForm>
                    )}

                    {!editing && (
                        <>
                            <HStack
                                spacing={3}
                                w="100%"
                            >
                                <DocumentCloud size="1.5rem" />

                                <Text fontWeight="500" maxW="10rem" noOfLines={3}>
                                    {name}
                                </Text>
                            </HStack>

                            <Spacer />

                            <HStack spacing="1rem">
                                {isEditable && (
                                    <WvwEditIconButton
                                        onClick={() => setEditing(true)}
                                        color="black"
                                    />
                                )}

                                {isDeleteable && (
                                    <WvwDeleteIconButton
                                        onClick={() => setDeleting(true)}
                                        color="black"
                                    />
                                )}
                            </HStack>
                        </>
                    )}
                </Flex>

                <Divider paddingTop=".8rem" />

                <ConfirmationModal
                    cancelButtonVariant="primary"
                    continueButton={t('button.delete')}
                    continueButtonVariant="danger"
                    content={t('modal.message.deleteDocument', { ns: 'documentmanagement' })}
                    isOpen={deleting}
                    onCancel={() => setDeleting(false)}
                    onContinue={() => {
                        updateDocument({
                            documentId,
                            deleted: true,
                        });

                        setDeleting(false);
                    }}
                    title={t('modal.title.deleteDocument', { ns: 'documentmanagement' })}
                />
            </VStack>
        </Box>
    );
};

DocumentListItem.defaultProps = {
    isDeleteable: false,
    isEditable: false,
};

export default DocumentListItem;
