import { Flex, HStack, VStack } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { useSmartTranslation } from '../../../../common/hooks';
import ProfilePictureEditor from '../../../../common/components/ProfilePictureEditor';
import { FormikCountrySelect, FormikInput } from '../../../../common/forms';
import { WvwText } from '../../../../common/components/typography';
import FormSection from './FormSection';

/**
 * Formik form section for editing the current user's personal contact details.
 */

type PropTypes = {
    minimumDetails?: boolean;
};

const ProfileContactDetailsEdit = (props: PropTypes) => {
    const t = useSmartTranslation('profile');

    const { minimumDetails } = props;

    const allDetails = minimumDetails === false;

    const renderInputRow = (inputOne: ReactNode, inputTwo: ReactNode) => (
        <HStack
            align="start"
            spacing="6"
            w="100%"
        >
            {inputOne}
            {inputTwo}
        </HStack>
    );

    return (
        <FormSection
            header={t('contactDetails')}
            variant="white"
            element={(
                <Flex justifyContent="space-between" flexWrap={{ sm: 'wrap-reverse', md: 'revert' }}>
                    <VStack
                        spacing="6"
                        w="100%"
                    >
                        {(allDetails || minimumDetails) && renderInputRow(
                            <FormikInput
                                noSubmitOnEnter
                                name="firstName"
                                label={t('profileDetails.firstName')}
                                placeholder={t('profileDetails.firstName')}
                            />,
                            <FormikInput
                                noSubmitOnEnter
                                name="lastName"
                                label={t('profileDetails.lastName')}
                                placeholder={t('profileDetails.lastName')}
                            />,
                        )}

                        {(allDetails || minimumDetails) && renderInputRow(
                            <FormikInput
                                noSubmitOnEnter
                                name="orgPosition"
                                label={t('profileDetails.roleInCompany')}
                                placeholder={t('profileDetails.roleInCompany')}
                            />,
                            <FormikInput
                                noSubmitOnEnter
                                name="email"
                                label={t('profileDetails.email')}
                                placeholder={t('profileDetails.email')}
                            />,
                        )}

                        {(allDetails || minimumDetails) && renderInputRow(
                            <FormikCountrySelect
                                name="mobileDialCode"
                                label={t('profileDetails.dialCode')}
                                selectDialCode
                                w="100%"
                            />,
                            <FormikInput
                                noSubmitOnEnter
                                name="mobile"
                                label={t('profileDetails.mobileNumber')}
                                placeholder={t('profileDetails.mobileNumber')}
                            />,
                        )}

                        {allDetails && renderInputRow(
                            <FormikCountrySelect
                                name="telDialCode"
                                label={t('profileDetails.dialCode')}
                                selectDialCode
                                w="100%"
                            />,
                            <FormikInput
                                noSubmitOnEnter
                                name="tel"
                                label={t('profileDetails.telNumber')}
                                placeholder={t('profileDetails.telNumber')}
                            />,
                        )}

                        {allDetails && renderInputRow(
                            <FormikCountrySelect
                                name="faxDialCode"
                                label={t('profileDetails.dialCode')}
                                selectDialCode
                                w="100%"
                            />,
                            <FormikInput
                                noSubmitOnEnter
                                name="fax"
                                label={t('profileDetails.faxNumber')}
                                placeholder={t('profileDetails.faxNumber')}
                            />,
                        )}
                    </VStack>

                    <VStack
                        marginBottom="1rem"
                        paddingInline={{ base: '0rem', md: '2rem' }}
                    >
                        <WvwText>
                            {t('profileDetails.profilePicture')}
                        </WvwText>

                        <ProfilePictureEditor />
                    </VStack>
                </Flex>
            )}
        />
    );
};

ProfileContactDetailsEdit.defaultProps = {
    minimumDetails: false,
};

export default ProfileContactDetailsEdit;
