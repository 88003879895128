import { ChevronDownIcon } from '@chakra-ui/icons';
import {
    Button,
    Link as ChakraLink,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
} from '@chakra-ui/react';
import { Eye } from 'iconsax-react';
import { Link as ReactLink } from 'react-router-dom';
import DataTable, { DataTableColumnType, DataTableFilterType } from '../../../common/components/datatable/DataTable';
import PageWithTitleLayout from '../../../common/components/display/PageWithTitleLayout';
import LoadingSpinner from '../../../common/components/LoadingSpinner';
import { displayDateWithCurrentLocale } from '../../../common/functions/displayDateInLocale';
import { useSmartTranslation } from '../../../common/hooks';
import { UploadedContractWithCaseAndClientAndValuerType } from '../../../types';
import { useAdminUploadedContracts } from '../../../utils/api/hooks';

type TableItem = UploadedContractWithCaseAndClientAndValuerType & {
    caseNumber: string;
    clientEntityName: string;
    clientUserName: string;
    valuerEntityName: string;
    valuerUserName: string;
};

const AWAITING_SIGNING = 'AWAITING_SIGNING';
const AWAITING_REVIEW = 'AWAITING_REVIEW';
const ACCEPTED = 'ACCEPTED';
const REJECTED = 'REJECTED';

const AdminUploadedContracts = () => {
    const t = useSmartTranslation('dashboard');

    const { data = [], isLoading } = useAdminUploadedContracts();

    if (isLoading) {
        return <LoadingSpinner />;
    }

    const contractsList = data.map(contract => ({
        ...contract,
        caseNumber: contract.case?.id.toString() || '',
        clientEntityName: contract.case?.clientEntity?.name || '',
        clientUserName: `${contract.case?.clientUser?.firstName} ${contract.case?.clientUser?.lastName}`.trim() || '',
        valuerEntityName: contract.case?.valuerEntity?.name || '',
        valuerUserName: `${contract.case?.valuerUser?.firstName} ${contract.case?.valuerUser?.lastName}`.trim() || '',
    }));

    const columns: DataTableColumnType<TableItem>[] = [
        {
            title: t('table.header.caseNumber'),
            render: i => i.caseNumber,
            sorter: (a, b) => a.caseNumber.localeCompare(b.caseNumber),
        },
        {
            title: t('table.header.clientEntity'),
            render: i => i.clientEntityName,
            sorter: (a, b) => a.clientEntityName.localeCompare(b.clientEntityName),
        },
        {
            title: t('table.header.clientUser'),
            render: i => i.clientUserName,
            sorter: (a, b) => a.clientUserName.localeCompare(b.clientUserName),
        },
        {
            title: t('table.header.valuerEntity'),
            render: i => i.valuerEntityName,
            sorter: (a, b) => a.valuerEntityName.localeCompare(b.valuerEntityName),
        },
        {
            title: t('table.header.valuerUser'),
            render: i => i.valuerUserName,
            sorter: (a, b) => a.valuerUserName.localeCompare(b.valuerUserName),
        },
        {
            title: t('table.header.createdAt'),
            render: i => displayDateWithCurrentLocale(i.createdAt),
            sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
        },
        {
            title: t('table.header.status'),
            render: i => i.signedContractReview,
            sorter: (a, b) => a.signedContractReview.localeCompare(b.signedContractReview),
        },
        {
            render: i => (
                <Menu>
                    <MenuButton
                        as={Button}
                        variant="primary"
                        borderRadius="30"
                        size="sm"
                        rightIcon={<ChevronDownIcon />}
                    >
                        {t('enquiriesTable.button.actions')}
                    </MenuButton>

                    <MenuList>
                        <ChakraLink
                            as={ReactLink}
                            to={`/admin/uploaded-contract/${i.id}`}
                        >
                            <MenuItem icon={<Eye size="1.2rem" />}>
                                {t('button.viewReport')}
                            </MenuItem>
                        </ChakraLink>
                    </MenuList>
                </Menu>
            ),
        },
    ];

    const tStatuses = (statuses: string[]) => statuses.map(status => ({
        label: t(`status.${status}`, { defaultValue: status, ns: 'casereport' }),
        value: status,
    }));

    const filters: DataTableFilterType<TableItem>[] = [
        {
            filterName: 'status',
            placeholder: t('table.header.status'),
            options: tStatuses([AWAITING_SIGNING, AWAITING_REVIEW, ACCEPTED, REJECTED]),
            filterFunction: (item, value) => item.signedContractReview === value,
        },
    ];

    return (
        <PageWithTitleLayout title={t('uploadedContracts')}>
            <DataTable
                columns={columns}
                data={contractsList}
                filters={filters}
            />
        </PageWithTitleLayout>
    );
};

export default AdminUploadedContracts;
