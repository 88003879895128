import { ChevronDownIcon } from '@chakra-ui/icons';
import {
    Button,
    Link as ChakraLink,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
} from '@chakra-ui/react';
import { Eye } from 'iconsax-react';
import { Link as ReactLink } from 'react-router-dom';
import DataTable, { DataTableColumnType, DataTableFilterType } from '../../../common/components/datatable/DataTable';
import { StatusBadgeCaseReport } from '../../../common/components/display';
import LoadingSpinner from '../../../common/components/LoadingSpinner';
import { displayDateWithCurrentLocale } from '../../../common/functions/displayDateInLocale';
import { useSmartTranslation } from '../../../common/hooks';
import { CaseReportWithCaseAndClientAndValuerType } from '../../../types';
import { useAdminCaseReports } from '../../../utils/api/hooks';
import PageWithTitleLayout from '../../../common/components/display/PageWithTitleLayout';

type TransformedCaseReport = CaseReportWithCaseAndClientAndValuerType & {
    caseNumber: string;
    clientEntityName: string;
    clientUserName: string;
    valuerEntityName: string;
    valuerUserName: string;
};

const ACCEPTED = 'ACCEPTED';
const REJECTED = 'REJECTED';
const SUBMITTED = 'SUBMITTED';

const AdminCaseReports = () => {
    const t = useSmartTranslation('dashboard');

    const { data: caseReportsList = [], isLoading } = useAdminCaseReports();

    if (isLoading) return <LoadingSpinner />;

    const caseReports = caseReportsList.map((caseReport): TransformedCaseReport => {
        const {
            case: {
                id,
                clientEntity,
                clientUser,
                valuerEntity,
                valuerUser,
            },
        } = caseReport;

        const caseNumber = `#${id}`;
        const clientEntityName = clientEntity.name;
        const clientUserName = `${clientUser.firstName} ${clientUser.lastName}`;
        const valuerEntityName = valuerEntity.name;
        const valuerUserName = `${valuerUser.firstName} ${valuerUser.lastName}`;

        return {
            ...caseReport,
            caseNumber,
            clientEntityName,
            clientUserName,
            valuerEntityName,
            valuerUserName,
        };
    });

    const headers: DataTableColumnType<TransformedCaseReport>[] = [
        {
            title: t('table.header.number'),
            render: r => `#${r.number}`,
            sorter: (a, b) => a.number - b.number,
        },
        {
            title: t('table.header.caseNumber'),
            render: r => r.caseNumber,
            sorter: (a, b) => a.caseNumber.localeCompare(b.caseNumber),
        },
        {
            title: t('table.header.clientEntity'),
            render: r => r.clientEntityName,
            sorter: (a, b) => a.clientEntityName.localeCompare(b.clientEntityName),
        },
        {
            title: t('table.header.clientUser'),
            render: r => r.clientUserName,
            sorter: (a, b) => a.clientUserName.localeCompare(b.clientUserName),
        },
        {
            title: t('table.header.valuerEntity'),
            render: r => r.valuerEntityName,
            sorter: (a, b) => a.valuerEntityName.localeCompare(b.valuerEntityName),
        },
        {
            title: t('table.header.valuerUser'),
            render: r => r.valuerUserName,
            sorter: (a, b) => a.valuerUserName.localeCompare(b.valuerUserName),
        },
        {
            title: t('table.header.createdAt'),
            render: r => displayDateWithCurrentLocale(r.createdAt),
            sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
        },
        {
            title: t('table.header.status'),
            render: r => <StatusBadgeCaseReport status={r.status} />,
            sorter: (a, b) => a.status.localeCompare(b.status),
        },
        {
            render: r => (
                <Menu>
                    <MenuButton
                        as={Button}
                        variant="primary"
                        borderRadius="30"
                        size="sm"
                        rightIcon={<ChevronDownIcon />}
                    >
                        {t('enquiriesTable.button.actions')}
                    </MenuButton>

                    <MenuList>
                        <ChakraLink
                            as={ReactLink}
                            to={`/admin/case-report/${r.id}`}
                        >
                            <MenuItem icon={<Eye size="1.2rem" />}>
                                {t('button.viewReport')}
                            </MenuItem>
                        </ChakraLink>
                    </MenuList>
                </Menu>
            ),
        },
    ];

    const tStatus = (status: string) => t(`status.${status}`, { defaultValue: status, ns: 'casereport' });

    const filters: DataTableFilterType<TransformedCaseReport>[] = [
        {
            filterName: 'status',
            placeholder: t('table.header.status'),
            options: [
                { label: tStatus(ACCEPTED), value: ACCEPTED },
                { label: tStatus(REJECTED), value: REJECTED },
                { label: tStatus(SUBMITTED), value: SUBMITTED },
            ],
            filterFunction: (item, value) => item.status === value,
        },
    ];

    return (
        <PageWithTitleLayout title="Admin Case Reports">
            <DataTable
                columns={headers}
                data={caseReports}
                filters={filters}
            />
        </PageWithTitleLayout>
    );
};

export default AdminCaseReports;
