import { sendEmailVerification, User } from 'firebase/auth';
import useMutationHook from '../useMutationHook';
import { useSmartTranslation } from '../../../../common/hooks';

type DtoType = {
    firebaseUser: User;
    redirectUrl: string;
};

const useSendEmailVerification = () => {
    const t = useSmartTranslation('common');

    const mutation = useMutationHook({
        mutationFunction: ({ firebaseUser, redirectUrl }: DtoType) => sendEmailVerification(
            firebaseUser,
            {
                url: `${window.location.origin}${redirectUrl}`,
            },
        ),
        refreshes: [],
        successMessage: t('emailVerificationSent'),
    });

    return mutation;
};

export default useSendEmailVerification;
