import { HStack, VStack, Text } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import {
    Folder,
    Message,
    Message2,
} from 'iconsax-react';
import {
    useDocumentRequestGetFolders,
    useDocumentRequestGetRequest,
    useDocumentRequestGetRequests,
} from '../../../../../../utils/api/hooks';
import { DocumentRequestType } from '../../../../../../types';
import LoadingModal from '../../../../../../common/components/LoadingModal';
import ModalDymanic from '../../../../../../common/components/ModalDynamic';
import RequestDetailsCommentSection from './common/RequestDetailsCommentSection';
import RequestDocuments from './common/RequestDocuments';
import RequestInformation from './common/RequestInformation';
import VerticalDividerWithIcon from '../../../../../../common/components/VerticalDivider';

const DocumentRequestDetailsModal = () => {
    const {
        caseId = '',
        requestId = '',
    } = useParams();
    const navigate = useNavigate();

    const [chatOpen, setChatOpen] = useState(false);

    const { data: folders = [] } = useDocumentRequestGetFolders({ caseId: Number(caseId) });

    const {
        data: request = {} as DocumentRequestType,
        isLoading: requestIsLoading,
    } = useDocumentRequestGetRequest(requestId);

    const {
        data: requestList = [],
        isLoading: requestListIsLoading,
    } = useDocumentRequestGetRequests({
        caseId,
        folderId: request.folderId,
    });

    const { folder } = request;

    if (requestIsLoading || requestListIsLoading) {
        return <LoadingModal />;
    }

    return (
        <ModalDymanic
            header={(
                <HStack w="100%">
                    <Folder />

                    <Text>
                        {folder?.name || ''}
                    </Text>
                </HStack>
            )}
            isOpen
            onClose={() => navigate('..')}
        >
            <HStack
                alignItems="flex-start"
                h="100%"
                minH="20rem"
            >
                {chatOpen && (
                    <VStack
                        align="left"
                        w="35%"
                    >
                        <RequestDetailsCommentSection
                            requestId={request.id}
                        />
                    </VStack>
                )}

                <VerticalDividerWithIcon
                    icon={chatOpen ? Message2 : Message}
                    onClick={() => setChatOpen(!chatOpen)}
                />

                <VStack
                    align="left"
                    paddingBottom="1.5rem"
                    paddingInline="1rem"
                    spacing={4}
                    w={chatOpen ? '65%' : '100%'}
                >
                    <RequestInformation
                        folders={folders}
                        request={request}
                        requestList={requestList}
                    />

                    <RequestDocuments request={request} />
                </VStack>
            </HStack>
        </ModalDymanic>
    );
};

export default DocumentRequestDetailsModal;
