import { ChevronDownIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    Container,
    Flex,
    HStack,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Spacer,
    Text,
    VStack,
} from '@chakra-ui/react';
import { Logout } from 'iconsax-react';
import { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate, useParams } from 'react-router-dom';
import { useMe } from '../../utils/api/hooks';
import Logo from '../../common/components/Logo';
import LanguageToggleButton from '../../common/components/LanguageToggleButton';
import Footer from '../../common/components/Footer';
import { WvwButton } from '../../common/components/inputs';
import { useSmartTranslation } from '../../common/hooks';
import { WvwText, WVWTitle } from '../../common/components/typography';
import { useSendEmailVerification } from '../../utils/api/hooks/firebase';
import { auth } from '../../utils/firebase';
import SiteLoading from '../../common/components/SiteLoading';

let emailSent = false;

const SendEmailVerification = () => {
    const { redirectUrl = '/' } = useParams();
    const t = useSmartTranslation('common');
    const navigate = useNavigate();

    const [resentEmail, setResentEmail] = useState(false);
    const [firebaseUser, firebaseUserLoading] = useAuthState(auth);

    const { update: sendEmailVerification } = useSendEmailVerification();

    const {
        data: user,
        isLoading: userLoading,
    } = useMe();

    useEffect(() => {
        if (firebaseUserLoading) return;
        if (!firebaseUser) return;

        if (firebaseUser.emailVerified) {
            navigate(redirectUrl, { replace: true });
        } else if (!emailSent) {
            sendEmailVerification({
                firebaseUser,
                redirectUrl,
            });

            emailSent = true;
        }
    }, [firebaseUserLoading, firebaseUser]);

    useEffect(() => {
        if (!resentEmail) return;

        setTimeout(() => {
            setResentEmail(false);
        }, 5000);
    }, [resentEmail]);

    if (firebaseUserLoading || !firebaseUser || userLoading) {
        return <SiteLoading />;
    }

    return (
        <Box
            bgColor="wvwGreen"
            paddingTop="1.5rem"
            h="100vh"
            w="100%"
        >
            <Container
                maxW="container.lg"
                bg="white"
                borderRadius="lg"
                p="2em"
                w="100%"
                justifyContent="center"
                alignContent="center"
            >
                <Flex
                    bg="white"
                    w="100%"
                    boxShadow="0 0 8px lightwvwGrey"
                    align="center"
                >
                    <Logo withColor />

                    <Spacer />

                    <HStack
                        align="center"
                        spacing="1rem"
                    >
                        <Box paddingInline=".8rem">
                            <LanguageToggleButton />
                        </Box>

                        <Text
                            pr=".5rem"
                            fontWeight="500"
                        >
                            {`${user?.firstName} ${user?.lastName}`}
                        </Text>

                        <Menu>
                            <MenuButton
                                as={Button}
                                bg="white"
                                rightIcon={<ChevronDownIcon />}
                                w="1rem"
                            />

                            <MenuList>
                                <MenuItem
                                    paddingBlock=".8rem"
                                    onClick={() => navigate('/logout')}
                                    icon={<Logout />}
                                >
                                    <Text paddingInline=".5rem">
                                        {t('Logout', { ns: 'menubar' })}
                                    </Text>
                                </MenuItem>
                            </MenuList>
                        </Menu>
                    </HStack>
                </Flex>

                <VStack
                    spacing="8"
                    pb="9em"
                    pt="6em"
                    w="100%"
                >
                    <WVWTitle
                        level="1"
                        content={t('emailVerification')}
                    />

                    <WvwText>
                        {t('emailVerificationText')}
                    </WvwText>

                    <WvwButton
                        isDisabled={resentEmail}
                        variant="primary"
                        content={t('button.resendVerification')}
                        onClick={() => {
                            setResentEmail(true);
                            sendEmailVerification({
                                firebaseUser,
                                redirectUrl,
                            });
                        }}
                    />
                </VStack>
            </Container>

            <Footer invertColor />
        </Box>
    );
};

export default SendEmailVerification;
