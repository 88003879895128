import { Box } from '@chakra-ui/react';
import LoadingSpinner from '../../../../../common/components/LoadingSpinner';
import { FileUploadInput } from '../../../../../common/inputs';
import { allowedFileTypes } from '../../../../../common/vars/file-uploads/file-standards';
import { useDocumentRequestUploadDocuments } from '../../../../../utils/api/hooks';

type PropTypes = {
    caseId: number;
    requestId?: number;
    submissionComment?: string;
};

const UploadDocumentToActionArea = (props: PropTypes) => {
    const {
        caseId,
        requestId,
        submissionComment,
    } = props;

    const {
        update: upload,
        busy: uploading,
    } = useDocumentRequestUploadDocuments({ caseId });

    if (uploading) {
        return (
            <Box
                border="1px dashed"
                borderColor="wvwGreen"
                borderRadius="4"
                marginBlock="2px"
                paddingBlock="1rem"
                w="100%"
            >
                <LoadingSpinner />
            </Box>
        );
    }

    return (
        <FileUploadInput
            fileTypes={allowedFileTypes}
            noRename
            onChange={files => {
                const submissionComments = Array(files.length).fill(submissionComment);

                upload({
                    caseId,
                    requestId,
                    files,
                    submissionComments,
                });
            }}
        />
    );
};

UploadDocumentToActionArea.defaultProps = {
    requestId: undefined,
    submissionComment: '',
};

export default UploadDocumentToActionArea;
