/* eslint-disable react/jsx-props-no-spreading */
import { Button, ButtonProps } from '@chakra-ui/react';
import { JSXElementConstructor, MouseEventHandler, ReactElement } from 'react';

type PropTypes = {
    block?: boolean;
    content: string;
    formSubmitButton?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    icon?: ReactElement<any, string | JSXElementConstructor<any>>;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    size?: 'sm' | 'md' | 'lg';
    width?: string;
    variant?: 'danger' | 'default' | 'link' | 'primary' | 'success' | 'outline' | 'ghost';
    isDisabled?: boolean;
};

const dangerButton = {
    bgColor: 'red',
    color: 'white',
    _hover: { bgColor: 'red80' },
};

const defaultButton = {
    _hover: { bgColor: 'darkWvwGreen' },
    bgColor: 'wvwGreen',
    color: 'white',
};

const linkButton = {
    color: 'wvwGreen',
    fontWeight: 'normal',
    padding: 0,
    variant: 'link',
    width: 'auto',
};

const primaryButton = {
    variant: 'primaryYellow',
};

const successButton = {
    bgColor: 'green',
    color: 'white',
    _hover: { bgColor: 'green80' },
};

const outlineButton = {
    border: '1px solid',
    backgroundColor: 'transparent',
    borderColor: 'wvwGreen',
    color: 'wvwGreen',
    _hover: {
        borderColor: 'wvwYellow',
        color: 'wvwYellow',
    },
};

const ghostButton = {
    border: 'none',
    backgroundColor: 'transparent',
    color: 'white',
    _hover: {
        bgColor: 'none',
    },
};

const WvwButton = (props: PropTypes) => {
    const {
        block,
        content,
        formSubmitButton,
        icon,
        onClick,
        size,
        width,
        variant,
        isDisabled,
    } = props;

    const baseProps: ButtonProps = {
        leftIcon: icon,
        onClick: onClick ? event => {
            event.stopPropagation();
            onClick(event);
        } : undefined,
        size,
        type: formSubmitButton ? 'submit' : 'button',
        w: block ? '100%' : width,
        minWidth: 'fit-content',
        isDisabled,
    };

    switch (variant) {
        case 'danger':
            return (
                <Button
                    {...baseProps}
                    {...dangerButton}
                >
                    {content}
                </Button>
            );
        case 'link':
            return (
                <Button
                    {...baseProps}
                    {...linkButton}
                >
                    {content}
                </Button>
            );
        case 'primary':
            return (
                <Button
                    {...baseProps}
                    {...primaryButton}
                >
                    {content}
                </Button>
            );
        case 'success':
            return (
                <Button
                    {...baseProps}
                    {...successButton}
                >
                    {content}
                </Button>
            );
        case 'outline':
            return (
                <Button
                    {...baseProps}
                    {...outlineButton}
                >
                    {content}
                </Button>
            );
        case 'ghost':
            return (
                <Button
                    {...baseProps}
                    {...ghostButton}
                >
                    {content}
                </Button>
            );
        default:
            return (
                <Button
                    {...baseProps}
                    {...defaultButton}
                >
                    {content}
                </Button>
            );
    }
};

WvwButton.defaultProps = {
    block: false,
    width: '15rem',
    formSubmitButton: false,
    icon: undefined,
    onClick: undefined,
    size: 'md',
    variant: 'default',
    isDisabled: false,
};

export default WvwButton;
