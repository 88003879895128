import {
    HStack,
    Box,
    VStack,
    Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { LabelAndData, WvwIcon } from '../../../../common/components/display';
import { WVWTitle } from '../../../../common/components/typography';

const CaseAttentionInfo = () => {
    const { t } = useTranslation('activecases');

    return (
        <Box
            padding="6"
            minWidth="75%"
            height="fit-content"
            borderRadius="lg"
            background="white"
        >
            <Text>
                * (Please note, this is a demo section and no live data is currently displayed.
                This will be replaced soon).
            </Text>

            <HStack
                mb="6"
            >
                <WVWTitle
                    content={t('caseOverview.attention.attentionRequired')}
                    level="1"
                    color="black"
                />

                <WvwIcon icon="warning" />
            </HStack>

            <VStack
                align="start"
                spacing="4"
            >
                <LabelAndData
                    label={t('caseOverview.attention.paymentDue')}
                    data={t('caseOverview.attention.attentionPlaceholder.one')}
                />

                <LabelAndData
                    label={t('caseOverview.attention.caseEscalation')}
                    data={t('caseOverview.attention.attentionPlaceholder.two')}
                />

                <LabelAndData
                    label={t('caseOverview.attention.actionRequired')}
                    data={t('caseOverview.attention.attentionPlaceholder.three')}
                />

                <LabelAndData
                    label={t('caseOverview.attention.updates')}
                    data={t('caseOverview.attention.attentionPlaceholder.four')}
                />
            </VStack>
        </Box>
    );
};

export default CaseAttentionInfo;
