import { HStack } from '@chakra-ui/react';
import { ReactNode } from 'react';

type PropTypes = {
    children: ReactNode | ReactNode[];
    w?: string;
};

const WvwHStack = (props: PropTypes) => {
    const { children, w } = props;

    return (
        <HStack w={w}>
            {children}
        </HStack>
    );
};

WvwHStack.defaultProps = {
    w: undefined,
};

export default WvwHStack;
