import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DocumentUploadWithNote from './common/DocumentUploadWithNote';
import ModalDynamic from '../../../../../../common/components/ModalDynamic';
import { useDocumentRequestGetDocument } from '../../../../../../utils/api/hooks';
import { WVWTitle, WvwText } from '../../../../../../common/components/typography';
import { ContentBox } from '../../../../../../common/components/display';
import WvwHStack from '../../../../../../common/components/display/WvwHStack';
import WvwVStack from '../../../../../../common/components/display/WvwVStack';
import WvwIcon from '../../../../../../common/components/display/WvwIcon';
import LoadingModal from '../../../../../../common/components/LoadingModal';

const ReplaceDocument = () => {
    const { t } = useTranslation('documentmanagement');
    const navigate = useNavigate();

    const { documentId, caseId } = useParams();

    const {
        data: replaceDocument,
        isLoading,
    } = useDocumentRequestGetDocument(Number(documentId));

    if (isLoading || !replaceDocument) return <LoadingModal />;

    return (
        <ModalDynamic
            variant="small"
            isOpen
            header={t('replaceDocument', { ns: 'documentmanagement' })}
            onClose={() => navigate('../', { replace: true })}
        >
            <WvwVStack>
                <ContentBox>
                    <WvwHStack>
                        <WvwIcon icon="document" />

                        <WVWTitle
                            content={replaceDocument.name}
                            level="3"
                            color="black"
                        />
                    </WvwHStack>
                </ContentBox>

                {replaceDocument.responseComment && (
                    <ContentBox color="red05">
                        <WVWTitle
                            content={t('rejectionNote')}
                            level="3"
                            color="red"
                        />

                        <WvwText>
                            {replaceDocument?.responseComment}
                        </WvwText>
                    </ContentBox>
                )}

                <WVWTitle
                    content={t('replaceWith', { ns: 'documentmanagement' })}
                    level="3"
                />

                <DocumentUploadWithNote
                    caseId={Number(caseId)}
                    replacedDocumentId={Number(documentId)}
                    onClose={() => navigate('../', { replace: true })}
                />
            </WvwVStack>
        </ModalDynamic>
    );
};

export default ReplaceDocument;
