import { useMutation } from 'react-query';
import useSmartTranslation from '../../../common/hooks/useSmartTranslation';
import useToast from '../../../common/hooks/useToast';

type ParamTypes<DtoType> = {
    refreshes: (() => void)[];
    mutationFunction: (dto: DtoType) => Promise<unknown>;
    successMessage?: string;
    onSuccess?: (response?: any) => void;
    onError?: (error: Error) => void;
};

/**
 * Creates a mutation hook that can be used to update data on the server.
 * @template DtoType - The type of the data to be updated.
 * @param params.mutationFunction - The endpoint function.
 * @param params.refreshes - An array of functions to refresh data after the mutation.
 * @param params.successMessage - A message to display on success.
 * @param params.onSuccess - A function to run on success.
 * @param params.onError - A function to run on error.
 * @returns return.update - A function to update the data.
 * @returns return.busy - A boolean indicating if the mutation is loading.
 */
function useMutationHook<DtoType>(params: ParamTypes<DtoType>) {
    const {
        refreshes,
        mutationFunction,
        successMessage,
        onSuccess,
        onError,
    } = params;

    const t = useSmartTranslation('errors');
    const { createErrorToast, createSuccessToast } = useToast();

    const { mutate: update, isLoading: busy } = useMutation(
        mutationFunction,
        {
            onSuccess: response => {
                refreshes.forEach(refresh => refresh());
                onSuccess?.(response);

                if (successMessage) {
                    createSuccessToast(successMessage);
                }
            },
            onError: (error: Error) => {
                createErrorToast(t(error.message, { defaultValue: error.message }));

                onError?.(error);
            },
        },
    );

    return {
        update,
        busy,
    };
}

export default useMutationHook;
