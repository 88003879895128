import {
    Heading,
    Button,
    Link,
    ListItem,
    OrderedList,
    UnorderedList,
    VStack,
} from '@chakra-ui/react';

type PropTypes = {
    onStageChange?: (newState: 'terms' | 'privacy' | undefined) => void;
};

const Terms = (props: PropTypes) => {
    const { onStageChange } = props;

    const updateStateToPrivacy = () => {
        if (onStageChange) {
            onStageChange('privacy');
        }
    };

    return (
        <VStack
            align="left"
            spacing="1rem"
        >
            <UnorderedList paddingLeft="4">
                <ListItem>
                    Stand: Mai 2024
                </ListItem>

                <ListItem>
                    Version: 1.0
                </ListItem>
            </UnorderedList>

            <Heading size="sm">
                §1 Allgemeines, Geltungsbereich, Änderungen
            </Heading>

            <OrderedList
                styleType="lower-alpha"
                paddingLeft="4"
            >
                <ListItem>
                    {/* eslint-disable-next-line max-len */}
                    Die Internetseiten wer-bewertet-was.de, who-values-what.com, who-values-what.de und wer-bewertet-was.com werden betrieben von der WVW Valuation as a Service GmbH, Hohe Bleichen 22 in 20345 Hamburg (nachfolgend „WVW“). Über diese Webseiten bietet die WVW ihren Kunden (nachfolgend “Nutzer”) eine Software-Plattform (nachfolgend “Plattform”) an. Die Nutzer - dies sind Wertgutachter, Intermediäre und deren Klienten - können mithilfe der genannten Plattform einen Immobilien-Bewertungsprozess effektiv und effizient umsetzen.
                </ListItem>

                <br />

                <ListItem>
                    {/* eslint-disable-next-line max-len */}
                    Die vorliegenden Allgemeinen Geschäftsbedingungen (nachfolgend „AGB“) gelten für die Nutzung der WVW-Webseiten und die Inanspruchnahme von WVW-Diensten.
                </ListItem>

                <br />

                <ListItem>
                    {/* eslint-disable-next-line max-len */}
                    Der Nutzer geht in Übereinstimmung mit den AGB eine vertragliche Beziehung bezüglich der Nutzung der Plattform mit WVW ein. Gegenstand der vertraglichen Beziehung können unter anderem dabei sein:
                    <OrderedList
                        styleType="lower-roman"
                        paddingLeft="4"
                    >
                        <ListItem>
                            {/* eslint-disable-next-line max-len */}
                            die Bereitstellung eines oder mehrerer digitaler Prozesse und Werkzeuge, um die Auftragsbearbeitung eines Wertgutachtens effizient zu bearbeiten,
                        </ListItem>

                        <br />

                        <ListItem>
                            {/* eslint-disable-next-line max-len */}
                            die Informationsweitergabe zwischen Wertgutachter, Intermediär und ihren Klienten,
                        </ListItem>

                        <br />

                        <ListItem>
                            die Vermittlung von Wertgutachtern, sowie
                        </ListItem>

                        <br />

                        <ListItem>
                            {/* eslint-disable-next-line max-len */}
                            weiteren Leistungen, welche den Bewertungsprozess effektiver, effizienter und kundenfreundlicher gestalten.
                        </ListItem>
                    </OrderedList>
                </ListItem>

                <br />

                <ListItem>
                    {/* eslint-disable-next-line max-len */}
                    Für die zugrundeliegende Vertragsbeziehung finden ausschließlich die vorliegenden AGB Anwendung. Entgegenstehende allgemeine Geschäftsbedingungen des Vertragspartners finden keine Berücksichtigung, es sei denn, WVW stimmt ihrer Geltung ausdrücklich schriftlich zu.
                </ListItem>

                <br />

                <ListItem>
                    {/* eslint-disable-next-line max-len */}
                    WVW ist berechtigt die AGB nach Vertragsschluss zu ändern, soweit hierdurch wesentliche Bestandteile des zugrundeliegenden Vertrags nicht berührt werden und dies zur Anpassung an Entwicklungen erforderlich ist, die bei Vertragsschluss nicht vorhersehbar waren und deren Vernachlässigung die Ausgewogenheit des Vertrags wesentlich beeinträchtigen würde.
                </ListItem>

                <br />

                <ListItem>
                    {/* eslint-disable-next-line max-len */}
                    Änderungen der AGB werden rechtzeitig vor dem geplanten Zeitpunkt ihres Wirksamwerdens in Schriftform mitgeteilt. Der Nutzer hat das Recht den mitgeteilten Änderungen zu widersprechen. Widerspricht der Auftraggeber den Änderungen nicht innerhalb von zwei Wochen nach Zugang der Änderungsmitteilung in Schriftform, werden die Änderungen zum geplanten Zeitpunkt wirksam und damit Vertragsbestandteil.
                </ListItem>

                <br />

                <ListItem>
                    {/* eslint-disable-next-line max-len */}
                    Widerspricht der Nutzer rechtzeitig, behalten die bisherigen allgemeinen Geschäftsbedingungen ihre Gültigkeit. In diesem Fall steht WVW ein Sonderkündigungsrecht zu.˝
                </ListItem>
            </OrderedList>

            <Heading size="sm">
                §2 Leistungen von WVW
            </Heading>

            <OrderedList
                styleType="lower-alpha"
                paddingLeft="4"
            >
                <ListItem>
                    {/* eslint-disable-next-line max-len */}
                    WVW bietet Wertgutachtern und ihren Klienten über die Plattform on-line verfügbare Werkzeuge zur Beschaffung von Wertgutachten und zur Begleitung des Gutachtenprozesses an.
                </ListItem>

                <br />

                <ListItem>
                    {/* eslint-disable-next-line max-len */}
                    Eine detaillierte Aufstellung der von WVW betriebenen Produkte kann der Nutzer auf der Webpräsenz der WVW oder auf Nachfrage bei WVW erhalten.
                </ListItem>

                <br />

                <ListItem>
                    {/* eslint-disable-next-line max-len */}
                    Die Darstellung und Funktionsumfang der bereitgestellten Dienste kann je nach Zugangsart - mobiles Endgerät, Desktop Computer - variieren.
                </ListItem>
            </OrderedList>

            <Heading size="sm">
                §3 Zulassung zur Plattform
            </Heading>

            <OrderedList
                styleType="lower-alpha"
                paddingLeft="4"
            >
                <ListItem>
                    Der Zugang zur Plattform der WVW erfolgt nach einer (einmaligen) Registrierung.
                </ListItem>

                <br />

                <ListItem>
                    {/* eslint-disable-next-line max-len */}
                    WVW behält sich vor, aus wichtigem Grund registrierte Nutzer von der Plattform auszuschließen oder die Registrierung von Nutzern abzulehnen. Der Vertrag endet dann automatisch mit Ablauf des Monats. Eine Rückerstattung bereits geleisteter Entgelte ist in solchen Fällen nicht vorgesehen und es obliegt WVW eine Rückerstattung auszuschließen.
                </ListItem>
            </OrderedList>

            <Heading size="sm">
                §4 Vergütung für die Nutzung der Plattform
            </Heading>

            <OrderedList
                styleType="lower-alpha"
                paddingLeft="4"
            >
                <ListItem>
                    {/* eslint-disable-next-line max-len */}
                    WVW unterscheidet die Nutzergruppe der Wertgutachter von der ihrer Klienten. Beiden bietet WVW ein gruppen-spezifisches abonnementbasiertes Nutzungsmodell an. Die Kosten eines Abonnements sind zum einen als pauschale Kosten monatlich zu entrichten, zum anderen haben Kunden ggf. erfolgsabhängige Gebühren zu entrichten.

                    <br />

                    <br />

                    {/* eslint-disable-next-line max-len */}
                    Eine aktuelle und detaillierte Preisliste wird den Kunden vor der Registrierung bereitgestellt.
                </ListItem>

                <br />

                <ListItem>
                    {/* eslint-disable-next-line max-len */}
                    Die Preise können von WVW jederzeit und nach rechtzeitiger Ankündigung geändert werden. Sollte WVW die Preise ändern, dann steht dem Nutzer zum Ablauf der Vertragsperiode ein Kündigungsrecht zu.
                </ListItem>

                <br />

                <ListItem>
                    Fällige aber nicht geleistete
                    Entgelte werden jährlich mit 4% über Sonia verzinst.
                </ListItem>
            </OrderedList>

            <Heading size="sm">
                §5 Abschluss von Verträgen auf der Plattform
            </Heading>

            <OrderedList
                styleType="lower-alpha"
                paddingLeft="4"
            >
                <ListItem>
                    Es obliegt dem Wertgutachter und seinem Klienten einen Vertrag zu schließen.
                </ListItem>

                <br />

                <ListItem>
                    {/* eslint-disable-next-line max-len */}
                    WVW wird kein Vertragspartner in diesem Vertragsverhältnis. Sollten die Parteien die Nutzung von WVW in diesem Vertrag die Nutzung von WVW vereinbaren, so haben die AGB-Vorrang.
                </ListItem>
            </OrderedList>

            <Heading size="sm">
                §6 Pflichten der Nutzer
            </Heading>

            <OrderedList
                styleType="lower-alpha"
                paddingLeft="4"
            >
                <ListItem>
                    {/* eslint-disable-next-line max-len */}
                    Nutzer müssen in ihren Profilen wahrheitsgemäße Angaben machen sowie darin hinterlegte Dokumente und Informationen aktuell halten.
                </ListItem>

                <br />

                <ListItem>
                    Nutzer müssen einen Ansprechpartner für WVW hinterlegen.
                </ListItem>

                <br />

                <ListItem>
                    {/* eslint-disable-next-line max-len */}
                    Nutzer dürfen ohne ausdrückliche schriftliche Zustimmung der WVW die Dienste der Plattform weder ganz noch teilweise systematisch extrahieren und/oder wiederverwenden. Die Anwendung von Data Mining Werkzeugen, Robots oder ähnlichen Datensammelprogrammen ist untersagt.
                </ListItem>

                <br />

                <ListItem>
                    {/* eslint-disable-next-line max-len */}
                    Die Verlinkung, Integration oder sonstige Verknüpfung der Dienste der WVW ohne ausdrückliche schriftliche Zustimmung ist untersagt. Ausgenommen sind hiervon ausdrücklich die über die Plattform angebotene direkte Verlinkung hin zu den Anfragemasken der Wertgutachter auf der Plattform.
                </ListItem>

                <br />

                <ListItem>
                    {/* eslint-disable-next-line max-len */}
                    Nutzer haben die Vertraulichkeit von Konto und Passwort sicher zu stellen, sowie den Zugang zu entsprechenden Endgeräten zu sichern.˝
                </ListItem>

                <br />

                <ListItem>
                    {/* eslint-disable-next-line max-len */}
                    Nutzer haben die Integrität von Endgeräten und ihrer Betriebssysteme zu sichern und diese angemessen gegen (Einschleusung von) Schadsoftware abzusichern, insbesondere gegen solche, die dazu geeignet ist Plattform oder den Zugang zu ihr zu beschädigen oder in sonstiger Art zu beeinträchtigen.
                </ListItem>
            </OrderedList>

            <Heading size="sm">
                §7 Zahlungsabwicklung für auf der Plattform geschlossene Verträge
            </Heading>

            <OrderedList
                styleType="lower-alpha"
                paddingLeft="4"
            >
                <ListItem>
                    Der für das Wertgutachten entrichtete Betrag steht dem Wertgutachter zu.
                </ListItem>
            </OrderedList>

            <Heading size="sm">
                §8 Haftung und Gewährleistung
            </Heading>

            <OrderedList
                styleType="lower-alpha"
                paddingLeft="4"
            >
                <ListItem>
                    {/* eslint-disable-next-line max-len */}
                    WVW übernimmt keine Gewährleistung und haftet nicht für technische Mängel, insbesondere für die ständige und ununterbrochene Verfügbarkeit der Website oder der von WVW genutzten Drittanbieter.
                </ListItem>

                <br />

                <ListItem>
                    {/* eslint-disable-next-line max-len */}
                    WVW haftet nicht für die fehlerfreie Wiedergabe der vom Nutzer eingegebenen oder hochgeladenen Inhalte.
                </ListItem>

                <br />

                <ListItem>
                    {/* eslint-disable-next-line max-len */}
                    Die Geschäftsanbahnung und der Vertragsabschluss obliegen den Parteien. WVW haftet nicht für Geschäfte, die nicht abgeschlossen wurden.
                </ListItem>

                <br />

                <ListItem>
                    {/* eslint-disable-next-line max-len */}
                    WVW haftet nicht für die fehlerfreie Ausführung der Aufträge, welche über die Plattform vermittelt werden.
                </ListItem>

                <br />

                <ListItem>
                    {/* eslint-disable-next-line max-len */}
                    Während der Vertragsanbahnung obliegt es den Parteien, selbständig eine Sorgfältigkeitsprüfung der Vertragspartei durchzuführen. Die Daten auf der Plattform werden von den Parteien zur Verfügung gestellt. WVW prüft diese Daten nicht und übernimmt keine Haftung bei Falschangaben. Dies trifft auch auf eventuelle Haftpflichtversicherungen zu, welche der Wertgutachter seinem Klienten zusichert.
                </ListItem>

                <br />

                <ListItem>
                    {/* eslint-disable-next-line max-len */}
                    WVW haftet nicht, wenn die Parteien Ihre gegenseitigen vertraglichen Pflichten nicht nachkommen, dies trifft auch zu, falls der Wertgutachter das Wertgutachten nicht oder nicht ordnungsgemäß liefert bzw. der Klient das vereinbarte Leistungshonorar ganz oder teilweise verweigert.
                </ListItem>

                <br />

                <ListItem>
                    {/* eslint-disable-next-line max-len */}
                    Für den Inhalt der hochgeladenen Informationen durch den Nutzer ist dieser selbst verantwortlich. WVW übernimmt keine Haftung für von Nutzern hochgeladene Dokumente oder das angefertigte Wertgutachten.
                </ListItem>

                <br />

                <ListItem>
                    {/* eslint-disable-next-line max-len */}
                    WVW haftet dem Nutzer, gleichgültig aus welchem Rechtsgrund, ausschließlich für die von WVW bzw. deren Mitarbeitern vorsätzlich oder grob fahrlässig verursachten Schäden. Eine Haftung für leichte Fahrlässigkeit besteht nicht.
                </ListItem>

                <br />

                <ListItem>
                    {/* eslint-disable-next-line max-len */}
                    WVW setzt angemessene technische und organisatorische Maßnahmen ein, um die Sicherheit und Vertraulichkeit der Daten der Nutzer zu gewährleisten. Dennoch kann keine absolute Sicherheit gewährleistet werden, und WVW haftet nicht für Datenverluste oder Verletzungen der Privatsphäre.
                </ListItem>
            </OrderedList>

            <Heading size="sm">
                §9 Schadloshaltung
            </Heading>

            <OrderedList
                styleType="lower-alpha"
                paddingLeft="4"
            >
                <ListItem>
                    {/* eslint-disable-next-line max-len */}
                    Der Nutzer verpflichtet sich, WVW und seine Tochtergesellschaften, leitenden Angestellten, Mitarbeiter, Vertreter und Partner von sämtlichen Ansprüchen, Verlusten, Schäden, Kosten und Haftungen freizustellen und schadlos zu halten, die sich aus der Nutzung der Plattform oder der Verletzung dieser AGB ergeben.
                </ListItem>

                <br />

                <ListItem>
                    {/* eslint-disable-next-line max-len */}
                    Diese Schadloshaltung erstreckt sich auf Ansprüche Dritter und umfasst rechtliche Kosten und Gebühren, die durch die Verletzung dieser AGB entstehen.
                </ListItem>

                <br />

                <ListItem>
                    {/* eslint-disable-next-line max-len */}
                    WVW behält sich das Recht vor, die Verteidigung in Angelegenheiten zu übernehmen, die die Schadloshaltung betreffen, und der Nutzer verpflichtet sich, mit WVW in angemessener Weise zusammenzuarbeiten.
                </ListItem>

                <br />

                <ListItem>
                    {/* eslint-disable-next-line max-len */}
                    Die Haftung für einen einzelnen Schadensfall ist auf den Wert des für die Leistungserbringung gezahlten Betrages begrenzt.
                </ListItem>
            </OrderedList>

            <Heading size="sm">
                §10 Geistiges Eigentum
            </Heading>

            <OrderedList
                styleType="lower-alpha"
                paddingLeft="4"
            >
                <ListItem>
                    {/* eslint-disable-next-line max-len */}
                    Das Wertgutachten und sonstige Dokumente bleiben Eigentum des Wertgutachters und seines Klienten.
                </ListItem>

                <br />

                <ListItem>
                    {/* eslint-disable-next-line max-len */}
                    Das Urheberrecht für veröffentlichte, oder von WVW selbst erstellte Objekte bleibt allein bei WVW. Eine Vervielfältigung oder Verwendung solcher Grafiken, Dokumente und Texte in anderen elektronischen oder gedruckten Publikationen ist ohne ausdrückliche schriftliche Zustimmung von WVW nicht gestattet.
                </ListItem>

                <br />

                <ListItem>
                    {/* eslint-disable-next-line max-len */}
                    Die von WVW zur Verfügung gestellte Plattform ist Eigentum von WVW. Das Kopieren von wesentlichen Inhalten, einzelnen Modulen oder Texten ist nicht gestatten.
                </ListItem>
            </OrderedList>

            <Heading size="sm">
                §11 Datenschutz und Datensicherheit
            </Heading>

            <OrderedList
                styleType="lower-alpha"
                paddingLeft="4"
            >
                <ListItem>
                    {'Die geltenden Datenschutzrichtlinien können online unter '}

                    <Button
                        color="wvwYellow"
                        variant="link"
                        onClick={() => {
                            updateStateToPrivacy();
                        }}
                    >
                        https://wer-bewertet-was.de/privacy
                    </Button>

                    {/* eslint-disable-next-line max-len */}
                    {' eingesehen werden und sind Bestandteil dieser AGB. Eine Kopie kann ebenfalls per E-Mail angefordert werden, und zwar unter der Adresse '}

                    <Link
                        color="wvwYellow"
                        href="mailto:platform@wer-bewertet-was.de"
                        isExternal
                    >
                        platform@wer-bewertet-was.de
                    </Link>

                    {'. '}
                </ListItem>

                <br />

                <ListItem>
                    {/* eslint-disable-next-line max-len */}
                    Beschwerden müssen zwingend schriftlich dokumentiert werden und sind an zu richten. WVW wird sich innerhalb von fünf Werktagen der Angelegenheit annehmen.
                </ListItem>

                <br />

                <ListItem>
                    {/* eslint-disable-next-line max-len */}
                    WVW hat über die gesamte Laufzeit dieser Vereinbarung hinweg ein betriebliches Kontinuitätsmanagement implementiert. Dieses steht dem Kunden auf Anfrage (in Teilen) zur Verfügung.
                </ListItem>
            </OrderedList>

            <Heading size="sm">
                §12 Kündigung
            </Heading>

            <OrderedList
                styleType="lower-alpha"
                paddingLeft="4"
            >
                <ListItem>
                    {/* eslint-disable-next-line max-len */}
                    Der zugrundeliegende Vertrag wird mit unbestimmter Laufzeit geschlossen und ist beidseitig jeweils zum Ende eines jeden Kalendermonats ordentlich kündbar.
                </ListItem>

                <br />

                <ListItem>
                    Das Recht zur außerordentlichen
                    Kündigung aus wichtigem Grund bleibt vorbehalten.
                </ListItem>

                <br />

                <ListItem>
                    {/* eslint-disable-next-line max-len */}
                    {'Das Kündigungsrecht kann per E-Mail an '}
                    <Link
                        color="wvwYellow"
                        href="mailto:platform@wer-bewertet-was.de"
                        isExternal
                    >
                        platform@wer-bewertet-was.de
                    </Link>

                    {' vollzogen werden.'}
                </ListItem>

                <br />

                <ListItem>
                    Nach einer Kündigung werden die Daten automatisch nach vier Wochen gelöscht.
                </ListItem>
            </OrderedList>

            <Heading size="sm">
                §13 Nutzungsbedingungen für Nutzerinhalte
            </Heading>

            <OrderedList
                styleType="lower-alpha"
                paddingLeft="4"
            >
                <ListItem>
                    {/* eslint-disable-next-line max-len */}
                    Nutzer dürfen keine Inhalte auf der Plattform veröffentlichen, die beleidigend, diffamierend, obszön, rassistisch, rechtswidrig oder anderweitig gegen die guten Sitten verstoßen.
                </ListItem>

                <br />

                <ListItem>
                    {/* eslint-disable-next-line max-len */}
                    Nutzer sind für die von ihnen veröffentlichten Inhalte selbst verantwortlich und dürfen keine Inhalte Dritter ohne die erforderlichen Rechte und Zustimmungen verwenden.

                    <br />

                    <br />

                    {/* eslint-disable-next-line max-len */}
                    WVW behält sich das Recht vor, Inhalte zu überprüfen und bei Verstoß gegen diese Nutzungsbedingungen zu entfernen.
                </ListItem>

                <br />

                <ListItem>
                    {/* eslint-disable-next-line max-len */}
                    Nutzer stimmen zu, WVW und andere Nutzer von jeglichen Ansprüchen freizustellen, die aus der Veröffentlichung ihrer Inhalte resultieren.
                </ListItem>
            </OrderedList>

            <Heading size="sm">
                §14 Kundendienst & Support
            </Heading>

            <OrderedList
                styleType="lower-alpha"
                paddingLeft="4"
            >
                <ListItem>
                    {/* eslint-disable-next-line max-len */}
                    WVW garantiert nicht die ständige Verfügbarkeit der Plattform, ist aber bemüht, Probleme innerhalb von 24 Stunden zu beheben.
                </ListItem>

                <br />

                <ListItem>
                    {/* eslint-disable-next-line max-len */}
                    {'Supportanfragen können über die WVW-Webseite, die Schaltfläche “Ein Problem melden” auf der Plattform oder per E-Mail an '}

                    <Link
                        color="wvwYellow"
                        href="mailto:platform@wer-bewertet-was.de"
                        isExternal
                    >
                        platform@wer-bewertet-was.de
                    </Link>

                    {' gestellt werden.'}
                </ListItem>
            </OrderedList>

            <Heading size="sm">
                §15 Schlussbestimmungen
            </Heading>

            <OrderedList
                styleType="lower-alpha"
                paddingLeft="4"
            >
                <ListItem>
                    {/* eslint-disable-next-line max-len */}
                    Keine der Bestimmungen dieser Vereinbarung zielt darauf ab, eine Partnerschaft oder ein Joint Venture zwischen den Parteien zu begründen, eine Partei zum Vertreter einer anderen Partei zu machen oder eine Partei zu ermächtigen, Verpflichtungen für oder im Namen einer anderen Partei einzugehen oder einzugehen. Die Parteien handeln in eigener Verantwortung.
                </ListItem>

                <br />

                <ListItem>
                    {/* eslint-disable-next-line max-len */}
                    Sollten einzelne Bestimmungen dieser AGB ganz oder teilweise nicht rechtswirksam sein oder ihre Rechtswirksamkeit später verlieren, so wird hierdurch die Gültigkeit der AGB ansonsten nicht berührt. Sollten Teile dieser AGB unwirksam sein oder eine Lücke haben, dann treten an ihrer Stelle die entsprechenden gesetzlichen Bestimmungen in Kraft.
                </ListItem>

                <br />

                <ListItem>
                    {/* eslint-disable-next-line max-len */}
                    Diese AGB sowie sämtliche Rechtsgeschäfte oder andere rechtliche Beziehungen mit WVW unterliegen dem Recht der Bundesrepublik Deutschland. Das UN-Kaufrecht (CSIG) sowie etwaige sonstige zwischenstaatliche Übereinkommen finden keine Anwendung.
                </ListItem>

                <br />

                <ListItem>
                    {/* eslint-disable-next-line max-len */}
                    Diese AGB begründen die einzige Vertragsbasis zwischen WVW und den anderen Vertragsparteien. Andere Verträge und Nebenabreden zwischen WVW und den Parteien bestehen nicht.
                </ListItem>

                <br />

                <ListItem>
                    {/* eslint-disable-next-line max-len */}
                    Der Gerichtsstand für alle Rechtsstreitigkeiten betreffend diese AGB sowie etwaige Einzelverträge ist Geschäftssitz der WVW.
                </ListItem>
            </OrderedList>
        </VStack>
    );
};

Terms.defaultProps = {
    onStageChange: undefined,
};

export default Terms;
