import {
    Heading,
    Link,
    Text,
    VStack,
} from '@chakra-ui/react';
import { Link as ReactLink } from 'react-router-dom';

const Imprint = () => (
    <VStack
        align="left"
        spacing="1rem"
    >
        <Text>
            TL Investment & Service GmbH
            <br />
            Franziskaner Straße 2a
            <br />
            81669 München
            <br />
            Deutschland
            <br />
            Tel.: +49 89 5147 0703
            <br />

            {'E-Mail: '}

            <Link
                color="wvwYellow"
                href="mailto:platform@wer-bewertet-was.de"
                isExternal
            >
                platform@wer-bewertet-was.de
            </Link>

            {' | Web: '}

            <Link
                as={ReactLink}
                color="wvwYellow"
                to="/"
            >
                wer-bewertet-was.de
            </Link>
        </Text>

        <Heading size="sm">
            Rechtsform:
        </Heading>

        <Text>
            Gesellschaft mit beschränkter Haftung (GmbH) mit Sitz in München
        </Text>

        <Heading size="sm">
            Vertreten durch:
        </Heading>

        <Text>
            Thorsten Lamberty (Geschäftsführer)
        </Text>

        <Heading size="sm">
            Eintragung im Handelsregister:
        </Heading>

        <Text>
            Registergericht: Amtsgericht München
            <br />
            Register-Nr. HRB 288190
        </Text>

        <Heading size="sm">
            UstID:
        </Heading>
        <Text>
            DE365534616
        </Text>

        <Heading size="sm">
            Hinweis zu Links:
        </Heading>

        <Text>
            Soweit auf andere Internet-Seiten verwiesen wird (Hyperlinks), stellen diese keine Meinung oder Inhalte der TL Investment & Service GmbH dar.
        </Text>
    </VStack>
);

export default Imprint;
