/* eslint-disable @typescript-eslint/comma-dangle */
import {
    IconButton,
    TableContainer,
    Table,
    Tbody,
    Td,
    Text,
    Thead,
    Th,
    Tr,
    Box,
} from '@chakra-ui/react';
import { ArrangeVertical, ArrowDown2, ArrowUp2 } from 'iconsax-react';
import {
    ReactNode,
    useEffect,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import SelectArray, { OptionType } from '../SelectArray';

export type DataTableColumnType<T> = {
    alignment?: string;
    title?: string;
    dataIndex?: string;
    maxWidth?: string;
    sorter?: (a: T, b: T) => number;
    render?: (data: T) => ReactNode;
};

export type DataTableFilterType<T> = {
    placeholder: string,
    options: OptionType[];
    filterName: string;
    filterFunction: (i: T, v: string | undefined) => boolean;
};

// FIXME how to use sorting from props in state
// eslint-disable-next-line max-len
// FIXME we could probably make a type from string-identifiers on columns and use that in the SortOrder
type SortOrder = {
    column: number | undefined;
    direction?: 'default' | 'asc' | 'desc';
};

type PropTypes<T> = {
    columnColor?: string;
    columns: DataTableColumnType<T>[];
    data: T[];
    expandedContent?: (rowData: T) => ReactNode;
    noDataText?: string;
    tableWidth?: string;
    titleColor?: string;
    initialSorting?: SortOrder;
    // eslint-disable-next-line max-len
    filters?: DataTableFilterType<T>[];
};

type TableRowProps<T> = {
    columns: DataTableColumnType<T>[];
    expandedContent?: (rowData: T) => ReactNode;
    item: T;
};

const cornerRadius = (index: number, length: number, expandedContent: boolean, leftOrRight: 'l' | 'r') => {
    if (index === length - 1 && leftOrRight === 'r') {
        return 10;
    }

    if (expandedContent) {
        return 0;
    }

    if (index !== 0) {
        return 0;
    }

    if (index === 0 && leftOrRight === 'r') {
        return 0;
    }

    return 10;
};

// eslint-disable-next-line max-len
const TableRow = <T extends Record<string, any>,>(props: TableRowProps<T>) => {
    const { columns, expandedContent, item } = props;

    const [expanded, setExpanded] = useState<boolean>(false);

    return (
        <>
            <Tr bgColor="white">
                {expandedContent && (
                    <Td
                        borderBottomLeftRadius="10"
                        borderTopLeftRadius="10"
                    >
                        <IconButton
                            aria-label="Expand"
                            icon={expanded ? <ArrowUp2 size="18" /> : <ArrowDown2 size="18" />}
                            size="xs"
                            onClick={() => setExpanded(!expanded)}
                        />
                    </Td>
                )}

                {columns
                    .map((col, index) => (
                        <Td
                            borderBottomLeftRadius={
                                cornerRadius(index, columns.length, !!expandedContent, 'l')
                            }
                            borderBottomRightRadius={
                                cornerRadius(index, columns.length, !!expandedContent, 'r')
                            }
                            borderTopLeftRadius={
                                cornerRadius(index, columns.length, !!expandedContent, 'l')
                            }
                            borderTopRightRadius={
                                cornerRadius(index, columns.length, !!expandedContent, 'r')
                            }
                            // eslint-disable-next-line react/no-array-index-key
                            key={index}
                            maxWidth={col.maxWidth}
                            overflow="hidden"
                        >
                            <Box
                                justifyContent={col.alignment}
                                display="flex"
                            >
                                {col.render && col.render(item)}

                                {!col.render && (item?.[col.dataIndex || ''])}
                            </Box>
                        </Td>
                    ))}
            </Tr>

            {expanded && expandedContent && (
                <Tr>
                    <Td colSpan={columns.length + 1}>
                        {expandedContent(item)}
                    </Td>
                </Tr>
            )}
        </>
    );
};

// eslint-disable-next-line @typescript-eslint/comma-dangle
const DataTable = <T extends Record<string, any>,>(props: PropTypes<T>) => {
    const {
        columnColor,
        columns,
        data,
        expandedContent,
        noDataText,
        tableWidth,
        titleColor,
        initialSorting = { // FIXME why do I have to put defaults when I have defaultProps
            column: undefined,
            direction: 'default',
        },
        filters,
    } = props;

    const { t } = useTranslation(['common']);

    const [sortedData, setSortedData] = useState<T[]>(data);

    const [sort, setSort] = useState<SortOrder>(initialSorting);

    const [filterState, setFilterState] = useState<{ [index: string]: string | undefined }>(
        filters?.reduce(
            (res, i) => ({ ...res, [i.filterName]: undefined }),
            {},
        ) || {},
    );

    useEffect(() => {
        const { column, direction } = sort;

        let newData = [...data];

        if (column && columns[column] && columns[column].sorter) {
            const { sorter } = columns[column];
            newData = [...data].sort(sorter);

            if (direction === 'desc') {
                newData = newData.reverse();
            }
        }

        filters?.forEach(i => {
            const filterValue = filterState?.[i.filterName];
            if (filterValue) {
                newData = newData
                    .filter(row => i.filterFunction(row, filterValue));
            }
        });

        setSortedData([...newData]);
    }, [data, sort, filterState]);

    const sortColumn = (index: number) => {
        let sortDirection = sort.direction;

        if (sort.column !== index) {
            setSort({
                column: index,
                direction: 'asc',
            });

            return;
        }

        switch (sortDirection) {
            case 'asc':
                sortDirection = 'desc';
                break;
            case 'desc':
                sortDirection = 'default';
                break;
            default:
                sortDirection = 'asc';
                break;
        }

        setSort({
            column: index,
            direction: sortDirection,
        });
    };

    return (
        <>
            {filters && (
                <SelectArray
                    selectList={filters.map(i => ({
                        placeholder: i.placeholder,
                        options: i.options,
                        value: filterState[i.filterName],
                        onChange: selectedValue => setFilterState({
                            ...filterState,
                            [i.filterName]: selectedValue,
                        })
                    }))}
                />
            )}

            <TableContainer
                maxWidth={tableWidth}
                w="100%"
                className="hide-scrollbar hide-scrollbar::-webkit-scrollbar"
            >
                <Table
                    maxWidth="100%"
                    size="sm"
                >
                    <Thead
                        bgColor={columnColor}
                        h="4rem"
                    >
                        <Tr>
                            {expandedContent && (
                                <Th
                                    borderBottomLeftRadius="10"
                                    borderTopLeftRadius="10"
                                    w="2rem"
                                />
                            )}

                            {columns.map((col, index) => (
                                <Th
                                    borderBottomLeftRadius={
                                        cornerRadius(index, columns.length, !!expandedContent, 'l')
                                    }
                                    borderBottomRightRadius={
                                        cornerRadius(index, columns.length, !!expandedContent, 'r')
                                    }
                                    borderTopLeftRadius={
                                        cornerRadius(index, columns.length, !!expandedContent, 'l')
                                    }
                                    borderTopRightRadius={
                                        cornerRadius(index, columns.length, !!expandedContent, 'r')
                                    }
                                    // eslint-disable-next-line react/no-array-index-key
                                    key={index}
                                    maxW={col.maxWidth}
                                    textTransform="none"
                                >
                                    <Box
                                        alignItems="center"
                                        justifyContent={col.alignment}
                                        display="flex"
                                    >
                                        <Text
                                            _hover={{ cursor: 'pointer' }}
                                            as="b"
                                            color={titleColor}
                                            fontSize=".8rem"
                                            onClick={() => sortColumn(index)}
                                        >
                                            {col.title || ''}
                                        </Text>

                                        {col.sorter && (
                                            <IconButton
                                                aria-label="Sort"
                                                icon={<ArrangeVertical size="18" />}
                                                color="white"
                                                size="xs"
                                                variant="ghost"
                                                _hover={{ color: 'wvwGrey80' }}
                                                onClick={() => sortColumn(index)}
                                            />
                                        )}
                                    </Box>
                                </Th>
                            ))}
                        </Tr>
                    </Thead>

                    <Tbody>
                        {sortedData?.map((i, index) => (
                            <TableRow
                                columns={columns}
                                expandedContent={expandedContent}
                                item={i}
                                // eslint-disable-next-line react/no-array-index-key
                                key={index}
                            />
                        ))}
                    </Tbody>
                </Table>

                {
                    !sortedData.length && (
                        <Text
                            color="wvwGrey60"
                            paddingBlock=".6rem"
                            textAlign="center"
                            w="100%"
                        >
                            {noDataText || t('noData')}
                        </Text>
                    )
                }
            </TableContainer>
        </>
    );
};

DataTable.defaultProps = {
    columnColor: 'wvwGreen',
    expandedContent: undefined,
    noDataText: undefined,
    tableWidth: '100%',
    titleColor: 'white',
    initialSorting: {
        column: undefined,
        direction: 'default',
    },
    filters: undefined,
};

TableRow.defaultProps = {
    expandedContent: undefined,
};

export default DataTable;
