import { ChevronDownIcon } from '@chakra-ui/icons';
import {
    Button,
    Link as ChakraLink,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Text,
    createStandaloneToast,
} from '@chakra-ui/react';
import { Clipboard, Link1 } from 'iconsax-react';
import { useTranslation } from 'react-i18next';
import { Link as ReactLink, useNavigate } from 'react-router-dom';
import DataTable from '../../../common/components/datatable/DataTable';
import LoadingSpinner from '../../../common/components/LoadingSpinner';
import { useRole } from '../../../common/hooks';
import {
    useEnquiryCancel,
    useEnquiryGetAll,
    useEnquiryReactivate,
    useEnquirySetDormant,
    useMyEntity,
    useOfferGetAllMyOffers,
} from '../../../utils/api/hooks';
import PageWithTitleLayout from '../../../common/components/display/PageWithTitleLayout';
import useEnquiryColumns from '../../../common/hooks/useEnquiryColumns';

const Enquiries = () => {
    const navigate = useNavigate();

    const { t } = useTranslation(['dashboard', 'common', 'offer']);

    const { toast } = createStandaloneToast();

    const {
        userIsClient,
        userIsValuer,
        roleLoading,
    } = useRole();

    const {
        data: valuer,
        isLoading: valuerLoading,
    } = useMyEntity();

    const {
        data: enquiryList = [],
        isLoading: enquiryLoading,
        refresh,
    } = useEnquiryGetAll();

    const { update: reactivate } = useEnquiryReactivate({
        onSuccess: data => {
            navigate(`/create-client-enquiry/enquiry-valuation-info/${data.data.id}`);
        },
    });

    const {
        data: offerList = [],
        isLoading: offerLoading,
    } = useOfferGetAllMyOffers();

    const { update: setDormant } = useEnquirySetDormant({
        onSuccess: () => {
            refresh();
        },
        onError: error => {
            toast({
                title: t('error.error', { ns: 'common' }),
                description: error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        },
    });

    const { cancelEnquiry, modal: cancelEnquiryModal } = useEnquiryCancel({
        onSuccess: () => refresh(),
        onError: error => {
            toast({
                title: t('error.error', { ns: 'common' }),
                description: error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        },
    });

    const {
        columnProperty,
        columnEstValue,
        columnCurrentPrice,
        columnEnquiryStatus,
        columnDueDate,
        columnSubmittedDate,
        columnActions,
    } = useEnquiryColumns(offerList, { cancelEnquiry, reactivate, setDormant });

    if (roleLoading || enquiryLoading || offerLoading || valuerLoading) return <LoadingSpinner />;

    const valuerColumns = [
        columnProperty,
        columnEstValue,
        columnCurrentPrice,
        columnEnquiryStatus,
        columnDueDate,
        columnSubmittedDate,
        columnActions,
    ];

    const clientColumns = [
        columnProperty,
        columnEstValue,
        columnCurrentPrice,
        columnEnquiryStatus,
        columnDueDate,
        columnSubmittedDate,
        columnActions,
    ];

    const columns = userIsValuer ? valuerColumns : clientColumns;

    return (
        <>
            <PageWithTitleLayout
                title={t('section.enquiries')}
                extra={(
                    <>
                        {userIsClient && (
                            <ChakraLink
                                as={ReactLink}
                                to="/create-client-enquiry"
                            >
                                <Button
                                    variant="outline1"
                                    paddingBlock="1.2rem"
                                    borderRadius="10"
                                    width="12.8rem"
                                >
                                    {t('enquiriesTable.button.createNewEnquiry')}
                                </Button>
                            </ChakraLink>
                        )}

                        {userIsValuer && (
                            <Menu>
                                <MenuButton
                                    as={Button}
                                    variant="outline1"
                                    paddingBlock="1.2rem"
                                    borderRadius="10"
                                    width="12.8rem"
                                    rightIcon={<ChevronDownIcon />}
                                >
                                    {t('enquiriesTable.button.createNewEnquiry')}
                                </MenuButton>

                                <MenuList>
                                    <MenuItem
                                        paddingBlock=".8rem"
                                        onClick={() => navigate(`/direct-enquiry-form/individual/${valuer.identityToken}`)}
                                    >
                                        <Clipboard size="1.1rem" />
                                        <Text paddingInline=".5rem">{t('enquiriesTable.button.privateEndCustomer')}</Text>
                                    </MenuItem>

                                    <MenuItem
                                        paddingBlock=".8rem"
                                        onClick={() => navigate(`/direct-enquiry-form/company/${valuer.identityToken}`)}
                                    >
                                        <Clipboard size="1.1rem" />
                                        <Text paddingInline=".5rem">{t('enquiriesTable.button.institutionalClient')}</Text>
                                    </MenuItem>

                                    <MenuItem
                                        paddingBlock=".8rem"
                                        onClick={() => navigate('/dashboard/account/integration')}
                                    >
                                        <Link1 size="1.1rem" />
                                        <Text paddingInline=".5rem">{t('enquiriesTable.button.sendLink')}</Text>
                                    </MenuItem>
                                </MenuList>
                            </Menu>
                        )}
                    </>
                )}
            >
                <DataTable
                    columns={columns}
                    data={enquiryList}
                    noDataText={t('noEnquiries', { ns: 'enquiries' })}
                />
            </PageWithTitleLayout>

            {cancelEnquiryModal}
        </>
    );
};

export default Enquiries;
