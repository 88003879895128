import { useEffect } from 'react';
import { PageContainerBasicWithUser } from '../../../common/components/display';
import { useApplyActionCode } from '../../../utils/api/hooks/firebase';
import LoadingSpinner from '../../../common/components/LoadingSpinner';
import { WVWTitle } from '../../../common/components/typography';
import { useSmartTranslation } from '../../../common/hooks';

type PropsType = {
    oobCode: string;
    redirectUrl: string;
};

const ProcessEmailVerification = (props: PropsType) => {
    const { oobCode, redirectUrl } = props;

    const t = useSmartTranslation('common');

    const { update: applyActionCode } = useApplyActionCode({
        onSuccess: () => window.location.replace(redirectUrl),
    });

    useEffect(() => applyActionCode(oobCode), []);

    return (
        <PageContainerBasicWithUser>
            <WVWTitle
                level="1"
                content={t('verifyingEmail')}
            />

            <LoadingSpinner />
        </PageContainerBasicWithUser>
    );
};

export default ProcessEmailVerification;
