/* eslint-disable max-len */
import {
    VStack,
    Grid,
    GridItem,
    Box,
} from '@chakra-ui/react';
import {
    FormikCheckbox,
    FormikInput,
    FormikCountrySelect,
    FormikTextarea,
} from '../../../../common/forms';
import { useRole, useSmartTranslation } from '../../../../common/hooks';
import { UserType } from '../../../../types';
import { WvwText } from '../../../../common/components/typography';
import LoadingSpinner from '../../../../common/components/LoadingSpinner';

type PropTypes = {
    inspection?: boolean;
    client: UserType;
};

const AccessDetails = (props: PropTypes) => {
    const t = useSmartTranslation('inspection');

    const { userIsValuer, roleLoading } = useRole();

    const { client, inspection } = props;

    if (roleLoading) return <LoadingSpinner />;

    let accessData = {
        checkboxTxt: t('modal.accessDetails.form.myDetails'),
        fName: 'accessFName',
        lName: 'accessLName',
        email: 'accessEmail',
        dialCode: 'accessMobileDialCode',
        mobileNumber: 'accessMobile',
        note: 'accessNote',
    };

    if (inspection) {
        accessData = {
            checkboxTxt: userIsValuer ? t('modal.accessDetails.form.clientDetails') : t('modal.accessDetails.form.myDetails'),
            fName: 'firstName',
            lName: 'lastName',
            email: 'emailAddress',
            dialCode: 'phoneDialcode',
            mobileNumber: 'phoneNumber',
            note: 'note',
        };
    }

    return (
        <VStack
            w="100%"
            align="left"
        >
            <Box
                justifyContent="flex-end"
                display="grid"
            >
                <FormikCheckbox
                    name="useMyDetails"
                    label={accessData.checkboxTxt}
                    onChange={(event, field) => {
                        const newValue = !field?.value;

                        if (newValue) {
                            field?.onChange({ target: { name: accessData.fName, value: client.firstName } });
                            field?.onChange({ target: { name: accessData.lName, value: client.lastName } });
                            field?.onChange({ target: { name: accessData.email, value: client.email } });
                            field?.onChange({ target: { name: accessData.dialCode, value: client.mobileDialCode } });
                            field?.onChange({ target: { name: accessData.mobileNumber, value: client.mobile } });
                        }

                        if (!newValue) {
                            field?.onChange({ target: { name: accessData.fName, value: '' } });
                            field?.onChange({ target: { name: accessData.lName, value: '' } });
                            field?.onChange({ target: { name: accessData.email, value: '' } });
                            field?.onChange({ target: { name: accessData.dialCode, value: '' } });
                            field?.onChange({ target: { name: accessData.mobileNumber, value: '' } });
                        }
                    }}
                />
            </Box>

            <Grid
                gap="3"
                templateColumns="repeat(6, 1fr)"
            >
                <GridItem colSpan={3}>
                    <FormikInput
                        name={accessData.fName}
                        placeholder={t('fieldPlaceholder.firstName', { ns: 'common' })}
                        type="text"
                    />
                </GridItem>

                <GridItem colSpan={3}>
                    <FormikInput
                        name={accessData.lName}
                        placeholder={t('fieldPlaceholder.lastName', { ns: 'common' })}
                        type="text"
                    />
                </GridItem>

                <GridItem colSpan={6}>
                    <FormikInput
                        name={accessData.email}
                        placeholder={t('fieldPlaceholder.email', { ns: 'common' })}
                        type="email"
                    />
                </GridItem>

                <GridItem colSpan={3}>
                    <FormikCountrySelect
                        name={accessData.dialCode}
                        selectDialCode
                        w="100%"
                    />
                </GridItem>

                <GridItem colSpan={3}>
                    <FormikInput
                        name={accessData.mobileNumber}
                        placeholder={t('fieldPlaceholder.phoneNumber', { ns: 'common' })}
                    />
                </GridItem>

                <GridItem colSpan={6}>
                    <VStack align="left">
                        <WvwText>
                            {t('modal.accessDetails.form.accessInstructions')}
                        </WvwText>

                        <FormikTextarea
                            name={accessData.note}
                            placeholder={t('fieldPlaceholder.accessInstructions', { ns: 'common' })}
                        />
                    </VStack>
                </GridItem>
            </Grid>
        </VStack>
    );
};

AccessDetails.defaultProps = {
    inspection: false,
};

export default AccessDetails;
