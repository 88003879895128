import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { createStandaloneToast } from '@chakra-ui/react';
import EnquiryFormBox from '../common/EnquiryFormBox';
import Properties from './property-forms/Properties';
import { FormikForm } from '../../../common/forms';
import { useEnquiry } from '../../../utils/api/hooks';
import useEnquirySteps from '../common/hooks/useEnquirySteps';

const validationSchema = Yup.object({
    properties: Yup.array().min(1, 'errors.required'),
});

const EnquiryPropertyInfo = () => {
    const { t } = useTranslation(['enquiryform', 'common', 'enquiries', 'formik']);
    const { toast } = createStandaloneToast();

    const { onContinue, onBack } = useEnquirySteps();

    const { enquiryId = '' } = useParams();

    const {
        enquiry,
        update: updateEnquiry,
    } = useEnquiry({
        enquiryId,
        onUpdateSuccess: () => {
            onContinue();
        },
        onUpdateError: error => toast({
            title: t('error.error', { ns: 'common' }),
            description: error.message,
            status: 'error',
            duration: 5000,
            isClosable: true,
        }),
    });

    return (
        <FormikForm
            validationSchema={validationSchema}
            initialValues={{
                properties: enquiry?.properties || [],
            }}
            onSubmit={values => {
                const data = {
                    properties: values.properties,
                    id: Number(enquiryId),
                };

                updateEnquiry(data);
            }}
        >
            <EnquiryFormBox
                onBack={onBack}
                content={[
                    {
                        key: 'propertyDetails',
                        header: t('newEnquiry.propertyDetailsSection.title', { ns: 'enquiryform' }),
                        subHeader: t('newEnquiry.propertyDetailsSection.subTitle', { ns: 'enquiryform' }),
                        element: <Properties />,
                    },
                ]}
            />
        </FormikForm>
    );
};

export default EnquiryPropertyInfo;
