import { useParams } from 'react-router-dom';
import CaseModuleContainer from '../common/CaseModuleContainer';
import { WvwText, WVWTitle } from '../../../../common/components/typography';
import WvwVStack from '../../../../common/components/display/WvwVStack';
import { useRole, useSmartTranslation } from '../../../../common/hooks';
import { useCaseGetCaseReports } from '../../../../utils/api/hooks';
import LoadingSpinner from '../../../../common/components/LoadingSpinner';
import ReportList from './common/ReportList';
import AddReportBanner from './common/AddReportBanner';
import CaseReportDetailsModal from './view-report/CaseReportDetailsModal';
import { ContentBox } from '../../../../common/components/display';

const CaseReportDelivery = () => {
    const { caseId: caseIdString, reportId: reportIdString } = useParams();

    const caseId = Number(caseIdString);
    const reportId = Number(reportIdString);

    const t = useSmartTranslation('casereport');
    const { userIsClient, userIsValuer } = useRole();

    const {
        data: reportList = [],
        isLoading: reportsLoading,
    } = useCaseGetCaseReports(caseId);

    if (reportsLoading) return <LoadingSpinner />;

    const renderAddReport = () => {
        if (userIsClient) return null;

        const submittedReports = reportList.filter(report => report.status === 'SUBMITTED');

        if (submittedReports.length > 0) return null;

        return <AddReportBanner caseId={caseId} />;
    };

    const renderNoReports = () => {
        if (userIsValuer) return null;
        if (reportList.length > 0) return null;

        return (
            <ContentBox color="wvwYellow10">
                {t('details.noReports')}
            </ContentBox>
        );
    };

    const openReport = reportList.find(report => report.id === reportId);

    return (
        <>
            <CaseModuleContainer>
                <WVWTitle
                    content={t('title.valuationReport')}
                    level="2"
                />

                <WvwVStack>
                    <WvwText>
                        {t('details.description')}
                    </WvwText>

                    {renderAddReport()}

                    {renderNoReports()}

                    <ReportList reportList={reportList} />
                </WvwVStack>
            </CaseModuleContainer>

            <CaseReportDetailsModal
                caseId={caseId}
                report={openReport}
            />
        </>
    );
};

export default CaseReportDelivery;
