import {
    VStack, HStack, Box,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { WvwIcon } from '../../../../common/components/display';
import { WvwText, WVWTitle } from '../../../../common/components/typography';

const CaseProgressInfo = () => {
    const { t } = useTranslation('activecases');

    const progressRow = (icon: 'success' | 'pending', text: string) => (
        <HStack>
            <WvwIcon icon={icon} />
            <WvwText>{text}</WvwText>
        </HStack>
    );

    const progressRows = [
        progressRow('success', t('caseOverview.progress.offerSent')),
        progressRow('success', t('caseOverview.progress.offerAccepted')),
        progressRow('success', t('caseOverview.progress.contractSigned')),
        progressRow('pending', t('caseOverview.progress.paymentProcessing')),
        progressRow('pending', t('caseOverview.progress.valuationReportPending')),
    ];

    return (
        <VStack
            align="start"
            spacing="1rem"
            bgColor="white"
            p="1rem"
            borderRadius="lg"
            w="100%"
        >
            <WVWTitle
                level="2"
                content={t('caseOverview.progress.caseProgress')}
                color="black"
            />

            {progressRows.map(row => (
                <Box key={row.props.children[1].props.children}>
                    {row}
                </Box>
            ))}

        </VStack>
    );
};

export default CaseProgressInfo;
