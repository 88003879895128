import { WarningTwoIcon } from '@chakra-ui/icons';
import { Icon } from '@chakra-ui/react';
import {
    BackSquare,
    CloseCircle,
    TickCircle,
    DocumentCloud,
    DocumentDownload,
    DirectInbox,
    DocumentText,
    Clock,
    Edit,
    Trash,
    HambergerMenu,
} from 'iconsax-react';
import { HiPlus } from 'react-icons/hi';

const icons = {
    add: HiPlus,
    back: BackSquare,
    cancel: CloseCircle,
    pending: Clock,
    delete: Trash,
    document: DocumentText,
    documentRequest: DirectInbox,
    documentToUpload: DocumentCloud,
    documentToDownload: DocumentDownload,
    edit: Edit,
    success: TickCircle,
    warning: WarningTwoIcon,
    burgerMenu: HambergerMenu,
};

export type WvwIconKeys = keyof typeof icons;

type PropTypes = {
    icon: WvwIconKeys;
    color?: 'wvwGreen' | 'wvwGrey60' | 'black' | 'white';
};

const WvwIcon = (props: PropTypes) => {
    const { icon, color } = props;

    return (
        <Icon
            as={icons[icon]}
            color={color}
            boxSize="6"
        />
    );
};

WvwIcon.defaultProps = {
    color: 'wvwGreen',
};

export default WvwIcon;
