import {
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    ButtonGroup,
    HStack,
    IconButton,
    Spacer,
    Text,
    VStack,
} from '@chakra-ui/react';
import {
    Add,
    TickCircle,
} from 'iconsax-react';
import { useState } from 'react';
import { DragSourceMonitor, useDrag } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import {
    useDocumentRequestCreateRequestsFromTemplate,
    useDocumentRequestDeleteTemplate,
    useDocumentRequestDeleteTemplateRequest,
    useDocumentRequestUpdateTemplate,
} from '../../../../../utils/api/hooks';
import {
    DocumentRequestTemplateType,
    FolderDropTargetType,
    TemplateDragSourceType,
    dragTargetTypes,
} from '../../../../../types';
import { FormikForm, FormikInput, FormikTextarea } from '../../../../../common/forms';
import ConfirmationModal from '../../../../../common/components/ConfirmationModal';
import RequestListDisplay from './RequestListDisplay';
import RequestListCreation from './RequestListCreation';
import WvwDeleteIconButton from '../../../../../common/components/WvwDeleteIconButton';
import WvwCancelIconButton from '../../../../../common/components/WvwCancelIconButton';
import WvwEditIconButton from '../../../../../common/components/display/WvwEditIconButton';

type WithoutCaseId = {
    caseId: undefined;
    enableDragAndDrop: false;
};

type WithCaseId = {
    caseId: number;
    enableDragAndDrop?: boolean;
};

type PropTypes = {
    template: DocumentRequestTemplateType;
    enableEditing: boolean;
} & (WithCaseId | WithoutCaseId);

type TemplateViewPropTypes = {
    template: DocumentRequestTemplateType;
};

const TemplateView = (props: TemplateViewPropTypes) => {
    const { template } = props;

    return (
        <VStack align="left">
            <Text textAlign="left" fontWeight="500">
                {template.name}
            </Text>

            <AccordionPanel marginInline="0" paddingInline="0" paddingTop="0" paddingBottom="0">
                <Text color="wvwGrey60" textAlign="left" maxW="12rem">
                    {template.description}
                </Text>
            </AccordionPanel>
        </VStack>
    );
};

const TemplateListItem = (props: PropTypes) => {
    const {
        caseId: caseIdProp,
        template,
        enableEditing = false,
        enableDragAndDrop = false,
    } = props;

    const caseId = Number(caseIdProp);

    const { t } = useTranslation('documentmanagement');

    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

    const { update: createRequests } = useDocumentRequestCreateRequestsFromTemplate({ caseId });

    const { update: deleteTemplate } = useDocumentRequestDeleteTemplate({ caseId });

    const { update: updateTemplate } = useDocumentRequestUpdateTemplate({ caseId });

    const { update: deleteRequest } = useDocumentRequestDeleteTemplateRequest({ caseId });

    const [editing, setEditing] = useState(false);
    const [addingRequest, setAddingRequest] = useState(false);

    const canDrag = enableDragAndDrop && !editing;

    const templateIsEmpty = (template.requests.length === 0 && !template.description);

    const [{ isDragging }, drag] = useDrag(() => ({
        type: dragTargetTypes.TEMPLATE_IMPORT,
        item: { templateId: template.id } as TemplateDragSourceType,
        canDrag: () => canDrag,
        end(item, monitor) {
            const dropResult = monitor.getDropResult() as FolderDropTargetType;

            if (item && dropResult) {
                createRequests({
                    caseId,
                    folderId: dropResult.folderId,
                    templateId: item.templateId,
                });
            }
        },
        collect: (monitor: DragSourceMonitor) => ({
            isDragging: monitor.isDragging(),
        }),
    }), [editing]);

    if (isDragging) {
        return null;
    }

    let templateDisplay;
    let templateOpButtons;
    let requestAdder;
    let requestEdit;
    let requestDelete;

    if (editing) {
        templateDisplay = (
            <FormikForm
                initialValues={{
                    name: template.name,
                    description: template.description,
                }}
                onSubmit={values => {
                    updateTemplate({
                        templateId: template.id,
                        name: values.name,
                        description: values.description,
                    });

                    setEditing(false);
                }}
            >
                <VStack w="100%" onClick={e => e.stopPropagation()}>
                    <FormikInput name="name" placeholder={t('renameFile')} />

                    <FormikTextarea name="description" placeholder={t('instructionNotes')} />

                    <ButtonGroup alignSelf="flex-end">
                        <WvwCancelIconButton
                            onClick={e => {
                                setEditing(false);
                                e.stopPropagation();
                            }}
                            color="black"
                        />

                        <IconButton aria-label="edit template" icon={<TickCircle color="black" />} type="submit" variant="none" />
                    </ButtonGroup>
                </VStack>
            </FormikForm>
        );

        requestAdder = addingRequest ? (
            <FormikForm
                initialValues={{
                    requests: [],
                }}
                onSubmit={values => {
                    updateTemplate({
                        templateId: template.id,
                        requests: values.requests,
                    });
                    setAddingRequest(false);
                }}
            >
                <RequestListCreation name="requests" submitOnAdd />
            </FormikForm>
        ) : (
            <VStack
                padding=".5rem"
                borderRadius="10"
                bg="wvwGrey05"
                onClick={() => setAddingRequest(true)}
                cursor="pointer"
            >
                <HStack spacing="0" color="wvwGrey80">
                    <Add />

                    <Text>{t('addRequest')}</Text>
                </HStack>
            </VStack>
        );
    } else {
        templateDisplay = <TemplateView template={template} />;

        if (enableEditing) {
            templateOpButtons = (
                <ButtonGroup onClick={e => e.stopPropagation()}>
                    <WvwDeleteIconButton
                        onClick={e => {
                            e.stopPropagation();
                            setDeleteConfirmOpen(true);
                        }}
                        color="black"
                    />

                    <WvwEditIconButton
                        onClick={() => setEditing(true)}
                        color="black"
                    />
                </ButtonGroup>
            );
        }
    }

    if (enableEditing) {
        requestDelete = (requestId: number) => deleteRequest(requestId);

        requestEdit = (requestId: number, name: string, description: string) => updateTemplate({
            templateId: template.id,
            requests: template.requests.map(request => (
                request.id === requestId ? {
                    id: request.id,
                    name,
                    description,
                } : {
                    id: request.id,
                    name: request.name,
                    description: request.description,
                }
            )),
        });
    }

    return (
        <>
            <AccordionItem key={template.id} ref={drag}>
                <AccordionButton
                    alignContent="center"
                    w="100%"
                    cursor={templateIsEmpty ? 'default' : 'pointer'}
                >
                    <HStack
                        align="left"
                        color="wvwGreen"
                        w="100%"
                        onClick={e => {
                            if (templateIsEmpty) {
                                e.stopPropagation();
                            }
                        }}
                    >
                        <Box
                            width="1rem"
                        >
                            {!templateIsEmpty && (
                                <AccordionIcon />
                            )}
                        </Box>

                        {templateDisplay}
                    </HStack>
                    <Spacer />
                    {templateOpButtons}
                </AccordionButton>

                <Box
                    paddingTop=".5rem"
                >
                    {requestAdder}
                </Box>

                <AccordionPanel marginInline="0" paddingInline="0">
                    <RequestListDisplay
                        onEdit={requestEdit}
                        onDelete={requestDelete}
                        requests={template.requests}
                        requestType="TEMPLATE"
                        noDrag
                    />
                </AccordionPanel>
            </AccordionItem>

            <ConfirmationModal
                cancelButtonVariant="primary"
                continueButtonVariant="danger"
                content={t('modal.message.deleteTemplate')}
                isOpen={deleteConfirmOpen}
                onCancel={() => setDeleteConfirmOpen(false)}
                onContinue={() => deleteTemplate(template.id)}
                title={t('modal.title.deleteTemplate')}
            />
        </>
    );
};

export default TemplateListItem;
