import {
    Box,
    Grid,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import CaseOverviewBox from './components/CaseOverviewBox';

const CaseOverviewInfo = () => {
    const { t } = useTranslation('activecases');

    const caseOverviews = [
        {
            title: t('caseOverview.overview.dates.title'),
            listItems: [
                t('caseOverview.overview.dates.startDate'),
                t('caseOverview.overview.dates.offerSent'),
                t('caseOverview.overview.dates.valuationExpected'),
            ],
        },
        {
            title: t('caseOverview.overview.customerInfo.title'),
            listItems: [
                t('caseOverview.overview.customerInfo.name'),
                t('caseOverview.overview.customerInfo.property'),
                t('caseOverview.overview.customerInfo.enquiryType'),
            ],
        },
        {
            title: t('caseOverview.overview.contractDetails.title'),
            listItems: [
                t('caseOverview.overview.contractDetails.signed'),
                t('caseOverview.overview.contractDetails.price'),
                t('caseOverview.overview.contractDetails.specialArrangements'),
            ],
        },
        {
            title: t('caseOverview.overview.inspectionDocuments.title'),
            listItems: [
                t('caseOverview.overview.inspectionDocuments.outstanding'),
                t('caseOverview.overview.inspectionDocuments.inspectionDate'),
            ],
        },
        {
            title: t('caseOverview.overview.paymentDetails.title'),
            listItems: [
                t('caseOverview.overview.paymentDetails.paymentExpected'),
            ],
        },
    ];

    return (
        <Grid
            templateColumns={{
                base: '1fr',
                md: 'repeat(2, 1fr)',
                lg: 'repeat(3, 1fr)',
            }}
            gap={6}
            width="100%"
            borderRadius="10"
        >
            {caseOverviews.map(caseOverview => (
                <Box key={caseOverview.title}>
                    <CaseOverviewBox
                        title={caseOverview.title}
                        listItems={caseOverview.listItems}
                    />
                </Box>
            ))}
        </Grid>
    );
};

export default CaseOverviewInfo;
