import {
    Center,
    Divider,
    Flex,
    Heading,
    HStack,
    Spacer,
    Text,
    VStack,
} from '@chakra-ui/react';
import { useContext, useState } from 'react';
import { CheckIcon } from '@chakra-ui/icons';
import { useRole, useSmartTranslation } from '../../../../../common/hooks';
import { WvwText, WVWTitle } from '../../../../../common/components/typography';
import { WvwButton } from '../../../../../common/components/inputs';
import { FlowContext } from '../../../../../common/components/display/ProcessFlowRouted';
import ModalDynamic from '../../../../../common/components/ModalDynamic';
import Privacy from '../../../../../common/components/Privacy';
import WvwSecurityShield from '../../../../../assets/icons/custom-icons/WvwSecurityShield';
import LoadingSpinner from '../../../../../common/components/LoadingSpinner';
import { useMe } from '../../../../../utils/api/hooks';

const ProfileCompletionLanding = () => {
    const t = useSmartTranslation('profile');

    const { goNext } = useContext(FlowContext);

    const [modal, setModal] = useState(false);

    const {
        data: user,
        isLoading: userLoading,
    } = useMe();

    const {
        userIsClient,
        userIsCompanyAdmin,
        userIsValuer,
        userIsIndividual,
        roleLoading,
    } = useRole();

    if (roleLoading || userLoading) return <LoadingSpinner />;

    const valuerDoesValuations = user.doesValuations;

    const keyBenefits = [
        t('profileCompletionWizard.landing.keyBenefits.benefits.improvedMatchmaking'),
        t('profileCompletionWizard.landing.keyBenefits.benefits.enhancedTransparency'),
        t('profileCompletionWizard.landing.keyBenefits.benefits.increasedVisibility'),
    ];

    const baseSteps = [
        t('profileCompletionWizard.steps.company'),
        t('profileCompletionWizard.steps.personal'),
        t('profileCompletionWizard.steps.qualifications'),
        t('profileCompletionWizard.steps.valuationOffer'),
        t('profileCompletionWizard.steps.extraDocs'),
    ];

    let steps;
    switch (true) {
        case (userIsClient && userIsCompanyAdmin):
            steps = [
                baseSteps[0],
                baseSteps[1],
            ];
            break;
        case ((userIsClient && !userIsCompanyAdmin) || userIsIndividual):
            steps = [
                baseSteps[1],
            ];
            break;
        case (userIsValuer && !userIsCompanyAdmin):
            steps = [
                baseSteps[1],
                baseSteps[2],
                baseSteps[3],
            ];
            break;
        case (userIsValuer && !userIsCompanyAdmin && !valuerDoesValuations):
            steps = [
                baseSteps[1],
            ];
            break;
        case (userIsValuer && userIsCompanyAdmin && !valuerDoesValuations):
            steps = [
                baseSteps[0],
                baseSteps[1],
            ];
            break;
        default:
            steps = baseSteps;
            break;
    }

    return (
        <Center>
            <Flex
                padding="4rem"
                width="100%"
                gap={4}
                alignItems="center"
                marginTop={{ base: '0', md: '4rem' }}
                flexWrap={{ base: 'wrap-reverse', md: 'nowrap' }}
            >
                <VStack
                    align="left"
                    spacing={8}
                    justifyContent="center"
                    width="100%"
                    maxWidth={{ md: '60%' }}
                >
                    <Heading
                        size="2xl"
                        color="black"
                    >
                        {t('profileCompletionWizard.landing.title')}
                    </Heading>

                    <Heading
                        size="1xl"
                        color="black"
                    >
                        {t('profileCompletionWizard.landing.subtitle')}
                    </Heading>

                    {userIsValuer && (
                        <VStack align="left" spacing={4}>
                            <WVWTitle
                                level="3"
                                color="black"
                                content={t('profileCompletionWizard.landing.keyBenefits.title')}
                            />
                            {keyBenefits.map(benefit => (
                                <HStack key={benefit}>
                                    <CheckIcon color="green" />

                                    <WvwText>
                                        {benefit}
                                    </WvwText>
                                </HStack>
                            ))}
                        </VStack>
                    )}

                    <Divider />

                    <HStack>
                        <WvwSecurityShield width="2rem" />

                        <Text>
                            {t('profileCompletionWizard.landing.securityDisclaimer')}
                            {' '}
                            <WvwButton
                                variant="link"
                                content={t('privacyPolicy', { ns: 'common' })}
                                onClick={() => setModal(true)}
                            />
                        </Text>
                    </HStack>

                    <WvwButton
                        variant="primary"
                        content={t('profileCompletionWizard.landing.completeProfile')}
                        onClick={goNext}
                    />
                </VStack>

                <Spacer />

                <VStack
                    align="left"
                    spacing="4"
                    justifyContent="center"
                    width="100%"
                    maxWidth={{ md: '30%' }}
                    flexWrap="wrap"
                >
                    <Heading
                        size="1xl"
                        color="black"
                    >
                        {t('profileCompletionWizard.landing.stepsTitle')}
                    </Heading>

                    <Flex
                        width="100%"
                        flexWrap="wrap"
                        gap={4}
                    >
                        {steps.map((step, index) => (
                            <HStack
                                key={step}
                                width="100%"
                            >
                                <Center
                                    bgColor="wvwGrey10"
                                    width="2rem"
                                    height="2rem"
                                    borderRadius="10"
                                >
                                    <Text
                                        textAlign="center"
                                    >
                                        {index + 1}
                                    </Text>
                                </Center>

                                <WVWTitle
                                    level="3"
                                    color="black"
                                    content={step}
                                />
                            </HStack>
                        ))}
                    </Flex>
                </VStack>
            </Flex>

            <ModalDynamic
                variant="small"
                isOpen={modal}
                header={t('privacy', { ns: 'common' })}
                onClose={() => setModal(false)}
            >
                <Privacy />
            </ModalDynamic>
        </Center>
    );
};

export default ProfileCompletionLanding;
