/* eslint-disable max-len */
import { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useTranslation } from 'react-i18next';
import { auth } from './utils/firebase';
import Account from './views/dashboard/account/Account';
import ActiveCasesDashboard from './views/dashboard/active-cases/ActiveCasesDashboard';
import AdminUsers from './views/admin/users/AdminUsers';
import Case from './views/dashboard/case/Case';
import CaseOverview from './views/dashboard/case/overview/CaseOverview';
import ClientEnquiryBox from './common/components/display/enquiry-form/display/ClientEnquiryBox';
import CompanyCustomize from './views/dashboard/account/customize/CompanyCustomize';
import CompanyDetails from './views/dashboard/account/index/CompanyDetails';
import CompanyDocuments from './views/dashboard/account/documents/CompanyDocuments';
import CompanyTeam from './views/dashboard/account/team/CompanyTeam';
import ContactUs from './views/contact-us/ContactUs';
import CreateDirectEnquiry from './views/direct-enquiry-form/CreateDirectEnquiry';
import SiteOutlet from './views/dashboard/SiteOutlet';
import DormantCases from './views/dashboard/dormant-cases/DormantCases';
import DormantEnquiries from './views/dashboard/dormant-enquiries/DormantEnquiries';
import DraftEnquiries from './views/dashboard/drafts/DraftEnquiries';
import EmailSharedDocs from './views/dashboard/account/documents/email/EmailSharedDocs';
import Enquiries from './views/dashboard/enquiries/Enquiries';
import EnquiryOverview from './views/dashboard/enquiry/overview/EnquiryOverview';
import EnquiryEmailSlider from './views/dashboard/account/integration/enquiry-email-link/EnquiryEmailSlider';
import EnquiryInvoiceInfo from './views/create-client-enquiry/enquiry-contact-info/EnquiryContactInfo';
import Enquiry from './views/dashboard/enquiry/Enquiry';
import EnquiryOverviewInfo from './views/create-client-enquiry/enquiry-overview-info/EnquiryOverviewInfo';
import EnquiryPropertyInfo from './views/create-client-enquiry/enquiry-property-info/EnquiryPropertyInfo';
import EnquiryValuationInfo from './views/create-client-enquiry/enquiry-valuation-info/EnquiryValuationInfo';
import EnquiryValuerInfo from './views/create-client-enquiry/enquiry-valuer-info/EnquiryValuerInfo';
import ForgotPassword from './views/forgot-password/ForgotPassword';
import Home from './Home';
import Integration from './views/dashboard/account/integration/Integration';
import LanguageRedirect from './views/language-redirect/LanguageRedirect';
import LoginFlow from './views/login/LoginFlow';
import Logout from './views/logout/Logout';
import Notifications from './views/dashboard/notifications/Notifications';
import Offer from './views/dashboard/enquiry/offer/Offer';
import OffersOverview from './views/dashboard/enquiry/offers/OffersOverview';
import ProcessNotification from './views/process-notification/ProcessNotification';
import Profile from './views/dashboard/profile/Profile';
import ProfileDetails from './views/dashboard/profile/components/ProfileDetails';
import ProfileTeam from './views/dashboard/profile/components/ProfileTeam';
import SiteLoading from './common/components/SiteLoading';
import ValuerPanel from './views/dashboard/account/valuer-panel/ValuerPanel';
import SuspendedScreen from './views/suspended-access/SuspendedScreen';
import CaseOffer from './views/dashboard/case/offer/CaseOffer';
import CaseDocuments from './views/dashboard/case/documents/CaseDocuments';
import RequestUnavailableOrIncomplete from './views/dashboard/case/documents/common/request-unavailable-or-incomplete/RequestUnavailableOrIncomplete';
import ReplaceDocument from './views/dashboard/case/documents/common/replace-document/ReplaceDocument';
import DocumentRequestDetailsModal from './views/dashboard/case/documents/common/request/DocumentRequestDetailsModal';
import DocumentPreview from './views/dashboard/case/documents/common/document-preview/DocumentPreview';
import SelectDates from './views/dashboard/case/inspection/select-dates/SelectDates';
import Inspection from './views/dashboard/case/inspection/Inspection';
import ScheduledInspectionModal from './views/dashboard/case/inspection/ScheduledInspectionModal';
import FourZeroFour from './FourZeroFour';
import Admin from './views/admin/Admin';
import AdminEnquiries from './views/admin/enquiries/AdminEnquiries';
import AdminOffers from './views/admin/offers/AdminOffers';
import AdminCases from './views/admin/cases/AdminCases';
import AdminUser from './views/admin/users/user/AdminUser';
import AdminEnquiry from './views/admin/enquiry/AdminEnquiry';
import AdminEntities from './views/admin/entities/AdminEntities';
import Register from './views/register/Register';
import FbAction from './views/fb-action/FbAction';
import ProfileTemplates from './views/dashboard/profile/components/ProfileTemplates';
import AdminDocuments from './views/admin/documents/AdminDocuments';
import AdminInspections from './views/admin/inspections/AdminInspections';
import Dashboard from './views/dashboard/dashboard/Dashboard';
import ProfileCompletionWizard from './views/dashboard/dashboard/profile-completion-wizard/ProfileCompletionWizard';
import ProfileCompletionLanding from './views/dashboard/dashboard/profile-completion-wizard/components/ProfileCompletionLanding';
import CaseReportDelivery from './views/dashboard/case/report-delivery/CaseReportDelivery';
import ProfileCompletionContactDetails from './views/dashboard/dashboard/profile-completion-wizard/components/ProfileCompletionContactDetails';
import ProfileCompletionBilling from './views/dashboard/dashboard/profile-completion-wizard/components/ProfileCompletionBilling';
import ProfileCompletionCompetencies from './views/dashboard/dashboard/profile-completion-wizard/ProfileCompletionCompetencies';
import ProfileCompletionExtras from './views/dashboard/dashboard/profile-completion-wizard/components/ProfileCompletionExtras';
// import CompanyAdminActiveCases from './views/company-admin/cases/CompanyAdminCases';
// import CompanyAdminEnquiries from './views/company-admin/enquiries/CompanyAdminEnquiries';
// import CompanyAdminEnquiryOverview from './views/company-admin/enquiries/CompanyAdminEnquiryOverview';
// import CompanyAdmin from './views/company-admin/CompanyAdmin';
import CaseEnquiryOverview from './views/dashboard/case/enquiry/CaseEnquiryOverview';
import { checkAuthState } from './common/functions/auth';
import SendEmailVerification from './views/send-email-verification/SendEmailVerification';
import RegisterSuccess from './views/register-success/RegisterSuccess';
import AdminCaseReports from './views/admin/case-reports/AdminCaseReports';
import AdminCaseReport from './views/admin/case-report/AdminCaseReport';
import AdminUploadedContracts from './views/admin/uploaded-contracts/AdminUploadedContracts';
import AdminUploadedContract from './views/admin/uploaded-contract/AdminUploadedContract';
import ProfileCompletionQualifications from './views/dashboard/dashboard/profile-completion-wizard/components/ProfileCompletionQualifications';

const {
    REACT_APP_FEATURE_ENABLE_DMS = 'false',
    REACT_APP_FEATURE_ENABLE_INSPECTION = 'false',
    REACT_APP_FEATURE_ENABLE_VALUATION_REPORT = 'false',
} = process.env;

const Router = () => {
    const { pathname } = window.location;

    const { t } = useTranslation();

    const [user, loading] = useAuthState(auth);

    useEffect(() => {
        if (loading) return;

        checkAuthState(user);
    }, [user, loading, pathname]);

    if (loading) {
        if (pathname.includes('/direct-enquiry-form/')) {
            return (
                <SiteLoading
                    noLogo
                    text={t('loading', { ns: 'common' })}
                />
            );
        }

        return <SiteLoading />;
    }

    return (
        <BrowserRouter>
            <Routes>
                <Route index element={<Home />} />

                <Route path="admin" element={<Admin />}>
                    <Route index element={<AdminUsers />} />
                    <Route path="cases" element={<AdminCases />} />
                    <Route path="case-reports" element={<AdminCaseReports />} />
                    <Route path="case-report/:reportId" element={<AdminCaseReport />} />
                    <Route path="documents" element={<AdminDocuments />} />
                    <Route path="enquiries" element={<AdminEnquiries />} />
                    <Route path="enquiry/:enquiryId" element={<AdminEnquiry />} />
                    <Route path="entities" element={<AdminEntities />} />
                    <Route path="inspections" element={<AdminInspections />} />
                    <Route path="offers" element={<AdminOffers />} />
                    <Route path="uploaded-contract/:contractId" element={<AdminUploadedContract />} />
                    <Route path="uploaded-contracts" element={<AdminUploadedContracts />} />
                    <Route path="users" element={<AdminUsers />} />
                    <Route path="users/:userId" element={<AdminUser />} />
                </Route>

                {/* <Route path="company-admin" element={<CompanyAdmin />}>
                    <Route path="cases" element={<CompanyAdminActiveCases />} />
                    <Route path="enquiries" element={<CompanyAdminEnquiries />} />
                    <Route
                        path="enquiry/:enquiryId"
                        element={<Enquiry forCompanyAdmin />}
                    >
                        <Route index element={<CompanyAdminEnquiryOverview />} />
                        <Route path="overview" element={<CompanyAdminEnquiryOverview />} />
                    </Route>

                    <Route path="case/:caseId" element={<Case />}>
                        <Route index element={<CaseOverview />} />

                        <Route path="offer" element={<CaseOffer />} />
                    </Route>
                </Route> */}

                <Route path="dashboard" element={<SiteOutlet />}>
                    <Route index element={<Dashboard />} />

                    <Route path="case/:caseId" element={<Case />}>
                        <Route index element={<CaseOverview />} />

                        <Route path="enquiry" element={<CaseEnquiryOverview />} />

                        <Route path="offer" element={<CaseOffer />} />

                        {REACT_APP_FEATURE_ENABLE_DMS === 'true' && (
                            <Route path="documents" element={<CaseDocuments />}>
                                <Route path="request/:requestId" element={<DocumentRequestDetailsModal />} />

                                <Route path="document-preview/:docId" element={<DocumentPreview />} />

                                <Route path="replace-document/:documentId" element={<ReplaceDocument />} />

                                <Route path="request-unavailable-or-incomplete/:requestId" element={<RequestUnavailableOrIncomplete />} />
                            </Route>
                        )}

                        {REACT_APP_FEATURE_ENABLE_INSPECTION === 'true' && (
                            <Route path="inspection" element={<Inspection />}>
                                <Route path="inspection-details/:inspectionId" element={<ScheduledInspectionModal />} />
                                <Route path="select-dates/:inspectionId" element={<SelectDates />} />
                            </Route>
                        )}

                        {REACT_APP_FEATURE_ENABLE_VALUATION_REPORT === 'true' && (
                            <>
                                <Route path="report-delivery" element={<CaseReportDelivery />} />
                                <Route path="report-delivery/view-report/:reportId" element={<CaseReportDelivery />} />
                                <Route path="report-delivery/view-report/:reportId/document/:documentId" element={<CaseReportDelivery />} />
                            </>
                        )}
                    </Route>

                    <Route path="enquiries" element={<Enquiries />} />

                    <Route path="enquiry/:enquiryId" element={<Enquiry />}>
                        <Route index element={<EnquiryOverview />} />

                        <Route path="overview" element={<EnquiryOverview />} />
                        <Route path="offer" element={<OffersOverview />} />
                        <Route path="offer/:offerId" element={<Offer />} />
                    </Route>

                    <Route path="dormant-enquiries" element={<DormantEnquiries />} />

                    <Route path="active-cases" element={<ActiveCasesDashboard />} />

                    <Route path="dormant-cases" element={<DormantCases />} />

                    <Route path="drafts" element={<DraftEnquiries />} />

                    <Route path="notifications" element={<Notifications />} />

                    <Route path="profile" element={<Profile />}>
                        <Route index element={<ProfileDetails />} />

                        <Route path="*" element={<ProfileDetails />} />
                        <Route path="team" element={<ProfileTeam />} />
                        <Route path="templates" element={<ProfileTemplates />} />

                        <Route path="documents" element={<CompanyDocuments />}>
                            <Route path="email" element={<EmailSharedDocs />} />
                        </Route>
                    </Route>

                    <Route path="account" element={<Account />}>
                        <Route index element={<CompanyDetails />} />

                        <Route path="*" element={<CompanyDetails />} />
                        <Route path="team" element={<CompanyTeam />} />
                        <Route path="valuer-panel" element={<ValuerPanel />} />

                        <Route path="documents" element={<CompanyDocuments />}>
                            <Route path="email" element={<EmailSharedDocs />} />
                        </Route>

                        <Route path="customize" element={<CompanyCustomize />} />

                        <Route path="integration" element={<Integration />}>
                            <Route path="enquiry-email-link/:isCompanyOrIndividual" element={<EnquiryEmailSlider />} />
                        </Route>
                    </Route>
                </Route>

                <Route
                    path="complete-profile"
                    element={<ProfileCompletionWizard />}
                >
                    <Route index element={<ProfileCompletionLanding />} />

                    <Route path="company" element={<ProfileCompletionBilling />} />

                    <Route path="personal" element={<ProfileCompletionContactDetails />} />

                    <Route path="qualifications" element={<ProfileCompletionQualifications />} />

                    <Route path="valuation" element={<ProfileCompletionCompetencies />} />

                    <Route path="extras" element={<ProfileCompletionExtras />} />
                </Route>

                <Route
                    path="create-client-enquiry"
                    element={<ClientEnquiryBox />}
                >
                    <Route index element={<EnquiryValuationInfo />} />

                    <Route path="enquiry-valuation-info/:enquiryId" element={<EnquiryValuationInfo />} />
                    <Route path="enquiry-valuer-info/:enquiryId" element={<EnquiryValuerInfo />} />
                    <Route path="enquiry-property-info/:enquiryId" element={<EnquiryPropertyInfo />} />
                    <Route path="enquiry-invoice-info/:enquiryId" element={<EnquiryInvoiceInfo />} />
                    <Route path="enquiry-overview-info/:enquiryId" element={<EnquiryOverviewInfo />} />
                </Route>

                <Route path="process-notification/:notificationId" element={<ProcessNotification />} />

                <Route path="forgot-password" element={<ForgotPassword />} />

                <Route path="fb-action" element={<FbAction />} />

                <Route path="contact-us" element={<ContactUs />} />

                <Route path="language-redirect/:lang/:destination" element={<LanguageRedirect />} />

                <Route path="language-redirect/:lang" element={<LanguageRedirect />} />

                <Route path="login" element={<LoginFlow />} />

                <Route path="login/:redirectUrl" element={<LoginFlow />} />

                <Route path="logout" element={<Logout />} />

                <Route path="register" element={<Register />} />

                <Route path="register/:registerAsType" element={<Register />} />

                <Route path="register/:inviteType/:token" element={<Register />} />

                <Route path="register-success" element={<RegisterSuccess />} />

                <Route path="send-email-verification" element={<SendEmailVerification />} />
                <Route path="send-email-verification/:redirectUrl" element={<SendEmailVerification />} />

                <Route path="suspended-access" element={<SuspendedScreen />} />

                <Route path="direct-enquiry-form/:clientType/:token" element={<CreateDirectEnquiry />} />
                <Route path="direct-enquiry-form/:token" element={<CreateDirectEnquiry />} />

                <Route path="*" element={<FourZeroFour />} />
            </Routes>
        </BrowserRouter>
    );
};

export default Router;
