import DataTable, { DataTableColumnType } from '../../../common/components/datatable/DataTable';
import PageWithTitleLayout from '../../../common/components/display/PageWithTitleLayout';
import StatusBadgeInspection from '../../../common/components/display/StatusBadgeInspection';
import LoadingSpinner from '../../../common/components/LoadingSpinner';
import { useSmartTranslation } from '../../../common/hooks';
import { InspectionType, InspectionWithCaseType } from '../../../types';
import { useAdminGetAllUsers, useAdminInspections } from '../../../utils/api/hooks';

type TableDataType = InspectionWithCaseType & {
    valuerName: string;
    accessContact: string;
};

const AdminInspections = () => {
    const t = useSmartTranslation('dashboard');

    const {
        data: inspectionsData = [],
        isLoading: inspectionsLoading,
    } = useAdminInspections({});

    const {
        data: valuerData = [],
        isLoading: valuerIsLoading,
    } = useAdminGetAllUsers();

    if (inspectionsLoading || valuerIsLoading) {
        return <LoadingSpinner />;
    }

    const inspectionTableData: TableDataType[] = inspectionsData.map(inspection => {
        const valuerId = inspection.case.valuerUserId;
        const valuer = valuerData.find(user => user.id === valuerId);
        const accessContactName = `${inspection.contactPerson?.firstName} ${inspection.contactPerson?.lastName}`;

        return {
            ...inspection,
            valuerName: `${valuer.firstName} ${valuer.lastName}`,
            accessContact: accessContactName,
        };
    });

    const headers: DataTableColumnType<TableDataType>[] = [
        {
            title: '#',
            sorter: (a, b) => a.id - b.id,
            render: i => (i.id),
        },
        {
            title: t('inspectionsTable.header.accessContact'),
            maxWidth: '15rem',
            sorter: (a, b) => a.accessContact.localeCompare(b.accessContact),
            render: i => i.accessContact,
        },
        {
            title: t('inspectionsTable.header.valuer'),
            sorter: (a, b) => a.valuerName.localeCompare(b.valuerName),
            render: i => i.valuerName,
        },
        {
            title: t('inspectionsTable.header.property'),
            sorter: (a, b) => {
                const aProperty = a?.property?.street;
                const bProperty = b?.property?.street;

                return aProperty.localeCompare(bProperty);
            },
            render: i => (i.property.street),
        },
        {
            title: t('inspectionsTable.header.phase'),
            dataIndex: 'phase',
            render: i => <StatusBadgeInspection status={i.phase} />,
            sorter: (a, b) => a.phase.localeCompare(b.phase),
        },
    ];

    const inspectionFilters = [
        {
            filterName: 'Phase',
            placeholder: 'Phase',
            options: [
                { label: t('filters.inspections.Phase.PREPARATION', { ns: 'admin' }), value: 'PREPARATION' },
                { label: t('filters.inspections.Phase.NEGOTIATION', { ns: 'admin' }), value: 'NEGOTIATION' },
                { label: t('filters.inspections.Phase.INSPECTION', { ns: 'admin' }), value: 'INSPECTION' },
                { label: t('filters.inspections.Phase.FINISHED', { ns: 'admin' }), value: 'FINISHED' },
            ],
            filterFunction:
                (i: InspectionType, filterValue?: string) => i.phase === filterValue,
        },
    ];

    return (
        <PageWithTitleLayout title="Admin Inspections">
            <DataTable
                columns={headers}
                data={inspectionTableData}
                filters={inspectionFilters}
            />
        </PageWithTitleLayout>
    );
};

export default AdminInspections;
