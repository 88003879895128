import {
    CircularProgress,
    CircularProgressLabel,
    Flex,
    HStack,
    Spacer,
    VStack,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CheckIcon } from '@chakra-ui/icons';
import { WvwText, WVWTitle } from '../../../../common/components/typography';
import { WvwButton } from '../../../../common/components/inputs';
import { useMyEntity, useMe } from '../../../../utils/api/hooks';
import LoadingSpinner from '../../../../common/components/LoadingSpinner';
import { useRole } from '../../../../common/hooks';

const calculateProgress = (profileData: any): number => {
    let progress = 100;

    progress = profileData.reduce((acc: any, currentValue: any): number => {
        if (currentValue === null || currentValue === undefined || currentValue === '') {
            return acc - 2;
        }
        return acc;
    }, 100);

    return progress;
};

type PropTypes = {
    progress: number;
};

const ProgressCircle = (props: PropTypes) => {
    const { progress } = props;
    let color = '';

    switch (true) {
        case (progress < 20):
            color = 'red';
            break;
        case (progress < 40):
        case (progress < 60):
            color = 'wvwYellow';
            break;
        case (progress < 80):
        case (progress < 100):
            color = 'green';
            break;
        default:
            color = 'green';
            break;
    }
    return (
        <CircularProgress
            value={progress}
            color={color}
            size="5rem"
        >
            <CircularProgressLabel>
                {`${progress}%`}
            </CircularProgressLabel>
        </CircularProgress>
    );
};

const ProfileCompletionBanner = () => {
    const { t } = useTranslation(['dashboard']);
    const navigate = useNavigate();

    const {
        data: entity,
        entitySampleValuations = [],
        entitySampleValuationsIsLoading,
        entityCertificates = [],
        entityCertificatesIsLoading,
        isLoading: entityLoading,
    } = useMyEntity();

    const {
        data: user,
        isLoading: userLoading,
    } = useMe();

    const {
        userIsValuer,
        roleLoading,
    } = useRole();

    if (userLoading || entityLoading || entitySampleValuationsIsLoading
        || entityCertificatesIsLoading || roleLoading) return <LoadingSpinner />;

    const awardsAndMarketing = entityCertificates.filter(i => i.type.toLowerCase() === 'award' && i.type.toLowerCase() === 'marketing');

    const profileData = [
        user.firstName,
        user.lastName,
        user.email,
        user.mobileDialCode,
        user.mobile,
        user.orgPosition,
        entity.websiteAddress,
        entity.valuerNumberOfPartners,
        entity.valuerYearsOfExperience,
        entity.name,
        entity.billingStreet,
        entity.billingCity,
        entity.billingCountry,
        entity.billingPostalCode,
        user.street,
        user.city,
        user.country,
        user.postalCode,
        user.officeTelDialCode,
        user.officeTel,
        user.officeFaxDialCode,
        user.officeFax,
        user.maxOperatingDistance,
        user.standardMemberships,
        user.otherMemberships,
        user.standardQualifications,
        user.otherQualifications,
        entity.piCoverValue,
        entity.piCoverValidityDate,
        entity.piCoverSpecialArrangementsAvailable,
        user.standardMemberships,
        user.valuationTypes,
        user.valuationTypes,
        user.valuationOccasions,
        entitySampleValuations,
        awardsAndMarketing,
    ];

    const progress = calculateProgress(profileData);

    const benefits = [
        t('profileCompletion.benefitOne'),
        t('profileCompletion.benefitTwo'),
        t('profileCompletion.benefitThree'),
    ];

    if (progress !== 100) {
        return (
            <Flex
                paddingInline="2.5rem"
                paddingBlock="1rem"
                backgroundColor="white"
                borderRadius="10"
                border="1px solid #e2e8f0"
                alignItems="center"
                marginTop="1rem"
                marginBottom="1rem"
            >
                <HStack
                    spacing="6"
                    justifyItems="top"
                >
                    <ProgressCircle progress={progress} />

                    <VStack align="left" spacing="1">
                        <WVWTitle
                            content={t('profileCompletion.title')}
                            level="2"
                            color="black"
                        />

                        <WvwText color="wvwGrey60">
                            {t('profileCompletion.subtitle')}
                        </WvwText>

                        {userIsValuer && (
                            <VStack spacing="0" align="left">
                                {benefits.map(benefit => (
                                    <HStack key={benefit}>
                                        <CheckIcon color="green" />

                                        <WvwText>
                                            {benefit}
                                        </WvwText>
                                    </HStack>
                                ))}
                            </VStack>
                        )}
                    </VStack>
                </HStack>

                <Spacer />

                <WvwButton
                    content={t('profileCompletion.button')}
                    variant="primary"
                    onClick={() => navigate('/complete-profile')}
                />
            </Flex>
        );
    }
    return null;
};

export default ProfileCompletionBanner;
