import { SimpleGrid, Box, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FormikSelect, FormikInput } from '../../../../common/forms';
import standardMaxOfficeDistance from '../../../../common/vars/valuationsAndMembershipTypes/standardMaxOfficeDistance';
import standardMinimumPartners from '../../../../common/vars/valuationsAndMembershipTypes/standardMinimumPartners';
import standardRequiredYearsOfExperience from '../../../../common/vars/valuationsAndMembershipTypes/standardRequiredYearsOfExperienceRequired';

const Requirements = () => {
    const { t } = useTranslation(['enquiryform', 'formik', 'common']);

    return (
        <SimpleGrid
            columns={2}
            spacing={4}
        >
            <Box>
                <Text
                    color="wvwGrey80"
                    fontWeight="500"
                    paddingBottom=".5rem"
                >
                    {t('newEnquiry.valuerSection.requirements.minPostQualiExperience')}
                </Text>

                <FormikSelect
                    name="valuerExperience"
                    placeholder={t('fieldPlaceholder.pleaseSelect', { ns: 'common' })}
                    options={standardRequiredYearsOfExperience.map(
                        experience => ({
                            label: t(experience.label, { ns: 'formik', defaultValue: experience.label }),
                            value: experience.value,
                        }),
                    )}
                />
            </Box>

            <Box>
                <Text
                    color="wvwGrey80"
                    fontWeight="500"
                    paddingBottom=".5rem"
                >
                    {t('newEnquiry.valuerSection.requirements.minPartners')}
                </Text>

                <FormikSelect
                    name="valuerMinimumPartners"
                    placeholder={t('fieldPlaceholder.pleaseSelect', { ns: 'common' })}
                    options={standardMinimumPartners.map(
                        partners => ({
                            label: t(partners.label, { ns: 'formik', defaultValue: partners.label }),
                            value: partners.value,
                        }),
                    )}
                />
            </Box>

            <Box
                paddingTop="1rem"
            >
                <Text
                    color="wvwGrey80"
                    fontWeight="500"
                    paddingBottom=".5rem"
                >
                    {t('newEnquiry.valuerSection.requirements.maxDistanceProperty')}
                </Text>

                <FormikSelect
                    name="valuerMaxOfficeDistance"
                    placeholder={t('fieldPlaceholder.pleaseSelect', { ns: 'common' })}
                    options={standardMaxOfficeDistance.map(
                        distance => ({
                            label: t(distance.label, { ns: 'formik', defaultValue: distance.label }),
                            value: distance.value,
                        }),
                    )}
                />
            </Box>

            <Box paddingTop="1rem">
                <Text
                    color="wvwGrey80"
                    fontWeight="500"
                    paddingBottom=".5rem"
                >
                    {t('newEnquiry.valuerSection.requirements.minPICover')}
                </Text>

                <FormikInput
                    name="valuerMinimumPICoverPercentage"
                    placeholder="0"
                    prefix="%"
                    type="number"
                    min="1"
                />
            </Box>
        </SimpleGrid>
    );
};

export default Requirements;
