/* eslint-disable react/no-this-in-sfc */
import {
    ButtonGroup,
    HStack,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    VStack,
} from '@chakra-ui/react';
import * as Yup from 'yup';
import { FormikForm, FormikSubmitButton } from '../../../../../common/forms';
import { useRole, useSmartTranslation } from '../../../../../common/hooks';
import { EntityType } from '../../../../../types';
import { useMyEntity } from '../../../../../utils/api/hooks';
import { WvwButton } from '../../../../../common/components/inputs';
import CompanyDetailsDetailsEdit from './CompanyDetailsDetailsEdit';
import parseDelimitedNumber from '../../../../../common/functions/parseDelimitedNumber';
import Logo from '../../../../../common/components/Logo';
import { WVWTitle } from '../../../../../common/components/typography';
import { wvwPalette } from '../../../../../theme';

const basevalidationFields = {
    name: Yup.string().required('errors.required'),
    street: Yup.string().required('errors.required'),
    city: Yup.string().required('errors.required'),
    country: Yup.string().required('errors.required'),
    postalCode: Yup.string().required('errors.required'),
};

const clientValidationFields = {
    contactNumberDialCode: Yup.string().test('contactNumber', 'errors.required', function (value) {
        if (value === undefined) {
            if (this.parent.contactNumber === undefined) return true;

            return false;
        }

        return true;
    }),
    contactNumber: Yup.number().typeError('errors.enterNumber').test('contactNumber', 'errors.required', function (value) {
        if (value === undefined) {
            if (this.parent.contactNumberDialCode === undefined) return true;

            return false;
        }

        return true;
    }),
};

const valuerValidationFields = {
    contactNumberDialCode: Yup.string().required('errors.required'),
    contactNumber: Yup.number()
        .required('errors.required')
        .typeError('errors.enterNumber'),
    companyEmail: Yup.string()
        .email('errors.invalidEmail')
        .required('errors.required'),
    valuerYearsOfExperience: Yup.string().required('errors.required'),
    valuerNumberOfPartners: Yup.number().required('errors.required'),
    piCoverValue: Yup.string().required('errors.required'),
    piCoverPercentage: Yup.string().required('errors.required'),
    piCoverValidityDate: Yup.string()
        .required('errors.required'),
};

type PropsType = {
    entity: EntityType;
    onComplete: () => void;
};

/**
 * Form for editing the current user's company details in their account.
 */

const CompanyDetailsEdit = (props: PropsType) => {
    const { entity, onComplete } = props;

    const initialFormData = {
        name: entity.name || '',
        contactFirstName: entity.contactFirstName || '',
        contactLastName: entity.contactLastName || '',
        websiteAddress: entity.websiteAddress || '',
        companyEmail: entity.companyEmail || '',
        contactNumberDialCode: entity.contactNumberDialCode || '',
        contactNumber: entity.contactNumber || '',
        street: entity.street || '',
        city: entity.city || '',
        postalCode: entity.postalCode || '',
        country: entity.country || '',
        valuerYearsOfExperience: entity.valuerYearsOfExperience || '',
        valuerNumberOfPartners: Number(entity.valuerNumberOfPartners) || undefined,
        piCoverValue: entity.piCoverValue || 0,
        piCoverPercentage: entity.piCoverPercentage || 0,
        piCoverValidityDate: entity.piCoverValidityDate || '',
        piCoverSpecialArrangementsAvailable: entity.piCoverSpecialArrangementsAvailable || false,
    };

    const t = useSmartTranslation('common');
    const { userIsValuer } = useRole();

    const {
        updateEntity,
    } = useMyEntity({
        onUpdateSuccess: () => {
            onComplete();
        },
    });

    const validationSchema = Yup.object().shape({
        ...basevalidationFields,
        ...(userIsValuer ? valuerValidationFields : clientValidationFields),
    });

    return (
        <Modal
            isOpen
            size="full"
            closeOnOverlayClick={false}
            onClose={onComplete}
        >
            <ModalOverlay />

            <ModalContent bgColor={wvwPalette.wvwBackground.main}>
                <FormikForm
                    validationSchema={validationSchema}
                    initialValues={initialFormData}
                    onSubmit={values => {
                        const {
                            valuerNumberOfPartners,
                            valuerYearsOfExperience,
                            piCoverValue,
                            piCoverPercentage,
                            piCoverValidityDate,
                            specialArrangementsAvailable,
                            ...rest
                        } = values;

                        let update = {
                            ...rest,
                        };

                        if (userIsValuer) {
                            const valuerNumberOfPartnersAsString = valuerNumberOfPartners
                                .toString();

                            const piCoverValueasNumber = Number(
                                parseDelimitedNumber(piCoverValue.toString()),
                            );

                            const piCoverPercentageAsNumber = Number(
                                parseDelimitedNumber(piCoverPercentage.toString()),
                            );

                            update = {
                                ...update,
                                valuerNumberOfPartners: valuerNumberOfPartnersAsString,
                                piCoverValue: piCoverValueasNumber,
                                piCoverPercentage: piCoverPercentageAsNumber,
                                valuerYearsOfExperience,
                                piCoverValidityDate,
                                piCoverSpecialArrangementsAvailable: specialArrangementsAvailable,
                            };
                        }

                        updateEntity(update);
                    }}
                >
                    <ModalHeader
                        bgColor="white"
                    >
                        <HStack spacing="0">
                            <Logo size="5rem" withColor />

                            <WVWTitle
                                color="black"
                                level="1"
                                content={t('editCompanyDetails', { ns: 'account' })}
                            />
                        </HStack>
                    </ModalHeader>

                    <ModalCloseButton />

                    <ModalBody>
                        <VStack
                            spacing="6"
                            maxW="1700px"
                            minW="400px"
                            marginX="auto"
                        >
                            <CompanyDetailsDetailsEdit />

                            <ButtonGroup
                                alignSelf="flex-end"
                                width="19rem"
                            >
                                <WvwButton
                                    onClick={onComplete}
                                    content={t('button.cancel')}
                                    variant="outline"
                                />

                                <FormikSubmitButton
                                    block
                                    content={t('button.save')}
                                />
                            </ButtonGroup>
                        </VStack>
                    </ModalBody>
                </FormikForm>
            </ModalContent>
        </Modal>
    );
};

export default CompanyDetailsEdit;
