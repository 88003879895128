import {
    Button,
    VStack,
    SimpleGrid,
    createStandaloneToast,
    Heading,
    Box,
} from '@chakra-ui/react';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import {
    FormikCheckbox,
    FormikCountrySelect,
    FormikForm,
    FormikInput,
    FormikPassword,
} from '../../../common/forms';
import { useAuthSignupInvited, useFbCreateUserWithEmailAndPassword, useSignup } from '../../../utils/api/hooks';
import { RegisterDataType } from '../../../types';
import { FlowContextStateful } from '../../../common/components/display/ProcessFlowStateful';
import RegisterFormsContainer from '../common/RegisterFormsContainer';
import TsAndCsText from '../../../common/components/TsAndCsText';
import { AuthSignupInvitedDto, SignupDto } from '../../../utils/api/dto';
import SiteLoading from '../../../common/components/SiteLoading';
import { confirmPasswordValidation, passwordValidation } from '../../../common/vars/passwordValidation';

type PropTypes = {
    // onContinue: (values: { [index: string]: string }) => void;
    registerData: RegisterDataType;
};

type FormDataType = {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    confirmPassword: string;
    dialCode: string;
    mobile: string;
    termsAndConditions: boolean;
};

const validationSchema = Yup.object({
    firstName: Yup.string()
        .required('errors.required'),
    lastName: Yup.string()
        .required('errors.required'),
    email: Yup.string()
        .email('errors.invalidEmail')
        .required('errors.required'),
    password: passwordValidation,
    confirmPassword: confirmPasswordValidation,
    dialCode: Yup.string()
        .required('errors.required'),
    mobile: Yup.number()
        .required('errors.required').typeError('errors.enterNumber'),
    termsAndConditions: Yup.boolean().required('errors.required').oneOf([true], 'errors.required'),
});

const RegisterPersonalForm = (props: PropTypes) => {
    const {
        registerData,
    } = props;

    const { token = '', inviteType = '' } = useParams();

    const { goBack, goNext } = useContext(FlowContextStateful);

    const { t } = useTranslation(['profile', 'common', 'account']);

    const { toast } = createStandaloneToast();

    const [formData, setFormData] = useState<FormDataType>({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        dialCode: '',
        mobile: '',
        termsAndConditions: false,
    });

    const [loading, setLoading] = useState(false);

    const {
        update: signup,
    } = useSignup({
        onSuccess: () => {
            // onContinue({
            //     firstName: formData.firstName,
            //     lastName: formData.lastName,
            //     email: formData.email,
            //     mobileDialCode: formData.dialCode,
            //     mobile: formData.mobile,
            // });
            goNext();
        },
        onError: error => {
            setLoading(false);

            toast({
                title: t('error.error', { ns: 'common' }),
                description: error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        },
    });

    const { update: signUpInvited } = useAuthSignupInvited({
        onSuccess: () => goNext(),
        onError: error => {
            setLoading(false);

            toast({
                title: t('error.error', { ns: 'common' }),
                description: error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        },
    });

    const {
        update: createUserWithEmailAndPassword,
    } = useFbCreateUserWithEmailAndPassword({
        onSuccess: () => {
            if (inviteType === 'invitedToTeam' && token) {
                const signUpInvitedDto: AuthSignupInvitedDto = {
                    email: formData.email,
                    token,
                    firstName: formData.firstName,
                    lastName: formData.lastName,
                    mobileDialCode: formData.dialCode,
                    mobile: formData.mobile,
                    orgPosition: '',
                };

                signUpInvited(signUpInvitedDto);
            } else {
                const dto: SignupDto = {
                    accountType: registerData.accountType === 'CLIENT' ? 'CLIENT' : 'VALUER',
                    companyOrIndividual: registerData.companyOrIndividual === 'COMPANY' ? 'COMPANY' : 'INDIVIDUAL',
                    billingCity: '',
                    billingCountry: '',
                    billingPostalCode: '',
                    billingStreet: '',
                    billingTier: 'FREE',
                    city: '',
                    country: '',
                    email: formData.email,
                    firstName: formData.firstName,
                    lastName: formData.lastName,
                    mobileDialCode: formData.dialCode,
                    mobile: formData.mobile,
                    mollieCustomerId: '',
                    orgName: '',
                    orgPosition: '',
                    postalCode: '',
                    street: '',
                };

                if ((inviteType === 'invitedToClientPanel' || inviteType === 'invitedToValuerPanel') && token) {
                    dto.invitationType = inviteType === 'invitedToValuerPanel' ? 'INVITEDTOVALUERPANEL' : 'INVITEDTOCLIENTPANEL';
                    dto.token = token;
                }

                signup(dto);
            }
        },
        onError: () => {
            setLoading(false);
        },
    });

    useEffect(() => {
        if (!formData.firstName) return;

        createUserWithEmailAndPassword({
            email: formData.email,
            password: formData.password,
        });
    }, [formData, inviteType, token]);

    if (loading) {
        return <SiteLoading text={t('registering', { ns: 'common' })} />;
    }

    return (
        <RegisterFormsContainer goBack={goBack}>
            <VStack
                spacing="4"
                w="100%"
                align="left"
                paddingTop="2rem"
                paddingBottom="1rem"
            >
                <FormikForm
                    initialValues={formData}
                    validationSchema={validationSchema}
                    onSubmit={values => {
                        setLoading(true);

                        setFormData({
                            firstName: values.firstName,
                            lastName: values.lastName,
                            email: values.email,
                            password: values.password,
                            confirmPassword: values.confirmPassword,
                            dialCode: values.dialCode,
                            mobile: values.mobile,
                            termsAndConditions: values.termsAndConditions,
                        });
                    }}
                >
                    <VStack
                        spacing="6"
                    >
                        <Heading
                            as="h4"
                            size="lg"
                            textAlign="left"
                            alignSelf="flex-start"
                        >
                            {t('accountDetails', { ns: 'account' })}
                        </Heading>

                        <SimpleGrid
                            columns={2}
                            spacing={2}
                            w="100%"
                        >
                            <FormikInput
                                name="firstName"
                                placeholder={t('fieldPlaceholder.firstName', { ns: 'common' })}
                                type="text"
                            />

                            <FormikInput
                                name="lastName"
                                placeholder={t('fieldPlaceholder.lastName', { ns: 'common' })}
                                type="text"
                            />
                        </SimpleGrid>

                        <FormikInput
                            name="email"
                            placeholder={t('fieldPlaceholder.email', { ns: 'common' })}
                            type="email"
                        />

                        <SimpleGrid
                            columns={2}
                            spacing={2}
                            w="100%"
                        >
                            <FormikPassword
                                name="password"
                                placeholder={t('fieldPlaceholder.password', { ns: 'common' })}
                            />

                            <FormikPassword
                                name="confirmPassword"
                                placeholder={t('fieldPlaceholder.confirmPassword', { ns: 'common' })}
                            />
                        </SimpleGrid>

                        <SimpleGrid
                            w="100%"
                            columns={2}
                            spacing={2}
                        >
                            <FormikCountrySelect
                                name="dialCode"
                                selectDialCode
                            />

                            <FormikInput
                                name="mobile"
                                placeholder={t('fieldPlaceholder.phoneNumber', { ns: 'common' })}
                            />
                        </SimpleGrid>

                        <FormikCheckbox
                            name="termsAndConditions"
                            label={<TsAndCsText register />}
                        />

                        <Box
                            display="flex"
                            justifyContent="flex-end"
                            w="100%"
                        >
                            <Button
                                type="submit"
                                variant="primaryYellow"
                                width="15rem"
                            >
                                {t('button.register', { ns: 'common' })}
                            </Button>
                        </Box>
                    </VStack>
                </FormikForm>
            </VStack>
        </RegisterFormsContainer>
    );
};

export default RegisterPersonalForm;
