import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { DirectEnquiryDataType } from './types';
import { FormikForm } from '../../common/forms';
import EnquiryFormBox from '../create-client-enquiry/common/EnquiryFormBox';
import Properties from '../create-client-enquiry/enquiry-property-info/property-forms/Properties';
import { FlowContextStateful } from '../../common/components/display/ProcessFlowStateful';
import { DIRECT_ENQUIRY_STEPS, DIRECT_ENQUIRY_STEPS_PROPERTY_DETAILS } from './DirectEnquirySteps';
import EnquiryContainer from '../../common/components/display/enquiry-form/display/EnquiryContainer';

type PropTypes = {
    onContinue: (values: any, goNext: () => void) => void;
    enquiryData: DirectEnquiryDataType;
};

const validationSchema = Yup.object({
    properties: Yup.array().min(1, 'errors.required'),
});

const DirectPropertyDescription = (props: PropTypes) => {
    const {
        onContinue,
        enquiryData,
    } = props;

    const { t } = useTranslation('enquiryform');

    const { goBack, goNext } = useContext(FlowContextStateful);

    const steps = [...DIRECT_ENQUIRY_STEPS];

    return (
        <EnquiryContainer
            currentStep={DIRECT_ENQUIRY_STEPS_PROPERTY_DETAILS}
            steps={steps}
        >
            <FormikForm
                validationSchema={validationSchema}
                initialValues={{
                    properties: enquiryData.properties || [],
                }}
                onSubmit={values => onContinue(values, goNext)}
            >
                <EnquiryFormBox
                    onBack={goBack}
                    content={[
                        {
                            key: 'propertyDetails',
                            header: t('newEnquiry.propertyDetailsSection.title'),
                            subHeader: t('newEnquiry.propertyDetailsSection.subTitle'),
                            element: <Properties />,
                        },
                    ]}
                />
            </FormikForm>
        </EnquiryContainer>
    );
};

export default DirectPropertyDescription;
