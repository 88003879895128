/* eslint-disable func-names */
import {
    createStandaloneToast,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import {
    FormikForm,
} from '../../../common/forms';
import { useEnquiry } from '../../../utils/api/hooks';
import LoadingSpinner from '../../../common/components/LoadingSpinner';
import useEnquirySteps from '../common/hooks/useEnquirySteps';
import { useRole } from '../../../common/hooks';
import EnquiryFormBox from '../common/EnquiryFormBox';
import Valuers from './valuer-forms/Valuers';
import Qualifications from './valuer-forms/Qualifications';
import Requirements from './valuer-forms/Requirements';
import AdditionalRequirements from './valuer-forms/AdditionalRequirements';

function valuerPanelValidation(values: { toEntityIds: number[], allowFullMatchmaking: string }) {
    const { toEntityIds, allowFullMatchmaking } = values;

    const valuersSelected = toEntityIds.length !== 0;
    const matchmakingSelected = allowFullMatchmaking === 'true';

    return valuersSelected || matchmakingSelected;
}

const validationSchema = Yup.object({
    toEntityIds: Yup.array().test('valuers', 'errors.selectOneValuer', function () {
        return valuerPanelValidation(this.parent);
    }),
    valuerExperience: Yup.string()
        .required('errors.selectOption'),
    valuerMinimumPartners: Yup.string()
        .required('errors.selectOption'),
    valuerMaxOfficeDistance: Yup.string()
        .required('errors.selectOption'),
    allowFullMatchmaking: Yup.string().test('matchmaking', 'errors.selectOption', function () {
        return valuerPanelValidation(this.parent);
    }),
});

const EnquiryValuerInfo = () => {
    const { t } = useTranslation(['enquiryform', 'formik', 'common']);
    const { onContinue, onBack } = useEnquirySteps();
    const { enquiryId = '' } = useParams();
    const { toast } = createStandaloneToast();

    const { userIsClient, userIsValuer } = useRole();

    const { enquiry, isLoading: isEnquiryLoading, update: updateEnquiry } = useEnquiry({
        enquiryId,
        onUpdateSuccess: () => onContinue(),
        onUpdateError: error => toast({
            title: t('error.error', { ns: 'common' }),
            description: error.message,
            status: 'error',
            duration: 5000,
            isClosable: true,
        }),
    });

    if (isEnquiryLoading || !enquiry) {
        return <LoadingSpinner />;
    }

    const {
        toEntityIds = [],
        valuerQualifications = [],
        valuerQualificationsOther = '',
        valuerExperience = '0',
        valuerMinimumPartners = '0',
        valuerMaxOfficeDistance = 'none',
        additionalRequirements = '',
        allowFullMatchmaking: allowFullMatchmakingBoolean = true,
        valuerMinimumPICoverPercentage = 0,
    } = enquiry;

    const allowFullMatchmaking = allowFullMatchmakingBoolean.toString();

    return (
        <FormikForm
            validationSchema={validationSchema}
            initialValues={{
                toEntityIds,
                valuerQualifications,
                valuerQualificationsOther,
                valuerExperience,
                valuerMinimumPartners,
                valuerMaxOfficeDistance,
                valuerMinimumPICoverPercentage,
                additionalRequirements,
                allowFullMatchmaking,
            }}
            onSubmit={values => {
                const data = {
                    ...values,
                    allowFullMatchmaking: userIsValuer ? false : values.allowFullMatchmaking === 'true',
                    id: Number(enquiryId),
                };

                updateEnquiry(data);
            }}
        >
            <EnquiryFormBox
                onBack={onBack}
                content={[
                    {
                        key: 'valuers',
                        header: t('newEnquiry.valuerSection.valuers'),
                        subHeader: t('newEnquiry.valuerSection.selectValuers'),
                        element: (
                            userIsClient && (
                                <Valuers />
                            )
                        ),
                    },
                    {
                        key: 'qualifications',
                        header: t('newEnquiry.valuerSection.qualifications.sectionTitle'),
                        element: (
                            <Qualifications />
                        ),
                    },
                    {
                        key: 'requirements',
                        header: t('newEnquiry.valuerSection.requirements.sectionTitle'),
                        element: (
                            <Requirements />
                        ),
                    },
                    {
                        key: 'additionalRequirements',
                        header: t('newEnquiry.valuerSection.requirements.additional.sectionTitle'),
                        subHeader: t('newEnquiry.valuerSection.requirements.additional.instruction'),
                        element: (
                            <AdditionalRequirements />
                        ),
                    },
                ]}
            />
        </FormikForm>
    );
};

export default EnquiryValuerInfo;
