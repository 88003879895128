import {
    HStack,
    Link,
    Text,
    VStack,
} from '@chakra-ui/react';
import { Folder } from 'iconsax-react';
import { useTranslation } from 'react-i18next';
import PropertyOverview from '../../../PropertyOverview';
import AccordionContainer from '../../../AccordionContainer';
import EnquiryOverviewSectionContainer from '../display/EnquiryOverviewSectionContainer';
import { DirectEnquiryDataType } from '../../../../../views/direct-enquiry-form/types';
import { FormikTextarea } from '../../../../forms';

type PropTypes = {
    overviewData: DirectEnquiryDataType;
    valuerName: string;
    individualVarient?: boolean;
};

const DirectGeneralEnquiryOverview = (props: PropTypes) => {
    const { overviewData, valuerName, individualVarient } = props;

    const {
        valuationType = '',
        valuationSpecial = [],
        valuationOther = '',
        retypeDocuments = [],
        valuationStandards = '',
        valuationStandardsOther = '',
        valuationPurposes = [],
        valuationPurposesOther = '',
        completionDate = '',
        properties = [],
        invoiceFName = '',
        invoiceLName = '',
        invoiceEmail = '',
        invoiceMobileDialCode = '',
        invoiceMobile = '',
    } = overviewData;

    const { t } = useTranslation(['enquiryform', 'common', 'enquiries', 'formik']);

    return (
        <EnquiryOverviewSectionContainer
            variant="white"
            content={[
                {
                    header: t('newEnquiry.overview.valuation.requestedTitle'),
                    tableData: [
                        {
                            title: t('newEnquiry.overview.valuer.sectionTitle'),
                            data:
                                (
                                    <Text>
                                        {valuerName}
                                    </Text>
                                ),
                        },
                        {
                            title: t('newEnquiry.overview.valuation.type'),
                            data: (
                                <div>
                                    {valuationType === 'Other Valuation' && (
                                        <VStack align="left">
                                            <Text>
                                                {t(valuationType, { ns: 'formik', defaultValue: `${t('noRequirement')}` })}
                                            </Text>

                                            {valuationSpecial.map(special => (
                                                <Text
                                                    key={special}
                                                    pl="1rem"
                                                >
                                                    {t(special, { ns: 'formik', defaultValue: special })}
                                                </Text>
                                            ))}

                                            <Text pl="1rem">
                                                {valuationOther}
                                            </Text>
                                        </VStack>
                                    )}

                                    {valuationType === 'Retype Valuation' && (
                                        <VStack align="left">
                                            <Text>
                                                {t(valuationType, { ns: 'formik', defaultValue: `${t('noRequirement')}` })}
                                            </Text>

                                            {retypeDocuments.map(document => (
                                                <Link
                                                    href={document.url}
                                                    isExternal
                                                    key={document.id}
                                                    color="wvwGreen"
                                                    pl="1rem"
                                                >
                                                    <HStack>
                                                        <Folder size="1rem" />

                                                        <Text>
                                                            {document.name}
                                                        </Text>
                                                    </HStack>
                                                </Link>
                                            ))}
                                        </VStack>
                                    )}

                                    {valuationType !== ('Retype Valuation' && 'Special Valuation' && 'Other Valuation') && (
                                        <Text>
                                            {t(valuationType, { ns: 'formik', defaultValue: `${t('noRequirement')}` })}
                                        </Text>
                                    )}
                                </div>
                            ),
                        },
                        {
                            title: !individualVarient ? t('newEnquiry.overview.valuation.standards') : undefined,
                            data: (
                                <VStack align="left">
                                    {valuationStandards === 'Other' ? (
                                        <Text>
                                            {valuationStandardsOther}
                                        </Text>
                                    ) : (
                                        <Text>
                                            {t(valuationStandards, { ns: 'formik', defaultValue: `${t('noRequirement')}` })}
                                        </Text>
                                    )}
                                </VStack>
                            ),
                        },
                        {
                            title: t('newEnquiry.overview.valuation.purpose'),
                            data: (
                                <VStack align="left">
                                    {valuationPurposes.map(purpose => (
                                        <HStack key={purpose}>
                                            <Text>
                                                {t(purpose, { ns: 'formik', defaultValue: purpose })}
                                            </Text>
                                        </HStack>
                                    ))}

                                    {valuationPurposes.includes('Other') && (
                                        <Text pl="1rem">
                                            {valuationPurposesOther}
                                        </Text>
                                    )}
                                </VStack>
                            ),
                        },
                        {
                            title: t('newEnquiry.overview.completionExpectation'),
                            data: (
                                <Text>
                                    {completionDate}
                                </Text>
                            ),
                        },
                    ],
                },
                {
                    header: t('newEnquiry.overview.property.detailsSectionTitle'),
                    tableData: [
                        {
                            title: 'properties',
                            data:
                                (
                                    <VStack width="100%">
                                        {properties.map(property => (
                                            <AccordionContainer
                                                title={`${property.street}, ${property.city}`}
                                                key={`${property.street} ${property.city}`}
                                            >
                                                <PropertyOverview property={property} />
                                            </AccordionContainer>
                                        ))}
                                    </VStack>
                                ),
                        },
                    ],
                },
                {
                    header: t('newEnquiry.invoiceSection.contactDetails.sectionTitle'),
                    tableData: [
                        {
                            title: t('newEnquiry.overview.contact.fullName'),
                            data: (
                                <Text>
                                    {`${invoiceFName} ${invoiceLName}`}
                                </Text>
                            ),
                        },
                        {
                            title: t('newEnquiry.overview.contact.email'),
                            data: (
                                <Text>
                                    {invoiceEmail}
                                </Text>
                            ),
                        },
                        {
                            title: t('newEnquiry.overview.contact.mobileNumber'),
                            data: (
                                <Text>
                                    {`${invoiceMobileDialCode} ${invoiceMobile}`}
                                </Text>
                            ),
                        },
                    ],
                },
                {
                    header: t('newEnquiry.additionalComments'),
                    tableData: [
                        {
                            title: 'additionalComments',
                            data:
                                (
                                    <FormikTextarea
                                        name="additionalRequirements"
                                    />
                                ),
                        },
                    ],
                },
            ]}
        />
    );
};

DirectGeneralEnquiryOverview.defaultProps = {
    individualVarient: false,
};

export default DirectGeneralEnquiryOverview;
