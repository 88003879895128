import { VStack } from '@chakra-ui/react';
import { WVWTitle } from '../../../../common/components/typography';
import { FormikRadioGroup, FormikConditionalSection } from '../../../../common/forms';
import { useSmartTranslation } from '../../../../common/hooks';
import ProfileOperationalAreaEdit from './ProfileOperationalAreaEdit';
import FormSection from './FormSection';

type PropTypes = {
    updateDoesValuations?: (value: string) => void;
};

const ProfileOtherDetailsEdit = (props: PropTypes) => {
    const t = useSmartTranslation('profile');

    const { updateDoesValuations } = props;

    return (
        <FormSection
            header={t('profileDetails.otherInfo')}
            variant="white"
            element={(
                <VStack
                    width="100%"
                    align="left"
                >
                    <WVWTitle
                        level="2"
                        color="black"
                        content={t('doesValuations', { ns: 'profile' })}
                    />

                    <FormikRadioGroup
                        name="doesValuations"
                        width="10rem"
                        onChange={updateDoesValuations}
                        options={[
                            {
                                label: t('yes', { ns: 'common' }),
                                value: 'true',
                            },
                            {
                                label: t('no', { ns: 'common' }),
                                value: 'false',
                            },
                        ]}
                    />

                    <FormikConditionalSection
                        formDataTarget="doesValuations"
                        condition={value => value === 'true'}
                    >
                        <ProfileOperationalAreaEdit />
                    </FormikConditionalSection>
                </VStack>
            )}
        />
    );
};

ProfileOtherDetailsEdit.defaultProps = {
    updateDoesValuations: undefined,
};

export default ProfileOtherDetailsEdit;
