import * as Yup from 'yup';

/**
 * Password validation schema
 */
export const passwordValidation = Yup.string()
    .min(8, 'errors.tooShort')
    .minLowercase(1, 'errors.minLowercase')
    .minUppercase(1, 'errors.minUppercase')
    .minNumbers(1, 'errors.minNumbers')
    .minSymbols(1, 'errors.minSymbols')
    .required('errors.required');

/**
 * Confirm password validation schema
 */
export const confirmPasswordValidation = Yup.string()
    .oneOf([Yup.ref('password'), null], 'errors.passwordMustMatch')
    .required('errors.required');
