import { useContext } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { FormikForm } from '../../common/forms';
import { DirectEnquiryDataType } from './types';
import EnquiryFormBox from '../create-client-enquiry/common/EnquiryFormBox';
import EnquiryContactForm from '../create-client-enquiry/enquiry-contact-info/contact-forms/EnquiryContactForm';
import { FlowContextStateful } from '../../common/components/display/ProcessFlowStateful';
import EnquiryContainer from '../../common/components/display/enquiry-form/display/EnquiryContainer';
import { DIRECT_ENQUIRY_STEPS, DIRECT_ENQUIRY_STEPS_CONTACT } from './DirectEnquirySteps';

const validationSchema = Yup.object({
    invoiceFName: Yup.string()
        .required('errors.required'),
    invoiceLName: Yup.string()
        .required('errors.required'),
    invoiceEmail: Yup.string()
        .email('Invalid email address')
        .required('errors.required'),
    invoiceEmailCc: Yup.string()
        .email('Invalid email address'),
    invoiceMobileDialCode: Yup.string()
        .required('errors.required'),
    invoiceMobile: Yup.number()
        .required('errors.required').typeError('errors.enterNumber'),
});

type PropTypes = {
    onContinue: (values: any, goNext: () => void) => void;
    enquiryData: DirectEnquiryDataType;
};

const DirectEnquiryRegistration = (props: PropTypes) => {
    const { t } = useTranslation(['enquiryform', 'common', 'formik']);

    const {
        onContinue,
        enquiryData,
    } = props;

    const { goBack, goNext } = useContext(FlowContextStateful);

    return (
        <EnquiryContainer
            currentStep={DIRECT_ENQUIRY_STEPS_CONTACT}
            steps={DIRECT_ENQUIRY_STEPS}
        >
            <FormikForm
                validationSchema={validationSchema}
                initialValues={{
                    invoiceFName: enquiryData.invoiceFName || '',
                    invoiceLName: enquiryData.invoiceLName || '',
                    invoiceEmail: enquiryData.invoiceEmail || '',
                    invoiceEmailCc: enquiryData.invoiceEmailCc || '',
                    invoiceMobileDialCode: enquiryData.invoiceMobileDialCode || '',
                    invoiceMobile: enquiryData.invoiceMobile || '',
                }}
                onSubmit={values => onContinue(values, goNext)}
            >
                <EnquiryFormBox
                    onBack={goBack}
                    content={[
                        {
                            key: 'contactInfo',
                            header: t('newEnquiry.invoiceSection.contactDetails.sectionTitle'),
                            subHeader: t('newEnquiry.invoiceSection.contactDetails.instruction'),
                            element: <EnquiryContactForm />,
                        },
                    ]}
                />
            </FormikForm>
        </EnquiryContainer>
    );
};

export default DirectEnquiryRegistration;
