/* eslint-disable react/no-this-in-sfc */
import { VStack } from '@chakra-ui/react';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ProfileContactDetailsEdit from '../../../profile/components/ProfileContactDetailsEdit';
import { FlowContext } from '../../../../../common/components/display/ProcessFlowRouted';
import { FormikForm } from '../../../../../common/forms';
import LoadingSpinner from '../../../../../common/components/LoadingSpinner';
import { useMe } from '../../../../../utils/api/hooks';
import ProfileCompletionFormFooter from './ProfileCompletionFormFooter';
import ProfileOfficeDetailsEdit from '../../../profile/components/ProfileOfficeDetailsEdit';
import { useRole } from '../../../../../common/hooks';
import { ProfileCompletionContext } from '../ProfileCompletionWizard';
import ProfileOtherDetailsEdit from '../../../profile/components/ProfileOtherDetailsEdit';

const ProfileCompletionContactDetails = () => {
    const { goNext } = useContext(FlowContext);

    const { updateDoesValuations } = useContext(ProfileCompletionContext);

    const [saveAndClose, setSaveAndClose] = useState(false);

    const navigate = useNavigate();

    const { userIsValuer, userIsCompanyAdmin, roleLoading } = useRole();

    const {
        data: user,
        isLoading,
        updateMe,
    } = useMe({
        onUpdateSuccess: () => {
            if (saveAndClose) {
                navigate('/dashboard', { replace: true });
            } else {
                goNext();
            }
        },
    });

    if (isLoading || roleLoading) {
        return <LoadingSpinner />;
    }

    const initalFormData = {
        firstName: user.firstName || '',
        lastName: user.lastName || '',
        orgPosition: user.orgPosition || '',
        email: user.email || '',
        mobileDialCode: user.mobileDialCode || '',
        mobile: user.mobile || '',
        officeTelDialCode: user.officeTelDialCode || '',
        officeTel: user.officeTel || '',
        officeFaxDialCode: user.officeFaxDialCode || '',
        officeFax: user.officeFax || '',
        officeStreet: user.street || '',
        officePostalCode: user.postalCode || '',
        officeCity: user.city || '',
        officeCountry: user.country || '',
        officeLatitude: user.latitude || '',
        officeLongitude: user.longitude || '',
        doesValuations: user.doesValuations === true ? 'true' : 'false',
        maxOperatingDistance: user.maxOperatingDistance || '',
    };

    const update = (values: any) => {
        const maxOperatingDistance = parseInt(values.maxOperatingDistance, 10);

        updateMe({
            firstName: values.firstName,
            lastName: values.lastName,
            orgPosition: values.orgPosition,
            email: values.email,
            mobileDialCode: values.mobileDialCode,
            mobile: values.mobile,
            officeTelDialCode: values.officeTelDialCode,
            officeTel: values.officeTel,
            officeFaxDialCode: values.officeFaxDialCode,
            officeFax: values.officeFax,
            street: values.officeStreet,
            postalCode: values.officePostalCode,
            city: values.officeCity,
            country: values.officeCountry,
            latitude: values.officeLatitude,
            longitude: values.officeLongitude,
            doesValuations: values.doesValuations === 'true',
            maxOperatingDistance,
        });
    };

    return (
        <FormikForm
            initialValues={initalFormData}
            onSubmit={values => {
                update(values);
            }}
        >
            <VStack
                spacing={4}
            >
                <ProfileContactDetailsEdit
                    minimumDetails
                />

                <ProfileOfficeDetailsEdit
                    userIsValuer={userIsValuer}
                    userIsCompanyAdmin={userIsCompanyAdmin}
                    streetName="officeStreet"
                    postalCodeName="officePostalCode"
                    cityName="officeCity"
                    countryName="officeCountry"
                    latName="officeLatitude"
                    lngName="officeLongitude"
                    officeTelDialCodeName="officeTelDialCode"
                    officeTelName="officeTel"
                    officeFaxDialCodeName="officeFaxDialCode"
                    officeFaxName="officeFax"
                />

                {userIsValuer && (
                    <ProfileOtherDetailsEdit
                        updateDoesValuations={updateDoesValuations}
                    />
                )}

                <ProfileCompletionFormFooter
                    handleSaveAndClose={() => setSaveAndClose(true)}
                />
            </VStack>
        </FormikForm>
    );
};

ProfileCompletionContactDetails.defaultProps = {
    updateDoesValuations: undefined,
};

export default ProfileCompletionContactDetails;
