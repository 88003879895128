import { VStack, Text } from '@chakra-ui/react';
import { FormikCheckboxGroup, FormikConditionalSection, FormikTextarea } from '../../../../common/forms';
import { standardValuerQualifications } from '../../../../common/vars/valuationsAndMembershipTypes';
import { useSmartTranslation } from '../../../../common/hooks';

const VALUER_QUALIFICATIONS = [
    ...standardValuerQualifications,
    'Other',
];

const Qualifications = () => {
    const t = useSmartTranslation('enquiryform');

    return (
        <>
            <FormikCheckboxGroup
                variant="outline"
                name="valuerQualifications"
                options={VALUER_QUALIFICATIONS.map(valuerQualification => ({
                    label: t(valuerQualification, { ns: 'formik', defaultValue: valuerQualification }),
                    value: valuerQualification,
                }))}
            />

            <FormikConditionalSection
                formDataTarget="valuerQualifications"
                condition={value => value.includes('Other')}
            >
                <VStack
                    align="start"
                    backgroundColor="wvwGrey05"
                    borderRadius="8"
                    padding="1rem"
                    spacing="4"
                    w="100%"
                >
                    <Text
                        color="wvwGreen"
                    >
                        {t('newEnquiry.valuerSection.qualifications.other.instruction')}
                    </Text>

                    <FormikTextarea
                        name="valuerQualificationsOther"
                    />
                </VStack>
            </FormikConditionalSection>
        </>

    );
};

export default Qualifications;
