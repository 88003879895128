/* eslint-disable max-len */
import { useField, useFormikContext } from 'formik';
import { useContext } from 'react';
import { Text } from '@chakra-ui/react';
import { useSmartTranslation } from '../hooks';
import MapsAutocomplete from '../components/inputs/MapsAutocomplete';
import { LabelAndInput } from '../components/display';
import { FormikValidationContext } from './FormikForm';

type PropsType = {
    cityName: string;
    countryName: string;
    streetName: string;
    postalCodeName: string;
    label?: string;
    latName: string;
    lngName: string;
    placeholder?: string;
};

const FormikMapsAutocomplete = (props: PropsType) => {
    const t = useSmartTranslation('formik');

    const {
        cityName,
        countryName,
        streetName,
        postalCodeName,
        label,
        latName,
        lngName,
        placeholder,
    } = props;

    const { setFieldValue } = useFormikContext();

    const [streetField] = useField(streetName);
    const [postalCodeField] = useField(postalCodeName);
    const [cityField] = useField(cityName);
    const [countryField] = useField(countryName);
    const [latField, latMeta] = useField(latName);
    const [lngField] = useField(lngName);

    const isFieldRequired = useContext(FormikValidationContext);

    const isStreetRequired = isFieldRequired(streetName);
    const isPostalCodeRequired = isFieldRequired(postalCodeName);
    const isCityRequired = isFieldRequired(cityName);
    const isCountryRequired = isFieldRequired(countryName);
    const isLatRequired = isFieldRequired(latName);

    const isRequired = isStreetRequired || isPostalCodeRequired || isCityRequired || isCountryRequired || isLatRequired;
    const allValuesFilled = streetField.value && postalCodeField.value && cityField.value && countryField.value && latField.value;

    return (
        <LabelAndInput
            label={label && `${label}${isRequired ? ' *' : ''}`}
            input={(
                <>
                    <MapsAutocomplete
                        values={{
                            street: streetField.value,
                            postalCode: postalCodeField.value,
                            city: cityField.value,
                            country: countryField.value,
                            lat: latField.value,
                            lng: lngField.value,
                        }}
                        onChange={values => {
                            setFieldValue(streetName, values.street);
                            setFieldValue(postalCodeName, values.postalCode);
                            setFieldValue(cityName, values.city);
                            setFieldValue(countryName, values.country);
                            setFieldValue(latName, values.lat);
                            setFieldValue(lngName, values.lng);
                        }}
                        placeholder={placeholder}
                    />

                    {(latMeta.error && !allValuesFilled)
                        && (
                            <Text color="red">
                                {t(latMeta.error, { defaultValue: latMeta.error })}
                            </Text>
                        )}
                </>
            )}
        />
    );
};

FormikMapsAutocomplete.defaultProps = {
    placeholder: undefined,
    label: undefined,
};

export default FormikMapsAutocomplete;
