import {
    HStack,
    VStack,
} from '@chakra-ui/react';
import CaseOverviewInfo from './CaseOverviewInfo';
import CaseAttentionInfo from './CaseAttentionInfo';
import CaseProgressInfo from './CaseProgressInfo';
import CaseCalendarInfo from './CaseCalendarInfo';

const CaseOverview = () => (
    <VStack
        align="start"
        width="100%"
        spacing="1rem"
    >
        <HStack
            alignItems="top"
            w="100%"
            spacing="1rem"
        >
            <CaseAttentionInfo />

            <CaseProgressInfo />
        </HStack>
        <CaseOverviewInfo />

        <CaseCalendarInfo />
    </VStack>
);

export default CaseOverview;
