import { ChevronDownIcon } from '@chakra-ui/icons';
import {
    Button,
    ButtonGroup,
    Divider,
    Flex,
    GridItem,
    HStack,
    Link as ChakraLink,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Spacer,
    Text,
    VStack,
    IconButton,
} from '@chakra-ui/react';
import { DocumentText } from 'iconsax-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as ReactLink } from 'react-router-dom';
import { DocumentRequestDocumentType, DocumentRequestType } from '../../../../../../../types';
import { FormikForm, FormikInput } from '../../../../../../../common/forms';
import DocumentComment from './DocumentComment';
import { useDocumentRequestUpdateDocument } from '../../../../../../../utils/api/hooks';
import { useRole } from '../../../../../../../common/hooks';
import ConfirmationModal from '../../../../../../../common/components/ConfirmationModal';
import StatusBadge from '../../../../../../../common/components/StatusBadge';
import { displayDateWithCurrentLocale } from '../../../../../../../common/functions/displayDateInLocale';

type PropTypes = {
    document: DocumentRequestDocumentType;
    request: DocumentRequestType;
};

const RequestDocumentsListItem = (props: PropTypes) => {
    const { document, request } = props;

    const {
        caseId = 0,
        createdAt,
        documentRequestId: requestId,
        id: documentId,
        name,
        responseComment,
        status,
        deleted,
    } = document;

    const { fromUserId } = request;

    const { t } = useTranslation('documentmanagement');
    const { userIsValuer } = useRole();

    const [editing, setEditing] = useState(false);
    const [deleting, setDeleting] = useState(false);

    const {
        update: updateDocument,
        busy: documentUpdating,
    } = useDocumentRequestUpdateDocument({
        caseId,
        requestId,
        documentId,
        onSuccess: () => {
            setEditing(false);
            setDeleting(false);
        },
    });

    return (
        <>
            <VStack
                align="left"
                alignContent="center"
                paddingBottom="0.5rem"
                paddingTop="1rem"
                spacing="0"
            >
                <Flex
                    alignItems="center"
                    paddingBottom="1.2rem"
                >
                    <HStack
                        spacing={2}
                        w="100%"
                    >
                        {editing ? (
                            <FormikForm
                                submitting={documentUpdating}
                                initialValues={{
                                    name,
                                }}
                                onSubmit={values => {
                                    updateDocument({
                                        documentId,
                                        documentRequestId: requestId,
                                        name: values.name,
                                    });
                                }}
                            >
                                <VStack
                                    align="left"
                                    spacing={3}
                                    paddingInline="1rem"
                                    w="100%"
                                >
                                    <FormikInput
                                        name="name"
                                        placeholder={t('renameFile', { ns: 'documentmanagement' })}
                                    />

                                    <ButtonGroup
                                        alignSelf="flex-end"
                                        paddingBottom=".8rem"
                                    >
                                        <Button
                                            onClick={() => setEditing(false)}
                                            variant="primary"
                                        >
                                            {t('button.cancel', { ns: 'common' })}
                                        </Button>

                                        <Button
                                            alignSelf="flex-end"
                                            type="submit"
                                            variant="primaryYellow"
                                        >
                                            {t('button.save', { ns: 'common' })}
                                        </Button>
                                    </ButtonGroup>
                                </VStack>
                            </FormikForm>
                        ) : (
                            <>
                                <ChakraLink
                                    as={ReactLink}
                                    to={`../document-preview/${documentId}`}
                                    color={deleted ? 'wvwGrey40' : 'wvwGreen'}
                                >
                                    <Button
                                        borderRadius="30"
                                        fontWeight="normal"
                                        variant="link"
                                        isDisabled={deleted}
                                    >
                                        <DocumentText />

                                        <Text
                                            fontWeight="500"
                                            pl=".5rem"
                                            overflow="hidden"
                                            textAlign="left"
                                            textOverflow="ellipsis"
                                            w="8rem"
                                            whiteSpace="nowrap"
                                        >
                                            {name}
                                        </Text>
                                    </Button>
                                </ChakraLink>

                                <Spacer />

                                <StatusBadge
                                    status={deleted ? 'DELETED' : status!}
                                />

                                <Spacer />

                                <Text>
                                    {displayDateWithCurrentLocale(createdAt)}
                                </Text>

                                <Spacer />

                            </>
                        )}

                        {(userIsValuer && !editing) && (
                            <GridItem
                                colStart={4}
                                justifyItems="flex-end"
                            >
                                <Menu>
                                    <MenuButton
                                        as={IconButton}
                                        borderRadius="30"
                                        icon={<ChevronDownIcon />}
                                        size="sm"
                                        variant="primary"
                                    />

                                    <MenuList>
                                        <ChakraLink
                                            as={ReactLink}
                                            color="wvwGreen"
                                            to={`../document-preview/${documentId}`}
                                        >
                                            <MenuItem>
                                                {t('button.viewDocument')}
                                            </MenuItem>
                                        </ChakraLink>

                                        {document.deleted === false && (
                                            <>
                                                <ChakraLink
                                                    as={ReactLink}
                                                    color="wvwGreen"
                                                    onClick={() => updateDocument({
                                                        documentId,
                                                        documentRequestId: requestId,
                                                        status: 'ACCEPTED',
                                                    })}
                                                >
                                                    <MenuItem>
                                                        {t('button.acceptDocument')}
                                                    </MenuItem>
                                                </ChakraLink>

                                                <ChakraLink
                                                    as={ReactLink}
                                                    color="wvwGreen"
                                                    onClick={() => updateDocument({
                                                        documentId,
                                                        documentRequestId: requestId,
                                                        status: 'REJECTED',
                                                    })}
                                                >
                                                    <MenuItem>
                                                        {t('button.rejectDocument')}
                                                    </MenuItem>
                                                </ChakraLink>

                                                <ChakraLink
                                                    as={ReactLink}
                                                    color="wvwGreen"
                                                    onClick={() => setEditing(true)}
                                                >
                                                    <MenuItem>
                                                        {t('button.renameDocument')}
                                                    </MenuItem>
                                                </ChakraLink>

                                                <ChakraLink
                                                    as={ReactLink}
                                                    color="wvwGreen"
                                                    onClick={() => setDeleting(true)}
                                                >
                                                    <MenuItem>
                                                        {t('button.deleteDocument')}
                                                    </MenuItem>
                                                </ChakraLink>
                                            </>
                                        )}
                                    </MenuList>
                                </Menu>
                            </GridItem>
                        )}
                    </HStack>
                </Flex>

                <Spacer />

                {(status === 'REJECTED' && !editing && responseComment) && (
                    <DocumentComment
                        fromId={fromUserId}
                        id={documentId}
                        message={responseComment}
                    />
                )}

                <Divider />
            </VStack>

            <ConfirmationModal
                cancelButton={t('button.cancel', { ns: 'common' })}
                cancelButtonVariant="primary"
                content={t('documentBody')}
                continueButton={t('button.delete', { ns: 'common' })}
                continueButtonVariant="danger"
                isOpen={deleting}
                title={t('deleteDocument')}
                onCancel={() => setDeleting(false)}
                onContinue={() => updateDocument({
                    documentRequestId: request.id,
                    documentId: document.id,
                    deleted: true,
                })}
            />
        </>
    );
};

export default RequestDocumentsListItem;
