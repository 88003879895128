import { ChevronDownIcon } from '@chakra-ui/icons';
import {
    Button,
    Link as ChakraLink,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Text,
    VStack,
    createStandaloneToast,
} from '@chakra-ui/react';
import { Eye, ProfileDelete, ProfileTick } from 'iconsax-react';
import { useTranslation } from 'react-i18next';
import { Link as ReactLink } from 'react-router-dom';
import {
    useAdminGetAllUsers,
    useAdminSetAccess,
    useAdminSetSystemAdmin,
} from '../../../utils/api/hooks';
import { UserType } from '../../../types';
import DataTable from '../../../common/components/datatable/DataTable';
import LoadingSpinner from '../../../common/components/LoadingSpinner';
import toTitleCase from '../../../common/functions/toTitleCase';
import { displayDateWithCurrentLocale } from '../../../common/functions/displayDateInLocale';
import PageWithTitleLayout from '../../../common/components/display/PageWithTitleLayout';

const AdminUsers = () => {
    const { toast } = createStandaloneToast();

    const { t } = useTranslation('common');

    const {
        data: users = [],
        isLoading,
        refresh,
    } = useAdminGetAllUsers();

    const {
        update: updateAccess,
    } = useAdminSetAccess({
        onSuccess: () => {
            refresh();
        },
        onError: error => {
            toast({
                title: t('error.error', { ns: 'common' }),
                description: error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        },
    });

    const {
        update: updateAdmin,
    } = useAdminSetSystemAdmin({
        onSuccess: () => { refresh(); },
        onError: error => {
            toast({
                title: t('error.error', { ns: 'common' }),
                description: error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        },
    });

    const headers = [
        {
            title: 'FB ID',
            maxWidth: '10rem',
            render: (i: UserType) => i.firebaseId,
            sorter: (a: UserType, b: UserType) => a.firebaseId.localeCompare(b.firebaseId),
        },
        {
            title: t('usersTable.header.user', { ns: 'dashboard' }),
            maxWidth: '10rem',
            render: (i: UserType) => {
                const name = `${i.firstName} ${i.lastName}` || 'No Name';

                return (
                    <Text color={name === 'No Name' ? 'wvwGrey20' : ''}>
                        {name}
                    </Text>
                );
            },
            sorter: (a: UserType, b: UserType) => {
                const aName = `${a.firstName} ${a.lastName}` || 'No Name';
                const bName = `${b.firstName} ${b.lastName}` || 'No Name';

                return aName.localeCompare(bName);
            },
        },
        {
            title: t('usersTable.header.userType', { ns: 'dashboard' }),
            maxWidth: '10rem',
            render: (i: UserType) => {
                const accountType = i?.entity?.accountType;
                const companyOrIndividual = i?.entity?.companyOrIndividual;

                if (!i.entity) {
                    return `[${t('removed', { ns: 'common' })}]`;
                }

                if (accountType === 'CLIENT') {
                    const userType = companyOrIndividual === 'INDIVIDUAL' ? t('privateClient') : t('institutionalClient');
                    return toTitleCase(userType);
                }

                return (t(`${accountType}`, { ns: 'common' }) || '');
            },
            sorter: (a: UserType, b: UserType) => {
                const accountTypeA = a?.entity?.accountType || '';
                const accountTypeB = b?.entity?.accountType || '';

                if (accountTypeA !== accountTypeB) {
                    return accountTypeA.localeCompare(accountTypeB);
                }

                const companyOrIndividualA = a?.entity?.companyOrIndividual || '';
                const companyOrIndividualB = b?.entity?.companyOrIndividual || '';

                return companyOrIndividualA.localeCompare(companyOrIndividualB);
            },
        },
        {
            title: t('usersTable.header.company', { ns: 'dashboard' }),
            maxWidth: '15rem',
            sorter: (a: UserType, b: UserType) => a?.entity?.name?.localeCompare(b?.entity?.name),
            render: (i: UserType) => {
                if (!i.entity) {
                    return `[${t('removed', { ns: 'common' })}]`;
                }

                return i?.entity?.name || 'No Company';
            },
        },
        {
            title: t('usersTable.header.email', { ns: 'dashboard' }),
            maxWidth: '12rem',
            sorter: (a: UserType, b: UserType) => a.email.localeCompare(b.email),
            render: (i: UserType) => (
                <Text color={i.email === 'No Email' ? 'wvwGrey20' : ''}>
                    {i.email}
                </Text>
            ),
        },
        {
            title: t('usersTable.header.admin', { ns: 'dashboard' }),
            maxWidth: '10rem',
            sorter: (a: UserType, b: UserType) => Number(a.systemAdmin) - Number(b.systemAdmin),
            render: (i: UserType) => {
                let color = 'red';
                let bgColor = 'red10';

                if (i.systemAdmin) {
                    color = 'green';
                    bgColor = 'green10';
                }

                return (
                    <Text
                        bg={bgColor}
                        color={color}
                        paddingInline=".5rem"
                        paddingBlock=".2rem"
                        fontWeight="bold"
                        w="6rem"
                        borderRadius="10"
                        fontSize=".8rem"
                        textAlign="center"
                    >
                        {i.systemAdmin ? 'Yes' : 'No'}
                    </Text>
                );
            },
        },
        {
            title: t('usersTable.header.systemAccess', { ns: 'dashboard' }),
            maxWidth: '10rem',
            sorter: (a: UserType, b: UserType) => Number(a.systemAccess) - Number(b.systemAccess),
            render: (i: UserType) => {
                let color = 'red';
                let bgColor = 'red10';

                if (i.systemAccess) {
                    color = 'green';
                    bgColor = 'green10';
                }

                return (
                    <Text
                        bg={bgColor}
                        color={color}
                        paddingInline=".5rem"
                        paddingBlock=".2rem"
                        fontWeight="bold"
                        w="6rem"
                        borderRadius="10"
                        fontSize=".8rem"
                        textAlign="center"
                    >
                        {i.systemAccess ? 'Yes' : 'No'}
                    </Text>
                );
            },
        },
        {
            title: t('usersTable.header.joiningDate', { ns: 'dashboard' }),
            maxWidth: '10rem',
            sorter: (a: UserType, b: UserType) => a.createdAt.localeCompare(b.createdAt),
            render: (i: UserType) => (
                <Text>
                    {displayDateWithCurrentLocale(i.createdAt)}
                </Text>
            ),
        },
        {
            maxWidth: '10rem',
            render: (i: UserType) => (
                <VStack
                    align="center"
                    w="100%"
                >
                    <Menu>
                        <MenuButton
                            as={Button}
                            variant="primary"
                            borderRadius="30"
                            size="sm"
                            rightIcon={<ChevronDownIcon />}
                        >
                            Actions
                        </MenuButton>

                        <MenuList>
                            <ChakraLink
                                as={ReactLink}
                                to={`/admin/users/${i.id}`}
                            >
                                <MenuItem
                                    icon={<Eye size="1.2rem" />}
                                >
                                    View User
                                </MenuItem>
                            </ChakraLink>

                            <MenuItem
                                icon={i.systemAccess === true ? <ProfileTick size="1.2rem" /> : <ProfileDelete size="1.2rem" />}
                                onClick={() => updateAccess({
                                    targetUserId: i.id, access: !i.systemAccess,
                                })}
                            >
                                {i.systemAccess !== true ? 'Enable' : 'Disable'}
                            </MenuItem>

                            <MenuItem
                                icon={i.systemAdmin ? <ProfileDelete size="1.2rem" /> : <ProfileTick size="1.2rem" />}
                                onClick={() => updateAdmin({
                                    targetUserId: i.id, access: !i.systemAdmin,
                                })}
                            >
                                {i.systemAdmin ? 'Remove Admin' : 'Make Admin'}
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </VStack>
            ),
        },
    ];

    if (isLoading) return <LoadingSpinner />;

    const userFilters = [
        {
            filterName: 'User Type',
            placeholder: 'User Type',
            options: [
                { label: t('filters.users.userType.institutionalClient', { ns: 'admin' }), value: 'CLIENT' },
                { label: t('filters.users.userType.valuer', { ns: 'admin' }), value: 'VALUER' },
            ],
            filterFunction:
                (i: UserType, filterValue?: string) => i.entity?.accountType === filterValue,
        },
        {
            filterName: 'Admin',
            placeholder: 'Admin Access',
            options: [{ label: 'Yes', value: 'true' }, { label: 'No', value: 'false' }],
            filterFunction:
                (i: UserType, filterValue?: string) => i.systemAdmin.toString() === filterValue,
        },
        {
            filterName: 'System Access',
            placeholder: 'Syster Access',
            options: [{ label: 'Yes', value: 'true' }, { label: 'No', value: 'false' }],
            filterFunction:
                (i: UserType, filterValue?: string) => i.systemAccess.toString() === filterValue,
        },
    ];

    return (
        <PageWithTitleLayout title="Admin Users">
            <DataTable
                columns={headers}
                data={users}
                filters={userFilters}
            />
        </PageWithTitleLayout>
    );
};

export default AdminUsers;
