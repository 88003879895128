import { Box, Divider, VStack } from '@chakra-ui/react';
import { useState } from 'react';
import { useFormikContext } from 'formik';
import { FormikCloudSelect, FormikConditionalSection, FormikListInput } from '../../../../common/forms';
import { useSmartTranslation } from '../../../../common/hooks';
import { standardValuerMemberships } from '../../../../common/vars/valuationsAndMembershipTypes';
import FormSectionProfileEdit from './FormSectionProfileEdit';
import { useMe } from '../../../../utils/api/hooks';
import LoadingSpinner from '../../../../common/components/LoadingSpinner';
import { WvwText, WVWTitle } from '../../../../common/components/typography';
import UploadCertificate from '../../../../common/components/UploadCertificate';
import storage from '../../../../utils/storage/storage';
import { wvwPalette } from '../../../../theme';

const MEMBERSHIPS = [
    ...standardValuerMemberships,
    'Other',
];

const ProfileValuationStandardsEdit = () => {
    const t = useSmartTranslation('profile');

    const { values } = useFormikContext();

    const [loadingUploads, setLoadingUploads] = useState<{ [index: string]: boolean }>({});

    const {
        data: user,
        myCertificates = [],
        myCertificatesIsLoading,
        myCertificatesRefresh,
    } = useMe();

    if (myCertificatesIsLoading) return <LoadingSpinner />;

    const { id: userId } = user;

    const {
        standardMemberships: formDataStandardMemberships = [],
        otherMemberships = [],
    } = values as any;

    const standardMemberships = formDataStandardMemberships
        .filter((i: string) => standardValuerMemberships.includes(i));

    const selectedMemberships = [
        ...otherMemberships,
        ...standardMemberships,
    ];

    return (
        <FormSectionProfileEdit
            header={t('profileCompletionWizard.steps.qualifications')}
            subHeader={t('subHeading.membershipsAndQualifications')}
        >
            <FormikCloudSelect
                name="standardMemberships"
                options={MEMBERSHIPS.map(membership => ({
                    label: t(membership, { ns: 'formik', defaultValue: membership }),
                    value: membership,
                }))}
            />

            <FormikConditionalSection
                formDataTarget="standardMemberships"
                condition={value => value.includes('Other')}
            >
                <VStack
                    align="start"
                    backgroundColor={wvwPalette.wvwBackground.main}
                    borderRadius="8"
                    padding="1rem"
                    spacing="4"
                    w="100%"
                >
                    <Box>
                        <WVWTitle
                            level="3"
                            color="black"
                            content={t('heading.otherValuationStandards')}
                        />

                        <WvwText>
                            {t('subHeading.otherValuationStandards')}
                        </WvwText>
                    </Box>

                    <FormikListInput
                        name="otherMemberships"
                        placeholder={t('membershipsAndQualifications.edit.addValuationStandard')}
                    />
                </VStack>
            </FormikConditionalSection>

            <Divider />

            {selectedMemberships.map(membership => {
                const files = myCertificates
                    .filter(
                        i => i.type.toLowerCase() === membership.toLowerCase(),
                    )
                    .map(i => ({
                        name: i.label,
                        fileUrl: i.file.fullPath,
                    }));

                const isStandardMembership = standardValuerMemberships.includes(membership);

                const certificatesTranslation = t('membershipsAndQualifications.certificate', { count: 2 });
                const certificateTranslation = t('membershipsAndQualifications.certificate', { count: 1 });

                const membershipName = isStandardMembership
                    ? t(membership, { ns: 'formik', defaultValue: membership })
                    : membership;

                const title = `${membershipName} ${certificatesTranslation}`;
                const defaultFileName = files.length === 0 ? `${membershipName} ${certificateTranslation}` : undefined;

                return (
                    <VStack
                        key={membership}
                        align="start"
                        spacing="4"
                        w="100%"
                    >
                        <WVWTitle
                            level="2"
                            color="black"
                            content={title}
                        />

                        <UploadCertificate
                            defaultFileName={defaultFileName}
                            files={files}
                            loading={loadingUploads[membership]}
                            onFileDelete={fileUrl => {
                                storage.deleteUserFile(fileUrl)
                                    .then(myCertificatesRefresh);
                            }}
                            onRename={(fileUrl, label) => {
                                storage.updateFileMetadata(fileUrl, label)
                                    .then(myCertificatesRefresh);
                            }}
                            onUpload={fileList => {
                                setLoadingUploads({
                                    ...loadingUploads,
                                    [membership]: true,
                                });

                                fileList.forEach(file => {
                                    storage.uploadUserCertificate(
                                        userId,
                                        file,
                                        membership,
                                    )
                                        .then(myCertificatesRefresh)
                                        .finally(() => {
                                            setLoadingUploads({
                                                ...loadingUploads,
                                                [membership]: false,
                                            });
                                        });
                                });
                            }}
                        />
                    </VStack>
                );
            })}
        </FormSectionProfileEdit>
    );
};

export default ProfileValuationStandardsEdit;
