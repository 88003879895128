import { VStack } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import PageWithTitleLayout from '../../../common/components/display/PageWithTitleLayout';
import useAdminCaseReport from '../../../utils/api/hooks/admin/useAdminCaseReport';
import LoadingSpinner from '../../../common/components/LoadingSpinner';
import { useSmartTranslation } from '../../../common/hooks';
import { ContentBox, LabelAndData, StatusBadgeCaseReport } from '../../../common/components/display';
import { WvwLink } from '../../../common/components/inputs';

const AdminCaseReport = () => {
    const t = useSmartTranslation('dashboard');
    const { reportId: reportIdString } = useParams<{ reportId: string }>();

    const reportId = Number(reportIdString);

    const { data: report, isLoading } = useAdminCaseReport(reportId);

    if (isLoading || !report) return <LoadingSpinner />;

    const {
        case: caseData,
        documents,
        number,
        status,
    } = report;

    const {
        clientEntity,
        clientUser,
        valuerEntity,
        valuerUser,
    } = caseData;

    const items = [
        {
            title: t('table.header.createdAt'),
            value: report.createdAt,
        },
        {
            title: t('table.header.status'),
            value: <StatusBadgeCaseReport status={status} />,
        },
        {
            title: t('table.header.clientEntity'),
            value: clientEntity.name,
        },
        {
            title: t('table.header.clientUser'),
            value: `${clientUser.firstName} ${clientUser.lastName}`,
        },
        {
            title: t('table.header.valuerEntity'),
            value: valuerEntity.name,
        },
        {
            title: t('table.header.valuerUser'),
            value: `${valuerUser.firstName} ${valuerUser.lastName}`,
        },
        {
            title: t('table.header.files'),
            value: documents.map(({ id, name, url }) => (
                <WvwLink
                    key={id}
                    to={url}
                    label={name}
                    isExternal
                />
            )),
        },
    ];

    return (
        <PageWithTitleLayout title={`Case: #${caseData.id}, Report: #${number}`}>
            <ContentBox>
                <VStack>
                    {items.map(({ title, value }) => (
                        <LabelAndData key={title} label={title} data={value} />
                    ))}
                </VStack>
            </ContentBox>
        </PageWithTitleLayout>
    );
};

export default AdminCaseReport;
