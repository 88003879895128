/* eslint-disable max-len */
import {
    Button,
    Divider,
    Flex,
    Heading,
    HStack,
    Text,
    VStack,
    Link as ChakraLink,
} from '@chakra-ui/react';
import {
    InfoCircle,
    WalletMoney,
} from 'iconsax-react';
import {
    Outlet,
    useLocation,
    useParams,
    Link as ReactLink,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from '../../../common/components/LoadingSpinner';
import { useEnquiry } from '../../../utils/api/hooks';
import { useRole } from '../../../common/hooks';
import AwaitingAccess from '../../../common/components/AwaitingAccess';

const sideNav = [
    {
        icon: <InfoCircle size="1.3rem" />,
        label: 'sideNavLabel.overview',
        path: 'overview',
    },
    {
        icon: <WalletMoney size="1.3rem" />,
        label: 'sideNavLabel.offer',
        path: 'offer',
    },
];

const reducedNav = [
    {
        icon: <InfoCircle size="1.3rem" />,
        label: 'sideNavLabel.overview',
        path: 'overview',
    },
];

type EnquiryOverviewParams = {
    forCompanyAdmin?: boolean;
};

const EnquiryOverview = (params: EnquiryOverviewParams) => {
    const { forCompanyAdmin } = params;

    const { enquiryId = '' } = useParams();

    const location = useLocation();

    // FIXME HELP
    const currentPath = location.pathname.split('/')[4] || '';

    const { t } = useTranslation(['enquiries', 'common', 'formik']);

    const { userIsApproved, roleLoading } = useRole();

    const {
        enquiry,
        isLoading: enquiryLoading,
    } = useEnquiry({ enquiryId });

    if (roleLoading || enquiryLoading) {
        return <LoadingSpinner />;
    }

    const draft = enquiry?.status === 'DRAFT';

    const nav = (forCompanyAdmin || draft) ? reducedNav : sideNav;

    if (!userIsApproved) {
        return <AwaitingAccess />;
    }

    return (
        <VStack
            w="100%"
            align="left"
            spacing="4"
        >
            <VStack
                align="left"
                spacing="0"
            >
                <Flex
                    paddingRight="2rem"
                >
                    <Heading
                        as="h2"
                        size="xl"
                    >
                        {t('Enquiry', { ns: 'common' })}
                    </Heading>

                    <Divider
                        orientation="vertical"
                        h="3rem"
                        marginInline=".5rem"
                    />

                    <Text
                        alignSelf="center"
                        fontWeight="light"
                        fontSize="3xl"
                    >
                        {enquiryId}
                    </Text>

                    {/* <Spacer />

                    {userIsValuer && (
                        <Button
                            variant="primary"
                            borderRadius="30"
                            size="sm"
                            alignSelf="flex-end"
                            onClick={onOpen}
                        >
                            {t('button.referEnquiry', { ns: 'common' })}
                        </Button>
                    )} */}
                </Flex>
            </VStack>

            <HStack
                alignItems="flex-start"
                w="100%"
                align="left"
            >
                <VStack>
                    {nav.map(i => {
                        let isActive = false;

                        // FIXME remove hack for marking the overview active for admins
                        if (forCompanyAdmin) isActive = true;

                        if (currentPath === i.path) {
                            isActive = true;
                        }

                        if (currentPath === 'overview' && i.path === '') {
                            isActive = true;
                        }

                        return (
                            <ChakraLink
                                as={ReactLink}
                                key={i.label}
                                to={`./${i.path}`}
                            >
                                <Button
                                    h="6rem"
                                    variant={isActive ? 'primary' : 'primaryWhite'}
                                    w="6rem"
                                    isDisabled={enquiry?.status === 'CANCELLED' && i.path !== 'overview'}
                                >
                                    <VStack spacing="1">
                                        {i.icon}

                                        <Text fontWeight="light">
                                            {t(i.label as unknown as TemplateStringsArray)}
                                        </Text>
                                    </VStack>
                                </Button>
                            </ChakraLink>
                        );
                    })}
                </VStack>

                <Outlet />
            </HStack>

            {/* <Modal
                isOpen={isOpen}
                onClose={onClose}
            >
                <ModalOverlay />

                <ModalContent minWidth="4xl">
                    <ModalHeader
                        color="wvwGreen"
                        bg="wvwGrey05"
                    >
                        {t('referEnquiry.sectionTitle')}
                    </ModalHeader>

                    <ModalCloseButton />

                    <FormikForm
                        initialValues={{
                            email: '',
                            company: '',
                            companyWebsite: '',
                            comment: '',
                        }}
                        validationSchema={validationSchema}
                        onSubmit={() => console.log('submit')}
                    >
                        <ModalBody bg="wvwGrey05">
                            <Box
                                bg="wvwGrey05"
                                w="100%"
                                borderRadius="10"
                            >
                                <VStack
                                    alignItems="left"
                                    spacing="4"
                                    padding="1rem"
                                >
                                    <Heading
                                        as="h4"
                                        size="md"
                                        color="wvwGreen"
                                    >
                                        {`${t('referEnquiry.referTo')}:`}
                                    </Heading>

                                    <Flex>
                                        <FormikInput
                                            width="98%"
                                            placeholder={t('fieldPlaceholder.companyName', { ns: 'common' })}
                                            name="company"
                                        />

                                        <Spacer />

                                        <FormikInput
                                            placeholder={t('fieldPlaceholder.companyEmail', { ns: 'common' })}
                                            name="email"
                                        />
                                    </Flex>

                                    <FormikInput
                                        placeholder={t('fieldPlaceholder.companyWebsite_optional', { ns: 'common' })}
                                        name="companyWebsite"
                                    />

                                    <FormikTextarea
                                        placeholder={t('fieldPlaceholder.comment', { ns: 'common' })}
                                        name="comment"
                                    />
                                </VStack>
                            </Box>
                        </ModalBody>

                        <ModalFooter bg="wvwGrey05">
                            <Button
                                variant="primaryYellow"
                                alignSelf="flex-end"
                                type="submit"
                            >
                                {t('button.refer', { ns: 'common' })}
                            </Button>
                        </ModalFooter>
                    </FormikForm>
                </ModalContent>
            </Modal> */}
        </VStack>
    );
};

EnquiryOverview.defaultProps = {
    forCompanyAdmin: false,
};

export default EnquiryOverview;
