import {
    Button,
    VStack,
    Heading,
    Text,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalOverlay,
    useDisclosure,
    useToast,
    Center,
} from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import Logo from '../../common/components/Logo';
import { FormikForm, FormikInput } from '../../common/forms';
import { useFbSendPasswordReset } from '../../utils/api/hooks';
import RegisterFormsContainer from '../register/common/RegisterFormsContainer';
import { WvwButton } from '../../common/components/inputs';
import { WvwText, WVWTitle } from '../../common/components/typography';

const validationSchema = Yup.object({
    email: Yup.string()
        .email('Invalid email address')
        .required('errors.required'),
});

const ForgotPassword = () => {
    const { t } = useTranslation(['passwordReset', 'common', 'formik']);

    const navigate = useNavigate();
    const toast = useToast();

    const { isOpen, onOpen, onClose } = useDisclosure();

    const { update } = useFbSendPasswordReset({
        onSuccess: () => {
            onOpen();
        },
        onError: error => {
            toast({
                title: t('error.error', { ns: 'common' }),
                description: (error?.message === 'Firebase: Error (auth/user-not-found).' ? t('error.authError.userNotFound', { ns: 'common' }) : error?.message),
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        },
    });

    return (
        <RegisterFormsContainer goBack={() => navigate('/')}>
            <FormikForm
                validationSchema={validationSchema}
                initialValues={{
                    email: '',
                }}
                onSubmit={values => {
                    update(values.email);
                }}
            >
                <Center width="100%">
                    <VStack
                        spacing="6"
                        align="left"
                        pb="9em"
                        pt="6em"
                        width="fit-content"
                    >
                        <Logo withColor />

                        <Heading
                            as="h1"
                            size="xl"
                        >
                            {t('forgotPassword')}
                        </Heading>

                        <Text
                            align="left"
                            w="25rem"
                        >
                            {t('enterEmail')}
                        </Text>

                        <VStack
                            spacing="4"
                            align="center"
                            w="100%"
                        >
                            <FormikInput
                                name="email"
                                label={t('Email', { ns: 'common' })}
                                type="email"
                                placeholder={t('Email', { ns: 'common' })}
                            />

                            <Button
                                type="submit"
                                variant="primaryYellow"
                                w="100%"
                            >
                                {t('button.sendLink', { ns: 'common' })}
                            </Button>
                        </VStack>
                    </VStack>
                </Center>
            </FormikForm>
            <Modal
                onClose={onClose}
                isOpen={isOpen}
                isCentered
            >
                <ModalOverlay />

                <ModalContent>
                    <ModalCloseButton />

                    <ModalBody>
                        <VStack
                            paddingBlock="6rem"
                            spacing="4"
                        >
                            <CheckCircleIcon boxSize="3rem" color="#38A169" />

                            <WVWTitle
                                level="1"
                                color="black"
                                content={t('thankYou')}
                            />

                            <WvwText
                                color="wvwGrey60"
                                bold
                                textAlign="center"
                            >
                                {t('linkMessage')}
                            </WvwText>
                        </VStack>
                    </ModalBody>

                    <ModalFooter justifyContent="center">
                        <WvwButton
                            variant="primary"
                            onClick={() => {
                                onClose();
                            }}
                            block
                            content={t('button.done', { ns: 'common' })}
                        />
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </RegisterFormsContainer>
    );
};

export default ForgotPassword;
