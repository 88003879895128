import { FormControl, Text } from '@chakra-ui/react';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';
import { FileUploadInput } from '../inputs';
import WvwVStack from '../components/display/WvwVStack';
import FileUploadUploadedFileListItem from '../components/display/FileUploadUploadedFileListItem';

type PropTypes = {
    name: string;
    withComment?: boolean;
};

export type FormikFileUploadDataType = {
    file: File;
    comment: string;
};

const FormikFileUploadLocal = (props: PropTypes) => {
    const { name, withComment } = props;

    const { t } = useTranslation('formik');

    const [field, meta] = useField<FormikFileUploadDataType[]>(props);

    const files: FormikFileUploadDataType[] = field.value || [];

    return (
        <FormControl>
            <WvwVStack>
                <FileUploadInput
                    fileTypes={[
                        'application/pdf',
                        'image/gif',
                        'image/jpeg',
                        'image/jpg',
                        'image/png',
                    ]}
                    noRename
                    onChange={newFiles => {
                        if (newFiles && newFiles.length) {
                            const newDocs = [
                                ...files,
                                ...newFiles.map(file => ({
                                    file,
                                    comment: '',
                                })),
                            ];

                            field.onChange(
                                { target: { name, value: newDocs } },
                            );
                        }
                    }}
                />
                {files.map(file => (
                    <FileUploadUploadedFileListItem
                        data={file}
                        key={file.file.name}
                        withComment={withComment}
                        onEdit={(newName, newComment) => {
                            const newFiles = files.map(f => {
                                if (f.file.name === file.file.name) {
                                    const newFile = new File([f.file], newName);

                                    return {
                                        ...file,
                                        file: newFile,
                                        comment: newComment,
                                    };
                                }

                                return f;
                            });

                            field.onChange(
                                { target: { name, value: newFiles } },
                            );
                        }}
                        onDelete={() => {
                            const newFiles = files.filter(f => f.file.name !== file.file.name);

                            field.onChange(
                                { target: { name, value: newFiles } },
                            );
                        }}
                    />
                ))}

                {meta.touched
                    && meta.error
                    && (
                        <Text color="red">
                            {t(meta.error, { defaultValue: meta.error })}
                        </Text>
                    )}
            </WvwVStack>
        </FormControl>
    );
};

FormikFileUploadLocal.defaultProps = {
    withComment: false,
};

export default FormikFileUploadLocal;
