import {
    GridItem,
    Box, List,
    ListItem,
} from '@chakra-ui/react';
import { WVWTitle } from '../../../../../common/components/typography';

type PropTypes = {
    title: string;
    listItems: string[];
};

const CaseOverviewBox = (props: PropTypes) => {
    const { title, listItems } = props;
    return (
        <GridItem>
            <Box
                p={5}
                minHeight="175px"
                borderRadius="lg"
                bg="white"
            >
                <WVWTitle level="2" color="black" content={title} />
                <List spacing={2} mt={3}>
                    {listItems.map(item => (
                        <ListItem key={item}>
                            {item}
                        </ListItem>
                    ))}
                </List>
            </Box>
        </GridItem>
    );
};

export default CaseOverviewBox;
